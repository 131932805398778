import { Injectable }    from '@angular/core';
import { UserService }   from '../user.service';
import { OrdersService } from '../order-item.service';
import {CurrencyPipe} from "@angular/common";
import {TenantCreditSettingsService} from "../tenant-credit-settings.service";

@Injectable({
  providedIn: 'root'
})
export class VariablesService {

  creditSettings: any = {creditValue: 0};

  constructor(
    private userService: UserService,
    private orderService: OrdersService
  ) {
  }

  replaceUserVariables(text: string): Promise<string> {
    let variables = [];
    return this.userService.getUser().then((user: any) => {
      if (user) {
        variables.push({
          name: 'Username',
          value: user.name
        });
        variables.push({
          name: 'User email',
          value: user.email
        });
        variables = variables.concat(user.variableValues);
      }

      if (this.orderService.currentShoppingCart) {
        variables.push({
          name: 'Order Id',
          value: this.orderService.currentShoppingCart.id
        });
        variables.push({
          name: 'orderId',
          value: this.orderService.currentShoppingCart.id
        });

        // const totalPrice = this.getTotalPrice();
        // console.log('totalPrice', totalPrice);
        //
        // variables.push({
        //   name: 'Order total',
        //   value: totalPrice
        // });
      }
      // console.log(variables);
      return this.replaceVariables(text, variables);
    });
  }

  async getTotalPrice() {
    const currencyPipe = new CurrencyPipe('nl-BE');
    const price = await this.orderService.getOrderPrice();
    const totalPrice = currencyPipe.transform(price, 'EUR', '', '1.2-3');

    return totalPrice;
  }



  replaceVariables(text, variables) {
    if (!text) {
      text = '';
    }

    variables.forEach((variable, index) => {
      if (!variable.name) {
        return;
      }

      const pattern = new RegExp('(#{{' + variable.name + '}})', 'gm');
      text = text.replace(pattern, variable.value);
      const pattern2 = new RegExp('(#{{' + variable.name.toLowerCase() + '}})', 'gm');
      text = text.replace(pattern2, variable.value);
    });
    return text;
  }
}
