import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ThemeService }                       from '../../theme.service';
import { StepsService }                       from '../steps/steps.service';
import { OrdersService }                      from '../../order-item.service';
import { UserService }                        from '../../user.service';
import {NotificationService} from "../../global/notification.service";
import {RxFormBuilder, RxwebValidators} from "@rxweb/reactive-form-validators";
import * as _ from "lodash";
import {BuilderService} from "../../builder.service";

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.sass']
})
export class DeliveryComponent implements OnInit {

  fileAttr = 'Choose File';

  deliveryInformation: FormGroup;

  form: any;

  user: any;

  formToVariablesMapping: any = {};

  isCheckoutDisabled: boolean = true;

  constructor(
    public themeService: ThemeService,
    public stepService: StepsService,
    public ordersService: OrdersService,
    public usersService: UserService,
    private notification: NotificationService,
    private formBuilder: RxFormBuilder,
    private builder: BuilderService
  ) { }

  ngOnInit() {
    this.deliveryInformation = new FormGroup({});
    this.themeService.get();
    this.builder.get('delivery-information')
      .then((response: any) => {
        this.form = response.form;
        this.prepareForm();

        this.usersService.getUser()
          .then((user) => {
            this.user = user;
            const formFieldsValues = this.deliveryInformation.getRawValue();
            for ( const field in formFieldsValues) {

              this.user.variableValues.map((variable, index, variableValuesRaw) => {
                if (variable.name.toLowerCase() == this.formToVariablesMapping[field].toLowerCase()) {
                  formFieldsValues[field] = variable.value;
                }
              });
            }

            if (formFieldsValues['emailAddress'] == '') {
              formFieldsValues['emailAddress'] = this.user.email;
            }

            this.deliveryInformation.setValue(
              formFieldsValues
            );
          });
      });
  }

  prepareForm() {
    let requiredFields = [];
    let formControls = {};
    _.each(this.form.lines, (line, i) => {
      _.each(line.fields, (field: any, fi) => {
        // const formControlName =
        field.formControlName = field.input_label.replace(/[^A-Z0-9]/ig, '_').toLowerCase() + '_' + line.id + '_' + field.id;
        this.formToVariablesMapping[field.formControlName] = field.input_label;
        let validators = [];
        if(field.is_required) {
          validators.push(RxwebValidators.required());
          if(field.icon == 'check_box') {
            requiredFields.push(field.formControlName);
          }
        }
        if(field.icon == 'email') {
          validators.push(RxwebValidators.email());
        }
        if(field.icon == 'money') {
          validators.push(RxwebValidators.numeric());
          // validators.push(RxwebValidators.maxLength({ value: field.max_length }));
          field.input_label += ' (enkel cijfers)';
        }
        formControls[field.formControlName] = ['', validators];
      });
    });
    this.deliveryInformation = this.formBuilder.group(formControls);
    requiredFields.map((controlName: string) => {
      this.deliveryInformation.controls[controlName].valueChanges.subscribe((value) => {
        // console.log(controlName + '; ', value);
        this.isCheckoutDisabled = !value;
      });
    });
    if (requiredFields.length == 0) {
      this.isCheckoutDisabled = false;
    }
    // valueChanges
  }

  next() {
    this.deliveryInformation.markAsTouched();
    _.each(this.deliveryInformation.controls, (item, index) => {
      item.markAsTouched();
    });

    if (!this.deliveryInformation.valid || this.isCheckoutDisabled) {
      this.notification.alert(2);
      return;
    }

    this.ordersService.confirmDelivery(this.deliveryInformation.getRawValue())
      .then((response) => {
        this.ordersService.confirmShoppingCart({}).then(() => {
          this.stepService.goToNext();
        });
      });
  }

  previous() {
    this.stepService.goToPrevious();
  }

  uploadFileEvt(imgFile: any, control: string) {
    if (imgFile.target.files && imgFile.target.files[0]) {
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr = file.name;
      });

      // HTML5 FileReader API
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          this.deliveryInformation.controls[control].setValue(e.target.result);
        };
      };
      reader.readAsDataURL(imgFile.target.files[0]);

      // Reset if duplicate image uploaded again
      // this.fileAttr = 'Choose File';
    } else {
      this.fileAttr = 'Choose File';
    }
  }


}
