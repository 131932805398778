import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-image-popover-dialog',
  templateUrl: './image-popover-dialog.component.html',
  styleUrls: ['./image-popover-dialog.component.sass']
})
export class ImagePopoverDialogComponent implements OnInit {

  selectedImage: any;

  constructor(
    public dialogRef: MatDialogRef<ImagePopoverDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public images: any[]
  ) {
    this.selectedImage = images[0];
  }

  ngOnInit() {
  }

  selectImage(image) {
    this.selectedImage = image;
  }

  onNoClick($event): void {
    this.dialogRef.close();
  }

}
