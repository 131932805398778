import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import {OrdersService} from "../order-item.service";

@Injectable({
  providedIn: 'root'
})
export class EmbeddedOrderItemsService {
  embeddedList: any[] = [];
  documents: any[] = [];
  orderItem: any = null;
  currentDocument: any;
  tags: any[] = [];
  allEmbeddedList: any[] = [];

  constructor(private ordersService: OrdersService) { }

  public setSavedEmbedded() {
    let promises = _.map(this.documents, (document) => {
      return this.ordersService.getEmbedded(this.orderItem.id, document.id)
        .then((data: any) => {
          data.assets.map((result) => {
            if (result) {
              this.addToList(document.id, result.assetId, result.tagName, result.page, 'valid', true)
            }
          });
          return data;
        })
    });

    return Promise.all(promises);
  }

  public findUnEmbedded() {
    return  _.find (this.documents, (document) => {
      const result = _.find(this.embeddedList, (item) => {
          return item.documentId === document.id;
      });
      return !result;
    });
  }

  public init(orderItem, documents) {
    this.orderItem = orderItem;
    this.documents = documents;

    return this.setSavedEmbedded();

  }

  setCurrentDocument(currentDocument){
    this.currentDocument = currentDocument;
  }

  public setEmbeddedByDocument() {
    const item = _.find (this.embeddedList, (item) => {
      return item.documentId === this.currentDocument.id;
    });

    if (item && !item.saved) {
      return this.addEmbedded(item)
    } else {
      return Promise.resolve();
    }
  }

  private addEmbedded(item) {
    const data = [{
        tagName: item.tagName,
        assetId: item.assetId,
        page: item.page
      }];

    return this.ordersService.saveImageToEmbedded(item.orderItemId, item.documentId, data)
      .then(() => {
        item.saved = true;
        return;
      })
  }

  public removeEmbedded() {
    return this.ordersService.removeEmbedded(this.orderItem.id, this.currentDocument.id)
      .then(() => {
        this.checkAndRemoveItemFromListByDocument(this.currentDocument.id);
        return;
      })
  }

  public addToList(documentId, assetId, tag, pageNumber, status = 'valid', saved = false) {
    const embeddedItem = {
      orderItemId: this.orderItem.id,
      documentId: documentId,
      assetId: assetId,
      tagName: tag,
      page: pageNumber,
      status: status,
      saved: saved
    };

    this.checkAndRemoveItemFromList(embeddedItem);
    this.embeddedList.push(embeddedItem);
    this.allEmbeddedList.push(embeddedItem);
  }

  private checkAndRemoveItemFromList(_item){
    this.embeddedList = _.filter(this.embeddedList, (item) => {
      if (item.assetId !== _item.assetId && item.documentId !== _item.documentId) {
        return item;
      }
    });
  }

  public checkAndRemoveItemFromListByDocument(id){
    this.embeddedList = _.filter(this.embeddedList, (item) => {
      if (item.documentId !== id) {
        return item;
      }
    });
    this.allEmbeddedList = _.filter(this.allEmbeddedList, (item) => {
      if (item.documentId !== id) {
        return item;
      }
    });
  }

  public async validateEmbedded() {
    this.tags = (await this.ordersService.getTags(this.orderItem.id, this.currentDocument.id)).tags;
    const errorTag = this.checkRequiredTags();

    if (errorTag.length !== 0) {
      return {documentId: this.currentDocument.id, errorType: 'tag'};
    }

    return _.find (this.embeddedList, (item) => {
       return item.status !== 'valid' && item.status !== 'to_be_checked' ? item : false;
    });
  }

  public findUncompleteDocuments() {
    return _.filter(this.documents, (document) => {
      const items = _.find(this.embeddedList, (item) => {
        return document.id === item.documentId;
      });

      const errorTag = this.checkRequiredTags();

      return !(items || errorTag.length === 0);
    });
  }

  public checkRequiredTags() {
    const requiredTags = _.filter(this.tags, (value: string, index: number, tags: any[]) => {
      return value.indexOf('*') === value.length - 1;
    });
    const errorTag: any[] = [];
    requiredTags.map((tag, tagIndex) => {
      let isUploaded = false;
      this.allEmbeddedList.map((asset, assetIndex) => {
        if (tag === asset.tagName) {
          isUploaded = true;
        }
      });
      if (!isUploaded) {
        errorTag.push({ tagName: tag, 'isUploaded': isUploaded });
      }
    });

    return errorTag;
  }
}
