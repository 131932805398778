import {Component, Inject, OnInit} from '@angular/core';
import {OrdersService} from "../../../order-item.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ThemeService} from "../../../theme.service";

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.sass']
})
export class SendEmailComponent implements OnInit {
  emailForm: FormGroup;

  constructor( private ordersService: OrdersService,
               public themeService: ThemeService,
               private formBuilder: FormBuilder,
               public dialogRef: MatDialogRef<SendEmailComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.setForm();
  }

  private setForm() {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  sendMail() {
    if (this.emailForm.invalid) {
      return;
    }

    this.ordersService.sendMail(this.data.id, this.emailForm.value.email)
      .then(() => {
        this.close();
      })
  }

  close() {
    this.dialogRef.close({success: true});
  }

}
