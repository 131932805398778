import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AdjustStepConditionService {
  documents: any = {};

  constructor() { }

  getDocuments(orderId) {
    return this.documents[orderId];
  }

  setDocuments(documents, orderId) {
    if (!this.documents[orderId]) {
      this.documents[orderId] = documents;
    }
  }

  setDocumentSavedCondition(documentId, orderId) {
    _.find(this.documents[orderId], (document) => {
      if(document.id === documentId) {
        document.saved = true;
        return true;
      }
    });
  }

  findUnsavedDocument(orderId) {
    if( this.documents[orderId]) {
      return _.find(this.documents[orderId], (document) => {
        return !document.saved;
      });
    }

  }
}
