<div class="container" *ngIf="!this.confirmed">
  <div class="header">
    <div class="avatar">
      <mat-icon
        [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">
        call
      </mat-icon>
    </div>
    <div class="title">
      <h1 appI18nTranslation>Contact information</h1>
    </div>
  </div>

  <div class="body">
    <form class="send-mail-customer-form" [formGroup]="contactForm">
      <div class="form-field">
        <label for="firstname" [ngClass]="{'error': contactForm.controls['firstName'].invalid && contactForm.controls['firstName'].touched }" appI18nTranslation>First Name</label>
        <input type="text" id="firstname" formControlName="firstName">
      </div>
      <div class="form-field">
        <label for="lastname" [ngClass]="{'error': contactForm.controls['lastName'].invalid && contactForm.controls['lastName'].touched }" appI18nTranslation>Name</label>
        <input type="text" id="lastname" formControlName="lastName">
      </div>
      <div class="form-field">
        <label for="company" [ngClass]="{'error': contactForm.controls['company'].invalid && contactForm.controls['company'].touched }" appI18nTranslation>Company</label>
        <input type="text" id="company" formControlName="company">
      </div>
      <div class="form-field">
        <label for="email" [ngClass]="{'error': contactForm.controls['email'].invalid && contactForm.controls['email'].touched }" appI18nTranslation>E-mail</label>
        <input type="text" id="email" formControlName="email">
      </div>
      <div class="form-field">
        <label class="phone" [ngClass]="{'error': contactForm.controls['phone'].invalid && contactForm.controls['phone'].touched }" appI18nTranslation>Telephone number</label>
        <ngx-intl-tel-input
          [cssClass]="'custom'"
          [preferredCountries]="['be']"
          [enablePlaceholder]="true"
          [enableAutoCountrySelect]="true"
          name="phone"
          formControlName="phone"></ngx-intl-tel-input>
      </div>
      <div class="form-field">
        <label for="amount" [ngClass]="{'error': contactForm.controls['amount'].invalid && contactForm.controls['amount'].touched }" appI18nTranslation>Desired quantity</label>
        <input type="text" id="amount" formControlName="amount">
      </div>
      <div class="form-field full-width">
        <label for="remarks" appI18nTranslation>Remarks</label>
        <textarea
          placeholder="Explain your question or problem here in a few lines"
          appi18nTranslation
          type="text"
          id="remarks"
          formControlName="remarks">
        </textarea>
      </div>

      <div class="button-row">
        <button mat-stroked-button mat-dialog-close class="cancel" appI18nTranslation>Cancel</button>
        <button
          mat-button
          [disabled]="contactForm.invalid"
          class="send-mail"
          [ngStyle]="{'background': (themeService.settings$ | async).getButtonColor(1), 'border-color': (themeService.settings$ | async).getButtonColor(0.2)}"
          (click)="send()" appI18nTranslation>
          Send
        </button>
      </div>
    </form>
  </div>
</div>

<div class="wrapper" *ngIf="this.confirmed">
  <div class="message-container" [ngStyle]="{'border-color': (themeService.settings$ | async).getButtonColor(0.2)}">
    <div class="text" appI18nTranslation>
      Thank you for your inquiry,<br>
      we will contact you as soon as possible.
    </div>
    <div class="text" appI18nTranslation>
      3Motion team
    </div>
  </div>
</div>
