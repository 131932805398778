import { environment }   from '../environments/environment';
import {
  EventEmitter,
  Injectable
}                        from '@angular/core';
import { Subscription }  from 'rxjs';
import { Locale }        from './locale';

@Injectable()
export class ConfigService {
  private apiUrl: string;
  private wsUrl: string;
  private currentTenant: string;
  private onChangeLocale: EventEmitter<any>;
  public currentLocale: Locale;

  constructor(
  ) {
    this.apiUrl = environment.apiUrl;
    this.wsUrl = environment.frontendWs;
    this.currentTenant = environment.production ? (window.location.host.split('.'))[0] : environment.defaultTenant;
    this.onChangeLocale = new EventEmitter<any>();
  }

  getWsUrl(): string {
    return this.wsUrl.replace('{tenant}', this.currentTenant);
  }

  getActionUrl(action: string): string {
    return window.location.protocol + this.apiUrl + this.currentTenant + '/' + this.currentLocale.code + '/' + action;
  }

  getLanguagesUrl(action: string): string {
    return window.location.protocol + this.apiUrl + this.currentTenant +  '/' + action;
  }

  subscribeChangeLocale(generatorOrNext?: any, error?: any, complete?: any): Subscription {
    return this.onChangeLocale.subscribe(generatorOrNext, error, complete);
  }

  setCurrentLocale(locale: Locale): void {
    this.currentLocale = locale;
    localStorage.setItem('currentLocale', JSON.stringify(this.currentLocale));
    this.onChangeLocale.emit(this.currentLocale);
  }
}
