<div class="container">
  <div class="header">
    <div class="logo">
      <img [src]="themeService.getLogoUrl()">
    </div>
  </div>
  <div class="content">
    <div class="image" *ngIf="page.image.isShown">
      <img [src]="page.image.image.url">
    </div>
    <h1 *ngIf="page.title.isShown" [innerHtml]="page.title.title | safe: 'html'"></h1>
    <h4 *ngIf="page.sub_title.isShown" [innerHtml]="page.sub_title.sub_title | safe: 'html'"></h4>
    <a class="button" href="/" appI18nTranslation>Back To Home</a>
  </div>
  <div class="footer">
    <div class="logo">
      <img src="assets/images/login-logo.png">
    </div>
    <div class="copyright" appI18nTranslation>&copy; 2018 Printlink. All rights reserved.</div>
  </div>
</div>
