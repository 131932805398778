import {
  Component,
  Input,
  OnInit,
  Renderer2
}                                    from '@angular/core';
import {ThemeService}                from "../../theme.service";
import {PromoProductsService}        from "../../promo-products.service";
import {ClientPage}                  from "../../client-page";
import {MatDialog}                   from "@angular/material";
import {ContactInformationComponent} from "../../global/contact-information/contact-information.component";
import {ImagePopoverDialogComponent} from "../catalog/image-popover-dialog/image-popover-dialog.component";
import { LanguagesService }          from '../../languages.service';

@Component({
  selector: 'app-promo-products',
  templateUrl: './promo-products.component.html',
  styleUrls: ['./promo-products.component.sass']
})
export class PromoProductsComponent implements OnInit {
  promoProducts: any[] = [];

  @Input() page: ClientPage;

  constructor(
    public themeService: ThemeService,
    private promoProductsService: PromoProductsService,
    public dialog: MatDialog,
    private languagesService: LanguagesService
  ) { }

  ngOnInit() {
    this.languagesService.currentLocaleSet$.subscribe(() => {
      this.getPromoProducts();
    });

  }

  private getPromoProducts() {
    this.promoProductsService.getList()
      .then((products) => {
        this.promoProducts = products.data;
      });
  }

  public openPromoProductDialog(promoProduct) {
    const data = {
      id: promoProduct.id
    };

    const dialogRef = this.dialog.open(ContactInformationComponent, {
      minWidth: '400px',
      data: data
    });
  }

  showProductImage(image: MouseEvent) {
    const dialogRef = this.dialog.open(ImagePopoverDialogComponent, {
      data: image
    });
  }

}
