import { Injectable } from '@angular/core';
import {OrdersService} from "../order-item.service";

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(
    public ordersService: OrdersService
  ) { }

  public export(orderId, documentId) {
    return this.ordersService.export(orderId, documentId)
      .then((result) => {
        return this.getPreviewData(result.exportId);
      })
  }

  public getPreviewData(exportId){
    return this.ordersService.getExported(exportId)
      .then((exportedData) => {
        if (exportedData.export.finished) {
          let url = exportedData.export.chiliUrl;
          return url.split('.online')[1];

        } else {
          return this.getPreviewData(exportId);
        }
      });
  }

  public getUploadedItem(id: number, documentId: number, uploadeItemId: number){
    return this.ordersService.getUploadedItem(id, documentId, uploadeItemId)
      .then((item) => {
        if (item && item.status !== 'pending') {
          return item;
        } else {
          return this.getUploadedItem(id, documentId, uploadeItemId);
        }
      })
  }

  public getPreviewPdf(chiliUrl){
    return this.ordersService.getPreviewPdf(`/CHILI${chiliUrl}`);
  }

  public getPreviewJpeg(previewChiliUrl){
    return this.ordersService.getPreviewJpeg(previewChiliUrl);
  }
}
