import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor() { }

  public download(response) {
    const fileData = {
      name: this.getFileNameFromHttpResponse(response),
      data: response.body
    };
    const newBlob = new Blob([fileData.data], { type: fileData.data.type });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');

    link.href = data;
    link.download = fileData.name;

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout( () => {
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  private getFileNameFromHttpResponse(httpResponse) {
    let contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
    if(contentDispositionHeader) {
      let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      return result.replace(/"/g, '');
    }
    return 'preview.' + httpResponse.body.type.replace('image/', '');
  }
}
