import { Injectable }  from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ThemeService} from "../theme.service";
import {AlertDialogComponent} from "./dialogs/alert-dialog/alert-dialog.component";
import {PromtDialogComponent} from "./dialogs/promt-dialog/promt-dialog.component";
import {AlertBlock} from "../blocks/alert-block";
import {CustomAlertDialogComponent} from "./dialogs/custom-alert-dialog/custom-alert-dialog.component";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../config.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private snackBar: MatSnackBar,
    public themeService: ThemeService,
    public dialog: MatDialog,

  ) { }

  get(notificationId: number) {
    return this.http.get(this.config.getActionUrl(`notifications/${notificationId}`)).toPromise();
  }

  public notify(message: string, delay: number = 3000) {
    let snackBarRef = this.snackBar.open(message, '', { horizontalPosition: 'right', duration: delay });
  }

  public alert(notificationId: number, data: any = null): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.get(notificationId)
        .then((result: any) => {

          if(data && data.email) {
            result.notification.message = result.notification.message.replace('%email%',data.email);
          }

          const dialogRef = this.dialog.open(AlertDialogComponent,
            {
              panelClass: 'alert-dialog-panel',
              data: result.notification,
              disableClose: true
            });

          resolve(dialogRef.afterClosed().toPromise());
        });
    });
  }

  public customAlert(alert: any, data:any = null): Promise<any> {
    if(data && data.totalPrice) {
      alert.totalPrice = data.totalPrice;
    }
    const dialogRef = this.dialog.open(CustomAlertDialogComponent,
      {
        panelClass: 'alert-dialog-panel',
        data: alert,
        disableClose: true
      });

    return dialogRef.afterClosed().toPromise();
  }

  public promt(notificationId: number, data: any = null): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.get(notificationId)
        .then((result: any) => {
          if(data) {
            if(data.total) {
              result.notification.message = result.notification.message.replace('%total%',data.total);
            }
            if(data.cancelLabel) {
              result.notification.cancelLabel = data.cancelLabel
            }
            if(data.applyLabel) {
              result.notification.applyLabel = data.applyLabel
            }
          }
          const dialogRef = this.dialog.open(PromtDialogComponent,
            {
              panelClass: 'promt-dialog-panel',
              data: result.notification,
              disableClose: true
            });

          resolve(dialogRef.afterClosed().toPromise());
        });
    });
  }

}
