import { Injectable }             from '@angular/core';
import { HttpClient }             from '@angular/common/http';
import { Observable }             from 'rxjs';
import { AuthenticateCommand }    from '../login/commands/autheticate.command';
import { Router }                 from '@angular/router';
import { AuthenticationResponse } from './authentication-response';
import { ConfigService }          from '../config.service';
import {UserService} from "../user.service";
import {map} from "rxjs/operators";
import {RegistrationCommand} from "../login/commands/registration.command";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private config: ConfigService,
    private userService: UserService
  ) { }

  login(data: AuthenticateCommand): Observable<Object> {
    const observerable = this.http.post(this.config.getLanguagesUrl('sessions'), data);

    return observerable;
  }

  registration(data: RegistrationCommand): Observable<Object> {
    const observerable = this.http.put(this.config.getLanguagesUrl('users'), data);

    return observerable;
  }

  async loginByExpodocCode(expoCode: string) {
    const response: any = await this.http.post(this.config.getLanguagesUrl('sessions/expocode'), {'expocode': expoCode}).toPromise();
    localStorage.setItem('token', response.token);
    await this.userService.getUser();
  }

  logout() {
    return this.http.delete(this.config.getLanguagesUrl('sessions/' + localStorage.getItem('token')))
    .subscribe((data: AuthenticationResponse) => {
      localStorage.removeItem('token');
      this.router.navigate(['login']);
      this.userService.setUser(null);
    });
  }

  getPermanentToken(token) {
    return this.http.post(this.config.getLanguagesUrl('sessions'), {'orderSharingToken': token})
    .pipe(map ((response) => {
      localStorage.setItem('token', response['token']);

      return response['token'];
    }));

  }

}
