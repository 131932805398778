import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {EditorService} from "../editor.service";
import {ThemeService} from "../../../theme.service";


interface TypeFrameCoordinates {
  x: number;
  y: number;
  width: number;
  height: number;
  realWidth: number;
  realHeight: number;
}

@Component({
  selector: 'app-chili-editor',
  templateUrl: './chili-editor.component.html',
  styleUrls: ['./chili-editor.component.sass']
})
export class ChiliEditorComponent implements OnInit {
  @Input() url: string;

  @ViewChild('chili')

  public chili: ElementRef;

  public frameWindow;
  public iframeUrl;
  public activeFrame;
  public activeFramePosition = { y: 0, x: 0, width: 0 };
  public zoomToolbarStyle = { top: 0, left: 0 };

  constructor(
    private sanitizer: DomSanitizer,
    private editorService: EditorService,
    public themeService: ThemeService
  ) { }

  ngOnInit() {
    this.editorService.shellReady.next(false);

    let url = `/CHILI/${this.url}`;

    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.editorService.startEventListening();
  }

  iFrameLoaded() {
    try {
      this.frameWindow = this.chili.nativeElement.contentWindow;
      if(!this.frameWindow) {
        return ;
      }
      this.frameWindow.GetEditor(this.EditorLoaded.bind(this));
    } catch (error) {
      console.log('error', error);
      return false;
    }
  }

  EditorLoaded(jsInterface) {
    let editor = this.frameWindow.editorObject;

    this.editorService.set(editor);
    // this.editorService.shellReady.subscribe(() => {
    //   console.log(this.getDocumentFrames());
    // });
    // this.testEvents();
    this.editorService.selectEvent$('SelectedFrameChanged').subscribe((event) => {
      // console.log(this.editorService.editor.exec(`document.fullWorkSpace.rightPanels[0]`));

      const frame = this.editorService.editor.GetObject(`document.selectedFrame`);
      // console.log(this.editorService.editor.GetObject(`document.selectedFrame.contentFrameControl.width`));
      // console.log(this.editorService.editor.GetObject(`document.pixelWidth`) * (this.editorService.editor.GetObject(`document.zoom`) / 100));
      // console.log(this.editorService.editor.GetObject(`document.workSpace.inlinePanels[59C77987-5A31-7E14-18BB-81DC64299A85].viewPreferences`));
      if(!frame || frame.type !== 'image') {
        this.activeFrame = false;
        return;
      }

      if (!event.targetID) {
        this.activeFrame = false;
        this.activeFramePosition = { y: 0, x: 0, width: 0 };
        return;
      }
      this.activeFrame = event.targetID;
      this.activeFramePosition = this.getFrameCoordinates(event.targetID);
      this.zoomToolbarStyle = {
        top: this.activeFramePosition.y,
        left: (this.activeFramePosition.x + this.activeFramePosition.width)
      };
    });

    this.editorService.selectEvent$('PageCanvasScrollChanged').subscribe((event) => {
      const frame = this.editorService.editor.GetObject(`document.selectedFrame`);
      if (frame) {
        this.activeFramePosition = this.getFrameCoordinates(frame.id);
        this.zoomToolbarStyle = {
          top: this.activeFramePosition.y,
          left: (this.activeFramePosition.x + this.activeFramePosition.width)
        };
      }
    });

  }

  getDocumentFrames() {
    const frames = [];
    const pages = this.editorService.editor.GetObject(
      `document.pages`,
    );

    if (!pages || pages.length === 0) {
      return ;
    }

    for(let i = 0; i < pages.length; i++) {
      const pageFrames = this.editorService.editor.GetObject(
        `document.pages[${i}].frames`,
      );

      for(let j = 0; i < pageFrames.length; i++) {
        const frame = this.editorService.editor.GetObject(
          `document.pages[${i}].frames[${j}]`,
        );
        if(!frame || frame.type !== 'image') {
          continue;
        }
        frames.push(frame);
      }
    }
    return frames;
  }

  getFrameCoordinates = (frameId: string) => {
    if (this.editorService.editor) {
      try {
        this.editorService.editor.GetObject(
          `document.allFrames[${frameId}].contentFrameControl`,
        );
      } catch (error) {
        throw error;
      }
      const frameData = this.editorService.editor.GetObject(`document.allFrames[${frameId}]`);
      const coordinatesData = this.editorService.editor.ExecuteFunction(
        `document.allFrames[${frameId}].contentFrameControl`,
        'GetEditorCanvasMetrics',
      );
      / CHILI Bug: after loading new Doc XML into the editor the y coordinate is not immediately set /
      if (coordinatesData.y === 'NaN') {
        throw new Error(`CoordinatesData.y is NaN on frame "${frameId}"`);
      }
      const frameCoordinates: TypeFrameCoordinates = {
        x: parseFloat(coordinatesData.x),
        y: parseFloat(coordinatesData.y),
        width: parseFloat(coordinatesData.width),
        height: parseFloat(coordinatesData.height),
        realWidth: parseFloat(frameData.realWidth),
        realHeight: parseFloat(frameData.realHeight),
      };
      return frameCoordinates;
    }
    return null;
  }

  zoomIn() {
    const frame = this.editorService.editor.GetObject(`document.selectedFrame`);

    if(!frame) {
      return;
    }

    const realWidth = (+frame.imgWidth.replace(' mm', ''));
    const realHeight = (+frame.imgHeight.replace(' mm', ''));
    const realX = (+frame.imgX.replace(' mm', ''));
    const realY = (+frame.imgY.replace(' mm', ''));
    const width = realWidth + (realWidth * 0.05);
    const height = realHeight + (realHeight * 0.05);
    const x = realX + (realX * 0.05);
    const y = realY + (realY * 0.05);
    this.editorService.editor.SetProperty("document.selectedFrame","fitMode", 'manual');
    this.editorService.editor.SetProperty("document.selectedFrame","imgWidth", width);
    this.editorService.editor.SetProperty("document.selectedFrame","imgHeight", height);
    this.editorService.editor.SetProperty("document.selectedFrame","imgX", x);
    this.editorService.editor.SetProperty("document.selectedFrame","imgY", y);
  }

  zoomOut() {
    const frame = this.editorService.editor.GetObject(`document.selectedFrame`);

    if(!frame) {
      return;
    }

    const realWidth = (+frame.imgWidth.replace(' mm', ''));
    const realHeight = (+frame.imgHeight.replace(' mm', ''));
    const realX = (+frame.imgX.replace(' mm', ''));
    const realY = (+frame.imgY.replace(' mm', ''));
    const width = realWidth - (realWidth * 0.05);
    const height = realHeight - (realHeight * 0.05);
    const x = realX - (realX * 0.05);
    const y = realY - (realY * 0.05);
    this.editorService.editor.SetProperty("document.selectedFrame","fitMode", 'manual');
    this.editorService.editor.SetProperty("document.selectedFrame","imgWidth", width);
    this.editorService.editor.SetProperty("document.selectedFrame","imgHeight", height);
    this.editorService.editor.SetProperty("document.selectedFrame","imgX", x);
    this.editorService.editor.SetProperty("document.selectedFrame","imgY", y);
  }

  testEvents() {
    const events = [
      'AdTypeOverlayViewerVisibilityChanged',
      'AdTypeOverlayVisibilityChanged',
      'AlternateLayoutAdded',
      'AlternateLayoutDefinitionChanged',
      'AlternateLayoutDeleted',
      'AlternateLayoutIndexChanged',
      'AlternateLayoutNameChanged',
      'AlternateLayoutSelectionChanged',
      'AnnotationAdded',
      'AnnotationDefinitionChanged',
      'AnnotationDeleted',
      'AnnotationMoved',
      'AutoTextCursorChanged',
      'BarcodeFrameVariableChanged',
      'BaseLineGridOptionsChanged',
      'BaseLineGridVisibilityChanged',
      'BleedVisibilityChanged',
      'BookMarkAdded',
      'BookMarkDefinitionChanged',
      'BookMarkDeleted',
      'BookMarkIndexChanged',
      'BookMarkLinkSettingsChanged',
      'BookMarkNameChanged',
      'BookViewNavigationChanged',
      'BookViewPreferencesChanged',
      'BorderVisibilityChanged',
      'ChainVisibilityChanged',
      'CharacterStyleAdded',
      'CharacterStyleColorChanged',
      'CharacterStyleDefinitionChanged',
      'CharacterStyleDeleted',
      'CharacterStyleIndexChanged',
      'CharacterStyleNameChanged',
      'CharacterStyleUnderLineChanged',
      'ColorAdded',
      'ColorDefinitionChanged',
      'ColorDeleted',
      'ColorIndexChanged',
      'ColorNameChanged',
      'CopyFrameChanged',
      'CursorChanged',
      'CustomButtonAdded',
      'CustomButtonDefinitionChanged',
      'CustomButtonDeleted',
      'CustomButtonIndexChanged',
      'DataSourceChanged',
      'DataSourceRecordChanged',
      'DisplayQualityChanged',
      'DocumentActionChanged',
      'DocumentAdSettingsChanged',
      'DocumentAnnotationSettingsChanged',
      'DocumentBleedChanged',
      'DocumentClosing',
      'DocumentConstraintsChanged',
      'DocumentDefaultDirectoriesChanged',
      'DocumentDimensionsChanged',
      'DocumentDirtyStateChanged',
      'DocumentEventActionAdded',
      'DocumentEventActionDefinitionChanged',
      'DocumentEventActionDeleted',
      'DocumentEventActionIndexChanged',
      'DocumentEventActionNameChanged',
      'DocumentFlapChanged',
      'DocumentFullyLoaded',
      'DocumentLinkSettingsChanged',
      'DocumentLoaded',
      'DocumentLoading',
      'DocumentSaved',
      'DocumentSaveRequested',
      'DocumentSerialized',
      'DocumentSlugChanged',
      'DocumentSpineChanged',
      'FontAdded',
      'FontDeleted',
      'FontIndexChanged',
      'FontLoaded',
      'FontNameChanged',
      'FrameAdded',
      'FrameAdNotesChanged',
      'FrameAdSettingsChanged',
      'FrameAlternateLayoutSelectionChanged',
      'FrameAnchorChanged',
      'FrameBarcodeSettingsChanged',
      'FrameBorderChanged',
      'FrameChainChanged',
      'FrameConstraintsChanged',
      'FrameDeleted',
      'FrameDisplayQualityChanged',
      'FrameDropShadowChanged',
      'FrameFillChanged',
      'FrameHandlePreferencesChanged',
      'FrameIndexChanged',
      'FrameLayerChanged',
      'FrameLinkSettingsChanged',
      'FrameMoved',
      'FrameMoveFinished',
      'FrameMoveInProgress',
      'FrameMoveRegionChanged',
      'FrameOpacityChanged',
      'FrameRotated',
      'FrameTagChanged',
      'FrameTextVariablesChanged',
      'FrameWrapChanged',
      'GridSettingsChanged',
      'HelpLayerVisibilityChanged',
      'HiddenCharacterVisibilityChanged',
      'IconSetDownloaded',
      'IconSetDownloadStarted',
      'IconSetFullDownloaded',
      'Idle',
      'ImageFrameVariableDefinitionChanged',
      'InlineFrameAdded',
      'InlineFrameDeleted',
      'InlinePanelVisibilityChanged',
      'InlineToolBarVisibilityChanged',
      'LanguageCacheRequiresClearing',
      'LanguageDownloaded',
      'LanguageDownloadStarted',
      'LayerAdded',
      'LayerAlternateLayoutSelectionChanged',
      'LayerContentTypeChanged',
      'LayerDeleted',
      'LayerIndexChanged',
      'LayerMoveRegionChanged',
      'LayerNameChanged',
      'LayerOpacityChanged',
      'LayerPrintingChanged',
      'LayerTextWrapChanged',
      'LayerVisibilityChanged',
      'LayerVisibleVariableChanged',
      'LinkSettingsChanged',
      'LinkSettingsVariableChanged',
      'MarginVisibilityChanged',
      'MeasurementUnitsChanged',
      'MoveRegionVisibilityChanged',
      'ObjectReplaceRequested',
      'PageAdded',
      'PageAlternateLayoutSelectionChanged',
      'PageBackgroundColorChanged',
      'PageBookMarkSettingsChanged',
      'PageCanvasScrollChanged',
      'PageControlsLoaded',
      'PageDeleted',
      'PageIncludeInLayoutChanged',
      'PageIncludeInOutputChanged',
      'PageIndexChanged',
      'PageLabelVisibilityChanged',
      'PageMarginsChanged',
      'PageModeChanged',
      'PageRulerVisibilityChanged',
      'PageSectionChanged',
      'PageVisibilityModeChanged',
      'PageVisibleVariableChanged',
      'PaginationEditorVisibilityChanged',
      'PanelAdded',
      'PanelControlAdded',
      'PanelControlDeleted',
      'PanelControlIndexChanged',
      'PanelControlTitleChanged',
      'PanelControlViewPreferencesChanged',
      'PanelControlVisibilityChanged',
      'PanelDeleted',
      'PanelIndexChanged',
      'PanelTitleChanged',
      'PanelViewPreferencesChanged',
      'ParagraphStyleAdded',
      'ParagraphStyleBackgroundDefinitionChanged',
      'ParagraphStyleColorChanged',
      'ParagraphStyleDefinitionChanged',
      'ParagraphStyleDeleted',
      'ParagraphStyleDiagonalRuleDefinitionChanged',
      'ParagraphStyleIndexChanged',
      'ParagraphStyleNameChanged',
      'ParagraphStyleRuleDefinitionChanged',
      'PathAdded',
      'PathChanged',
      'PathCursorChanged',
      'PathDeleted',
      'PathPointAdded',
      'PathPointDeleted',
      'PathPointMoved',
      'PDFSettingsChanged',
      'PluginAdded',
      'PluginDeleted',
      'PluginIndexChanged',
      'PluginNameChanged',
      'PreflightPreferencesChanged',
      'PreflightResultAdded',
      'PreflightResultDeleted',
      'PreflightResultDescriptionChanged',
      'RotateViewChanged',
      'SearchCompleted',
      'SearchResultAdded',
      'SearchResultDeleted',
      'SearchResultsSettingsChanged',
      'SearchResultsStatusChanged',
      'SearchStarted',
      'SectionBreakVisibilityChanged',
      'SectionVisibleVariableChanged',
      'SelectedAlternateLayoutChanged',
      'SelectedAnnotationChanged',
      'SelectedBookMarkChanged',
      'SelectedCharacterStyleChanged',
      'SelectedColorChanged',
      'SelectedDataSourceChanged',
      'SelectedDocumentEventActionChanged',
      'SelectedFontChanged',
      'SelectedFrameChanged',
      'SelectedImportWarningChanged',
      'SelectedLayerChanged',
      'SelectedPageChanged',
      'SelectedPageMasterChanged',
      'SelectedPanelChanged',
      'SelectedParagraphStyleChanged',
      'SelectedParagraphStyleListSettingsChanged',
      'SelectedPathChanged',
      'SelectedPathPointChanged',
      'SelectedPluginChanged',
      'SelectedSearchResultChanged',
      'SelectedTableCellChanged',
      'SelectedTableColumnChanged',
      'SelectedTableRowChanged',
      'SelectedVariableChanged',
      'SelectionContentChanged',
      'SlugVisibilityChanged',
      'SnapSettingsChanged',
      'SnippetVariablesChanged',
      'SourceViewChanged',
      'SpellCheckDictionariesLoaded',
      'SpellCheckHunSpellFilesLoaded',
      'SpellCheckPreferencesChanged',
      'StoryFrameChanged',
      'StoryViewPreferencesChanged',
      'TableBorderAndFillChanged',
      'TableCellBorderAndFillChanged',
      'TableCellDefinitionChanged',
      'TableColumnAdded',
      'TableColumnBorderAndFillChanged',
      'TableColumnDefinitionChanged',
      'TableColumnDeleted',
      'TableColumnIndexChanged',
      'TableFrameBorderAndFillChanged',
      'TableRowAdded',
      'TableRowBorderAndFillChanged',
      'TableRowDefinitionChanged',
      'TableRowDeleted',
      'TableRowIndexChanged',
      'TabRulerControlVisibilityChanged',
      'TabRulerMarkerSelectionChanged',
      'TabRulerVisibilityChanged',
      'TextColumnGuideVisibilityChanged',
      'TextFormatChanged',
      'TextFrameCopyFittingAppliedPercentageChanged',
      'TextFrameCustomBaseLineGridChanged',
      'TextFrameFlowComposed',
      'TextFrameLinksUpdated',
      'TextFramePathSettingChanged',
      'TextFrameReadOnlyFlowComposed',
      'TextFrameStrokeChanged',
      'TextSelectionChanged',
      'ToolBarItemAdded',
      'ToolBarItemDeleted',
      'ToolBarItemIndexChanged',
      'ToolBarItemViewPreferencesChanged',
      'UndoStackChanged',
      'VariableAdded',
      'VariableDefinitionChanged',
      'VariableDeleted',
      'VariableIndexChanged',
      'VariableNameChanged',
      'VariableRowAdded',
      'VariableRowDeleted',
      'VariableRowIndexChanged',
      'VariableTextDirectionChanged',
      'VariableValueChanged',
      'VariableVisibilityChanged',
      'ViewModeChanged',
      'WarningVisibilityChanged',
      'WorkSpaceChanged',
      'WorkSpaceColorChanged',
      'WorkSpaceInfoChanged',
      'WrapMarginVisibilityChanged',
      'ZoomAfterChange',
      'ZoomBeforeChange',
      'ZoomChanged',
      'ZoomSettingsChanged',
      'DocumentFoldSettingChanged',
      'DocumentOutputCreated',
      'DocumentThreeDModelChanged',
      'PageContentTypeChanged',
      'SnippetVariableDefinitionsChanged',
      'ThreeDModelChanged',
      'ViewPreferenceInfoChanged',
      'CopyFittingStyleAdded',
      'CopyFittingStyleChanged',
      'CopyFittingStyleDeleted',
      'CopyFittingStyleNameChanged',
      'DocumentFoldingSettingsChanged',
      'FoldingSettingsChanged',
      'FrameResized',
      'HighlightedFrameChanged',
      'SelectedCopyFittingStyleChanged',
      'StoryVariableChanged',
    ];

    events.forEach((eventName) => {
      this.editorService.selectEvent$(eventName).subscribe((event) => {console.log(event)});
    })
  }
}
