import { Injectable } from '@angular/core';
import {Subject} from "rxjs/Subject";
import {BehaviorSubject} from "rxjs/index";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../user.service";

@Injectable({
  providedIn: 'root'
})
export class StepsService {
  public stepWasChanged$ = new Subject<any>();
  public currentStep$ = new BehaviorSubject<any>(null);
  public currentStep: number = 0;
  public steps: any[] = [
    'upload',
    'adjust',
    'proof',
    'confirm'
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  public setCurrentStep(stepNumber, orderId, orderStatus = null) {
    this.currentStep = stepNumber;
    this.currentStep$.next(stepNumber);

    if (!orderStatus || orderStatus !== 'confirmed') {
      this.saveCurrentStepByUser(stepNumber, orderId);
    }
  }

  public goToNext(orderItemId){
    const nextStep = this.steps[this.currentStep + 1];

    this.router.navigate([`editor/${nextStep}/${orderItemId}`]);
  }

  public goToPrevious(orderItemId){
    const previousStep = this.steps[this.currentStep - 1];

    this.router.navigate([`editor/${previousStep}/${orderItemId}`]);
  }

  public callStepWasChangedEvent(direction) {
    this.stepWasChanged$.next(direction);
  }

  private saveCurrentStepByUser(stepNumber, orderId) {
    this.userService.getUser()
      .then((user) => {
        if (user && user.id) {
          let orders = JSON.parse(localStorage.getItem(user.id.toString()));

          orders = orders ? orders : {};

          orders[orderId] = this.steps[stepNumber];
          localStorage.setItem(user.id.toString(), JSON.stringify(orders));
        }
      })
  }

  public getCurrentStep(orderId): Promise<any> {
    return this.userService.getUser()
      .then((user) => {
        if (user && user.id) {
          const orders = JSON.parse(localStorage.getItem(user.id.toString()));

          return orders && orders[orderId]? orders[orderId] : this.steps[0];
        }

        return this.steps[0];
      })
  }

  public removeCurrentStep(orderId) {
    return this.userService.getUser()
      .then((user) => {
        if (user && user.id) {
          const orders = JSON.parse(localStorage.getItem(user.id.toString()));

          if (orders && orders[orderId]) {
            delete orders[orderId];

            localStorage.setItem(user.id.toString(), JSON.stringify(orders));
          }
        }
      })
  }
}
