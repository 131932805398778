import { Component, OnInit } from '@angular/core';
import {ThemeService}        from "../theme.service";
import {ClientPageService}   from "../client-page.service";
import { ActivatedRoute }    from '@angular/router';
import { LanguagesService }  from '../languages.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  constructor(
    public themeService: ThemeService,
    public route: ActivatedRoute,
    public clientPageService: ClientPageService
  ) {}

  page: any;
  isProcessed = false;
  error: any;
  path: string;

  ngOnInit() {

    this.themeService.get();

    this.path = this.route.snapshot.url[0].path;

    this.clientPageService.get( this.path == 'uploader' ? 'external-uploader-page' : 'login-page').then((response: any) => {
      this.page = response.page;
    });
  }

}
