import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
}                                     from '@angular/common/http';
import { Observable }                 from 'rxjs';
import {DefaultUrlSerializer, Router} from '@angular/router';
import 'rxjs/add/operator/do';
import { ConfigService }              from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private config: ConfigService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (
      request.url.indexOf('campaigns') > -1 ||
      request.url.indexOf('translation') > -1 ||
      request.url.indexOf('languages') > -1 ||
      request.url.indexOf('tenant-configuration') > -1 ||
      request.url.indexOf('pages/code/login-page') > -1 ||
      request.url.indexOf('pages/code/external-uploader-page') > -1 ||
      request.url.indexOf('placeholders') > -1
    ) {
      return next.handle(request);
    }

    if ((request.url.indexOf('users') > -1 && request.url.indexOf('users/set-new-password') == -1) ||
      (request.url.indexOf('users') > -1 && request.method === 'PUT')
      ) {
      return next.handle(request);
    }

    if (request.url.indexOf('sessions') > -1 && request.method === 'POST') {
      return next.handle(request);
    }

    if (localStorage.getItem('token')) {
      request = request.clone({
        setHeaders: {
          Authorization: `${localStorage.getItem('token')}`
        }
      });
    }


    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          const urlSerializer = new DefaultUrlSerializer();
          const urlTree = urlSerializer.parse(document.location.pathname + document.location.search);
          if (!urlTree.queryParams.expocode && !urlTree.queryParams.token && !this.isAuthorisationPage()) {
            localStorage.removeItem('token');
            this.router.navigate(['login']);
          } else {
            next.handle(request).do((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                // do stuff with response if you want
              }
            });
          }
        }
      }
    });
  }

  isAuthorisationPage() {
    return window.location.pathname == '/login' ||
      window.location.pathname == '/error-page' ||
      window.location.pathname == '/registration' ||
      window.location.pathname == '/forgot' ||
      window.location.pathname == '/uploader';
  }

}
