import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {catchError} from "rxjs/operators";
import {Observable} from "rxjs/Observable";
import {of} from "rxjs/internal/observable/of";

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) { }

  public filtersData(): Promise<any>  {
    return this.http.get(this.config.getActionUrl('products/filters'), { params: { size: '5000' } })
      .pipe(
        catchError(this.handleError('get product filters data', 'error'))
      )
      .toPromise();
  }

  public get(id): Promise<any> {
    return this.http.get(this.config.getActionUrl(`products/${id}`))
      .pipe(
        catchError(this.handleError('get product', null))
      )
      .toPromise();
  }

  public list(query: any = {}): Promise<any> {
    return this.http.get(this.config.getActionUrl(`products`), { params: query })
    .pipe(
      catchError(this.handleError('get products', null))
    )
    .toPromise();
  }

  public saveFromUploader(files): Promise<any> {
    return this.http.post(this.config.getActionUrl('products/save-from-uploader'), { "files": files })
      .pipe(
        catchError(this.handleError('save product', 'error'))
      )
      .toPromise();
  }

  public getMaintags(): Promise<any> {
    return this.http.get(this.config.getActionUrl('products/campaigns')).toPromise();
  }

  public placeholders(): Promise<any> {
    return this.http.get(this.config.getActionUrl('placeholders'), { params: { size: '5000' } })
      .pipe(
        catchError(this.handleError('get placeholders', 'error'))
      )
      .toPromise();
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
