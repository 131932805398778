export class ResetPasswordCommand {
  get getKey(): string {
    return this.key;
  }
  get getConfirmPassword(): string {
    return this.confirm_password;
  }
  get getPassword(): string {
    return this.password;
  }
  
  public password: string;
  public confirm_password: string;
  public key: string;
  
  constructor(password: string, confirm_password: string, key: string) {
    this.password = password;
    this.confirm_password = confirm_password;
    this.key = key;
  }
}
