import {Component, OnInit} from '@angular/core';
import {SettingService} from "./setting.service";
import {Title} from "@angular/platform-browser";
import 'rxjs/add/operator/filter';
import {AuthenticationService} from "./authentication/authentication.service";
import {
  ActivatedRoute, NavigationEnd,
  Router
} from '@angular/router';
import {ThemeService} from "./theme.service";
import {ConfigService} from './config.service';
import {LanguagesService} from './languages.service';
import {TranslationsService} from './translations.service';
import {OrdersService} from './order-item.service';
import {UserService} from './user.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {

  title = 'Printlink';

  isSetDefaultLocale = false;
  isLocalesLoaded = false;
  isLocalesLoaded$: Subject<boolean> = new Subject<boolean>();


  constructor(
    private config: ConfigService,
    private languagesService: LanguagesService,
    private translationsService: TranslationsService,
    private settingsService: SettingService,
    private route: ActivatedRoute,
    private titleService: Title,
    private authenticationService: AuthenticationService,
    private themeService: ThemeService,
    private router: Router,
    private ordersService: OrdersService,
    private usersService: UserService
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe($event => {
      if ($event instanceof NavigationEnd) {
        console.log('Init app component');
        this.isSetDefaultLocale = false;
        let promises: any  = [];
        if(this.isAuthorisationPage()) {
          promises  = [
            this.languagesService.list()
          ];
        } else {
          promises  = [
            this.languagesService.list(),
            this.usersService.getUser()
          ];
        }

        Promise.all(promises).then((values: any) => {
          this.languagesService.setAvailableLocales(values[0].data);
          if(this.isAuthorisationPage()) {
            let currentLocale = values[0].data[0];
            this.languagesService.setCurrentLocale(currentLocale);
            this.config.setCurrentLocale(currentLocale);
          }

          if(!this.isAuthorisationPage() && this.isCurrentLocale()) {
            const locale = JSON.parse(localStorage.getItem('currentLocale'));
            if (locale) {
              localStorage.setItem('isCurrentLocaleFromLocalStorage', localStorage.getItem('currentLocale'));
              let currentLocale = values[0].data.find((item) => {
                return item.code == locale.code;
              });
              this.languagesService.setCurrentLocale(currentLocale);
              this.config.setCurrentLocale(currentLocale);
            }
          }

          if (!this.isAuthorisationPage() && !this.isCurrentLocale() && values[1]) {
            this.initAppAfterUserLoading(values[1]);
          }


          if (this.isAuthorisationPage()) {
            this.languagesService.list().then(() => {
              this.translationsService.list(this.languagesService.getCurrentLocale().id)
                .then((translationsCollection) => {
                  this.applySettings();
                  this.isLocalesLoaded = true;
                });
            });
          } else {
            this.languagesService.list().then(() => {
              this.translationsService.list(this.languagesService.getCurrentLocale().id)
                .then((translationsCollection) => {
                  this.applySettings();
                  this.isLocalesLoaded = true;
                });
            });
          }

          this.languagesService.currentLocaleSet$.subscribe(() => {
            this.translationsService.list(this.languagesService.getCurrentLocale().id)
              .then((translationsCollection) => {
                if (localStorage.getItem('token')) {
                  this.ordersService.get();
                }
                this.applySettings();
              });
          });

        });
      }
    });


    this.route.queryParamMap.subscribe((params: any) => {
      if (params['params'] && params['params'].token) {
        localStorage.setItem('token', params['params'].token);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(this.route.snapshot.url);
      }
    });


    this.themeService.settings$.subscribe((theme: ThemeService) => {
      document.documentElement.style.setProperty('--mat-progress-bar-color', theme.getButtonColor(1));
      document.documentElement.style.setProperty('--checkbox-icon-color', theme.getButtonColor(1));
      document.documentElement.style.setProperty('--checkbox-label-color', theme.getButtonColor(1));
      document.documentElement.style.setProperty('--badge-background-color', theme.getButtonColor(1));
      document.documentElement.style.setProperty('--active-element-hover-color', theme.getButtonColor(1));
      document.documentElement.style.setProperty('--profile-photo-color', theme.getButtonColor(1));
      document.documentElement.style.setProperty('--profile-active-button-color', theme.getButtonColor(1));
      document.documentElement.style.setProperty('--profile-photo-background-color', theme.getButtonColor(0.25));
      document.documentElement.style.setProperty('--alert-background-color', theme.settings.alertBackground);
      document.documentElement.style.setProperty('--alert-text-color', theme.settings.alertTextColor);
      document.documentElement.style.setProperty('--promt-background-color', theme.settings.promtBackground);
      document.documentElement.style.setProperty('--promt-text-color', theme.settings.promtTextColor);
    });
  }

  initAppAfterUserLoading(user) {
    this.isLocalesLoaded = false;
    if (!this.isCurrentLocaleFromLocalStorage() && !this.isAuthorisationPage()) {
      const languagesCodeVariable = user.variableValues.filter((variable) => {
        return variable.type == 'languagescode';
      });
      if (languagesCodeVariable.length > 0) {
        const userLocale = languagesCodeVariable[0].value;
        this.languagesService.getAvailableLocales().map((locale) => {
          if (locale.code.toLowerCase() == userLocale.toLowerCase()) {
            this.languagesService.setCurrentLocale(locale);
            this.config.setCurrentLocale(locale);
          }
        });
      }
    }
    this.isSetDefaultLocale = true;
    this.isLocalesLoaded = true;
  }

  applySettings(): void {
    this.themeService.get();

    this.settingsService.get().subscribe((response: any) => {
      this.titleService.setTitle(response.tenantName);
    });

    if (localStorage.getItem('token')) {
      this.ordersService.get();
    }

  }

  isCurrentLocale() {
    return localStorage.getItem('currentLocale')
      && localStorage.getItem('currentLocale') != undefined
      && localStorage.getItem('currentLocale') != 'undefined'
      && localStorage.getItem('currentLocale') != '';
  }

  isCurrentLocaleFromLocalStorage() {
    return localStorage.getItem('isCurrentLocaleFromLocalStorage')
      && localStorage.getItem('isCurrentLocaleFromLocalStorage') != undefined
      && localStorage.getItem('isCurrentLocaleFromLocalStorage') != 'undefined'
      && localStorage.getItem('isCurrentLocaleFromLocalStorage') != '';
  }

  isAuthorisationPage() {
    return window.location.pathname == '/login' ||
      window.location.pathname == '/error-page' ||
      window.location.pathname == '/registration' ||
      window.location.pathname == '/forgot' ||
      window.location.pathname.indexOf('/reset') == 0 ||
      window.location.pathname == '/uploader' ||
      !localStorage.getItem('token');
  }
}
