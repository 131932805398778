<div class="popover-container">
  <button mat-icon-button (click)="onNoClick($event)">
    <mat-icon>close</mat-icon>
  </button>
  <div class="images-list" *ngIf="images.length > 1">
    <img [src]="image" *ngFor="let image of images; let i = index;" (click)="selectImage(image)">
  </div>
  <div class="image-wrapper" [ngStyle]="{'width': images.length > 1 ? '80%' : '100%'}">
    <div class="image" [ngStyle]="{'background-image': 'url(' + selectedImage + ')'}"></div>
  </div>
</div>
