import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';

import { FormsModule }                from '@angular/forms';
import { AppRoutingModule }           from './app-routing.module';
import { HomeModule }                 from './home/home.module';
import { AppComponent }               from './app.component';
import { MaterialModule }             from './app.material';
import { ChangesHoverStyleDirective } from './changes-hover-style.directive';
import { AuthenticationInterceptor }  from './authentication/authentication.interceptor';
import { LoginComponent }             from './login/login.component';
import { ConfigService }              from './config.service';
import { SafePipe }                   from './safe.pipe';
import { ErrorComponent }            from './error/error.component';
import {BrowserAnimationsModule}     from "@angular/platform-browser/animations";
import {HttpCancelInterceptor}       from "./http-cancel.interseptor";
import { RestorePasswordComponent }  from './login/restore-password/restore-password.component';
import { LoginFormComponent }        from './login/login-form/login-form.component';
import { ResetPasswordComponent }    from './reset-password/reset-password.component';
import localeNlBe                    from '@angular/common/locales/nl-BE';
import { CheckoutModule }            from './checkout/checkout.module';
import {GlobalModule}                from "./global/global.module";
import { RegistrationFormComponent } from './login/registration-form/registration-form.component';
import { ExternalUploaderComponent } from './login/external-uploader/external-uploader.component';
import { FileUploaderDirective }     from './file-uploader.directive';
import { BytesPipe }                 from './bytes.pipe';

registerLocaleData(localeNlBe, 'nl-BE');

@NgModule({
  declarations: [
    AppComponent,
    ChangesHoverStyleDirective,
    FileUploaderDirective,
    LoginComponent,
    SafePipe,
    ErrorComponent,
    RestorePasswordComponent,
    LoginFormComponent,
    ResetPasswordComponent,
    RegistrationFormComponent,
    ExternalUploaderComponent,
    BytesPipe
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    AppRoutingModule,
    HttpClientModule,
    HomeModule,
    CheckoutModule,
    FormsModule,
    BrowserAnimationsModule,
    GlobalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor,
      multi: true,
    },
    ConfigService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
