<div class="dialog-container">
  <div class="dialog-title">
    <img [src]="data.icon | safe: 'resourceUrl'" alt="Alert">
  </div>
  <div class="dialog-content">
    <div class="dialog-text" [innerHtml]="data.message | safe:'html' "></div>
    <div class="dialog-action">
      <button mat-button cdkFocusInitial (click)="close()" [innerHtml]="data.cancelLabel | safe:'html' "></button>
      <button mat-button cdkFocusInitial (click)="apply()" [innerHtml]="data.applyLabel | safe:'html' "></button>
    </div>
  </div>
</div>
