

<div class="main-container">
  <div class="name" *ngIf="currentImage">{{currentImage.originalName}}</div>
    <div class="preview-container" [ngStyle]="{'background-image': 'url(' + url + ')'}">
      <div class="progress" *ngIf="isProcessed">
        <mat-progress-bar mode="query" ></mat-progress-bar>
      </div>
      <app-image-loading-detector
        *ngIf="!showPreview && url"
        [image]="url"
        (wasLoaded)="loadHandler($event)">
      </app-image-loading-detector>
    </div>
  <div class="status" [ngClass]="[currentImage.status]" *ngIf="currentImage">{{currentImage.status}}</div>
</div>
