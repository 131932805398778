<div class="container" *ngIf="form">
  <h1 appI18nTranslation>Delivery Information:</h1>
  <form [formGroup]="deliveryInformation">
    <div class="form-line" *ngFor="let line of form.lines; let i = index;">
      <div class="form-field" [ngClass]="field.icon" *ngFor="let field of line.fields; let fi = index;"
           [ngStyle]="{ 'width': 'calc((100% / ' + line.fields.length + ') - 65px)' }">
        <label *ngIf="field.icon != 'check_box'">{{field.input_label}}: <span class="red" *ngIf="field.is_required">*</span></label>
        <span *ngIf="field.icon == 'check_box'"   [ngClass]="{'error': (( field.icon == 'email' && deliveryInformation.get(field.formControlName).hasError('email')) || (field.is_required && deliveryInformation.get(field.formControlName).hasError('required'))) && (deliveryInformation.get(field.formControlName).dirty || deliveryInformation.get(field.formControlName).touched)}">
          <input type="checkbox" [formControlName]="field.formControlName">
        </span>
        <label *ngIf="field.icon == 'check_box'"><span class="red" *ngIf="field.is_required">*</span> {{field.input_label}}</label>
        <input type="number" [placeholder]="field.input_label" *ngIf="field.icon=='money'" [formControlName]="field.formControlName"  [ngClass]="{'error': (( field.icon == 'money' && (deliveryInformation.get(field.formControlName).hasError('numeric') || deliveryInformation.get(field.formControlName).hasError('maxLength')) ) || (field.is_required && deliveryInformation.get(field.formControlName).hasError('required'))) && (deliveryInformation.get(field.formControlName).dirty || deliveryInformation.get(field.formControlName).touched)}">
        <input type="text" [placeholder]="field.input_label" *ngIf="field.icon=='text_fields'" [formControlName]="field.formControlName"  [ngClass]="{'error': ((field.is_required && deliveryInformation.get(field.formControlName).hasError('required'))) && (deliveryInformation.get(field.formControlName).dirty || deliveryInformation.get(field.formControlName).touched)}">
        <div class="file_upload" *ngIf="field.icon=='file_upload'">
          <!-- Display files names -->
          <input matInput readonly [(ngModel)]="fileAttr" [ngModelOptions]="{standalone: true}" name="name" [placeholder]="field.input_label"  [ngClass]="{'error': ((field.is_required && deliveryInformation.get(field.formControlName).hasError('required'))) && (deliveryInformation.get(field.formControlName).dirty || deliveryInformation.get(field.formControlName).touched)}"/>

          <input type="file" #fileInput class="uploadFile" (change)="uploadFileEvt($event, field.formControlName)" name="uploadFile"
                 accept="image/*" />

          <!-- Browse Button -->
          <button mat-flat-button color="primary" (click)="fileInput.click()" appI18nTranslation>
            Browse File
          </button>

        </div>
        <input type="email" [placeholder]="field.input_label" *ngIf="field.icon=='email'" [formControlName]="field.formControlName"  [ngClass]="{'error': (( field.icon == 'email' && deliveryInformation.get(field.formControlName).hasError('email')) || (field.is_required && deliveryInformation.get(field.formControlName).hasError('required'))) && (deliveryInformation.get(field.formControlName).dirty || deliveryInformation.get(field.formControlName).touched)}">
        <span *ngIf="field.icon == 'radio_button_checked'"   [ngClass]="{'error': (( field.icon == 'email' && deliveryInformation.get(field.formControlName).hasError('email')) || (field.is_required && deliveryInformation.get(field.formControlName).hasError('required'))) && (deliveryInformation.get(field.formControlName).dirty || deliveryInformation.get(field.formControlName).touched)}"><input type="radio" [formControlName]="field.formControlName"> {{field.input_label}}</span>
        <select *ngIf="field.icon == 'list'" [formControlName]="field.formControlName"   [ngClass]="{'error': (( field.icon == 'email' && deliveryInformation.get(field.formControlName).hasError('email')) || (field.is_required && deliveryInformation.get(field.formControlName).hasError('required'))) && (deliveryInformation.get(field.formControlName).dirty || deliveryInformation.get(field.formControlName).touched)}">
          <option value="" disabled selected>{{field.input_label}}</option>
          <option [value]="option" *ngFor="let option of field.options.split(','); let i = index;">{{option}}</option>
        </select>
        <textarea rows="10" *ngIf="field.icon == 'text_snippet'" [placeholder]="field.input_label" [formControlName]="field.formControlName"   [ngClass]="{'error': (( field.icon == 'email' && deliveryInformation.get(field.formControlName).hasError('email')) || (field.is_required && deliveryInformation.get(field.formControlName).hasError('required'))) && (deliveryInformation.get(field.formControlName).dirty || deliveryInformation.get(field.formControlName).touched)}"></textarea>
      </div>

    </div>
  </form>
  <div class="actions">
    <button mat-button class="continue" [ngStyle]="{'border-color': (themeService.settings$ | async).getButtonColor(1), 'color': (themeService.settings$ | async).getButtonColor(1)}" (click)="previous()"><mat-icon>keyboard_backspace</mat-icon><span appI18nTranslation>Shopping cart</span></button>
    <button mat-button class="checkout" [ngStyle]="{'background-image': (themeService.settings$ | async).getGradienString()}" (click)="next()" appI18nTranslation>Next</button>
  </div>
</div>
