import { Component, OnInit }      from '@angular/core';
import { ThemeService }           from '../../theme.service';
import { ClientPageService }      from '../../client-page.service';
import { RestorePasswordService } from '../restore-password.service';
import { RestoreCommand }         from '../commands/restore.command';
import {
  ActivatedRoute,
  Router
}                                 from '@angular/router';
import { HttpErrorResponse }      from '@angular/common/http';
import { NotificationService }    from '../../global/notification.service';
import { LanguagesService }       from '../../languages.service';

@Component({
  selector: 'fu-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.sass']
})
export class RestorePasswordComponent implements OnInit {

  constructor(public themeService: ThemeService,
              public route: ActivatedRoute,
              private languagesService: LanguagesService,
              public clientPageService: ClientPageService,
              public restoreService: RestorePasswordService,
              public router: Router,
              public notification: NotificationService) { }

  page: any;
  data: RestoreCommand = new RestoreCommand('');
  isProcessed = false;
  error: any;

  ngOnInit() {

    this.themeService.get();

    this.clientPageService.get('login-page').then((response: any) => {
      this.page = response.page;
    });
  }

  handleRestore(event) {
    this.error = null;
    this.isProcessed = true
    this.restoreService.create(this.data)
      .then((response: any) => {
        this.notification.alert(5, { 'email': this.data.email })
          .then(() => {
            this.router.navigate(['login']);
          });
        this.isProcessed = false;
      }, ( response: HttpErrorResponse) => {
        this.isProcessed = false;
        if (response.status === 404) {
          this.notification.alert(6, { 'email': this.data.email });
        } else {
          this.notification.alert(response.error.error.message);
        }
      });
  }

}
