import { NgModule }               from '@angular/core';
import { Routes, RouterModule }   from '@angular/router';
import { LoginComponent }         from './login/login.component';
import { ErrorComponent }         from './error/error.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CanActivateExpoCode }    from './authentication/authentication.guard';

let routes: Routes = [
    { path: '', loadChildren: './home/home.module#HomeModule', canActivate: [CanActivateExpoCode]},
    { path: 'error-page', component: ErrorComponent},
    { path: 'login', component: LoginComponent},
    { path: 'forgot', component: LoginComponent},
    { path: 'registration', component: LoginComponent},
    { path: 'uploader', loadChildren: './uploader/uploader.module#UploaderModule'},
    { path: 'reset/:restore_key', component: ResetPasswordComponent},
    { path: 'editor', loadChildren: './editor/editor.module#EditorModule', canActivate: [CanActivateExpoCode]},
    { path: 'profile', loadChildren: './user/user.module#UserModule', canActivate: [CanActivateExpoCode]},
    { path: 'checkout', loadChildren: './checkout/checkout.module#CheckoutModule', canActivate: [CanActivateExpoCode]},
    { path: '**', redirectTo: 'error-page'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CanActivateExpoCode]
})
export class AppRoutingModule {
}
