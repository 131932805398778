import { OrderItem } from './order-item';

export class OrdersResponse {
  id: number;
  status: string;
  orderItems: OrderItem[];
  createdAt: string;
  updatedAt: string;
  price: number;
}
