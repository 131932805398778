import {
  Directive,
  ElementRef,
  OnInit,
  Renderer2
} from '@angular/core';
import { TranslationsService } from '../translations.service';

@Directive({
  selector: '[appI18nTranslation]'
})
export class I18nTranslationDirective implements OnInit {

  constructor(
    private el: ElementRef,
    private _renderer: Renderer2,
    private translationsService: TranslationsService
  ) {}

  ngOnInit() {
    this._renderer.setAttribute(this.el.nativeElement, 'i18n-source', this.el.nativeElement.innerText);
    if (this.el.nativeElement.getAttribute('placeholder')) {
      this._renderer.setAttribute(this.el.nativeElement, 'i18n-placeholder-source', this.el.nativeElement.getAttribute('placeholder'));
    }
    this.translate();
    this.translationsService.translationsLoaded$.subscribe(() => {
      this.translate();
    });

  }

  translate() {
    const translation = this.translationsService.translations.find((t) => {
      return t.source.toLowerCase() === this.el.nativeElement.getAttribute('i18n-source').toLowerCase();
    });

    if (translation && translation.target && translation.target != '') {
      switch (this.el.nativeElement.nodeName) {
        case 'MAT-CHECKBOX':
          this.el.nativeElement.children[0].children[1].innerText = translation.target;
          break;
        default:
          this.el.nativeElement.innerText = translation.target;
          break;
      } // /editor/proof/698
    } else {
      this.el.nativeElement.innerText = this.el.nativeElement.getAttribute('i18n-source');
    }
    if (this.el.nativeElement.getAttribute('placeholder')) {
      const translationPlaceholder = this.translationsService.translations.find((t) => {
        if (!this.el.nativeElement.getAttribute('i18n-placeholder-source')) {
          return false;
        }
        return t.source.toLowerCase() === this.el.nativeElement.getAttribute('i18n-placeholder-source').toLowerCase();
      });

      if (translationPlaceholder && translationPlaceholder.target && translationPlaceholder.target != '') {
        document.querySelector('#' + this.el.nativeElement.getAttribute('id')).setAttribute('placeholder', translationPlaceholder.target);
      } else {
        document.querySelector('#' + this.el.nativeElement.getAttribute('id')).setAttribute('placeholder', this.el.nativeElement.getAttribute('i18n-placeholder-source'));
      }
    }

  }

}
