import { Component, OnInit } from '@angular/core';
import { ThemeService }      from '../../theme.service';
import { OrdersService }     from '../../order-item.service';
import { StepsService }      from '../steps/steps.service';
import { ActivatedRoute }    from '@angular/router';
import { LanguagesService }  from '../../languages.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.sass']
})
export class CheckoutComponent implements OnInit {

  path = 'cart';

  constructor(
    public themeService: ThemeService,
    public ordersService: OrdersService,
    public stepService: StepsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.path = this.route.snapshot.routeConfig.path;
    this.stepService.setCurrentStep(this.route.snapshot.routeConfig.path);
    this.themeService.get();
  }

}
