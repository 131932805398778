<div class="container" *ngIf="order && order.orderItems && order.orderItems.length">
  <div class="total">
    <div class="count">
      <span appI18nTranslation>Order items:</span> <span class="red" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}"> ({{order.orderItems.length}})</span>
    </div>
    <div class="right">
      <div class="price" *ngIf="creditSettings.enable && this.order">
        <span appI18nTranslation>Credits used:</span> <span class="red" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}"> {{ getCreditsUsed() | number:'1.1-3' }} <span appI18nTranslation>Credits</span></span>
      </div>
      <div class="price">
        <span appI18nTranslation>Total price:</span> <span class="red" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}"> {{getOrderPrice() | currency: 'EUR':'code':'1.2-3':'nl-BE'}}</span>
      </div>
    </div>
  </div>
  <mat-list>
    <mat-list-item *ngFor="let item of order.orderItems">
      <div class="item-image">
        <img [src]="item.documents[0].files.images[0].file.url" [alt]="item.name" *ngIf="!item.previewUrl && item.documents.length != 0 && item.documents[0].files.images.length > 0">
        <img [src]="item.previewUrl" [alt]="item.name" *ngIf="!item.previewUrl && item.documents.length != 0 && item.documents[0].files.images.length == 0">
        <img [src]="item.previewUrl" [alt]="item.name" *ngIf="item.previewUrl">
      </div>
      <div class="item-information" [ngStyle]="{ 'width': !page.comment.isShown ? '50%' : '25%'}">
        <div class="item-title">
          <h1 *ngIf="!item.productgroupId || !getProductgroup(item.productgroupId)">{{item.name}}</h1>
          <h1 *ngIf="item.productgroupId && getProductgroup(item.productgroupId)">{{getProductgroup(item.productgroupId).name}}</h1>
          <mat-select *ngIf="item.productgroupId"  placeholder="Select product..." (valueChange)="selectOtherProduct($event, item)"  [(ngModel)]="item.productId">
            <mat-option *ngFor="let product of getProductgroup(item.productgroupId).products" [value]="product.id">
              {{product.name}}
            </mat-option>
          </mat-select>
        </div>
        <div class="item-description" [innerHtml]="item.notes | truncate: 40"></div>
        <div class="item-price" [ngStyle]="{'color': themeService.getButtonColor(1)}" *ngIf="isShowCurrencyPrice(item)">{{getItemPrice(item) | currency: 'EUR':'symbol':'1.2-3':'nl-BE'}}</div>
        <div class="item-price" [ngStyle]="{'color': themeService.getButtonColor(1)}" *ngIf="isShowCreditsPrice(item)">{{ (getItemPrice(item) / creditSettings.creditValue) | number:'1.1-3'}} <span appI18nTranslation>Credits</span></div>
      </div>
      <div class="item-information" *ngIf="page.comment.isShown">

        <label appI18nTranslation>Comment</label>
        <textarea class="item-comments" (change)="changeComments(item)" [(ngModel)]="item.comments"></textarea>
      </div>
      <div class="item-edit">
<!--        <a mat-button [routerLink]="['/editor', 'upload', item.id]" [ngClass]="item.status" *ngIf="item.status == 'editing' && !isWebshopItem(item)"><span appI18nTranslation>Edit</span> <mat-icon>insert_photo</mat-icon></a>-->
        <button mat-button class="editing" (click)="edit(item)" *ngIf="isShowEditButton(item)">
          <span appI18nTranslation>Edit</span>
<!--          <mat-icon>insert_photo</mat-icon>-->
          <div class="icon-edit"></div>
        </button>
        <button mat-button class="confirmed" (click)="view(item)" *ngIf="isShowViewButton(item)"><span appI18nTranslation>View</span> <mat-icon>insert_photo</mat-icon></button>
        <button mat-button class="download" (click)="download(item)" [disabled]="item.downloadProcessing" *ngIf="isShowDownloadButton(item)">
          <span appI18nTranslation>Download</span>
          <mat-icon>insert_photo</mat-icon>
          <div class="progress-container" *ngIf="item.downloadProcessing">
            <mat-progress-spinner diameter="14" mode="indeterminate"></mat-progress-spinner>
          </div>
        </button>
      </div>
      <div class="item-status-and-quantity">
        <div class="item-status">
          <span class="editing" appI18nTranslation *ngIf="item.status == 'editing'">Editing</span>
          <span class="confirmed" appI18nTranslation *ngIf="item.status == 'confirmed'">Confirmed</span>
          <span class="pending" appI18nTranslation *ngIf="item.status == 'pending'">Pending</span>
        </div>
        <div class="item-quantity">
          <mat-form-field>
            <button mat-icon-button class="reduce" appI18nTranslation-aria-label aria-label="Reduce the amount of this product" (click)="reduce(item)" [disabled]="isDisabledReduce(item)">
              <mat-icon [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">remove</mat-icon>
            </button>
            <input matInput type="text" [(ngModel)]="item.quantity" [disabled]="!item.settings.isManualInput" (change)="manualInput(item)">
            <button mat-icon-button class="increase" appI18nTranslation-aria-label aria-label="Increase the amount of this product" (click)="increase(item)" [disabled]="isDisabledIncrease(item)">
              <mat-icon [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">add</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="item-remove">
        <button mat-icon-button appI18nTranslation-aria-label aria-label="Remove product" (click)="remove(item)" *ngIf="isShowRemoveButton(item)">
          <mat-icon [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">delete</mat-icon>
        </button>
        <button mat-icon-button [ngStyle]="{ 'opacity': 0 }" *ngIf="!isShowRemoveButton(item)">
          <mat-icon [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">delete</mat-icon>
        </button>
      </div>
    </mat-list-item>
  </mat-list>
  <div class="actions">
    <button mat-button class="continue" [ngStyle]="{'border-color': (themeService.settings$ | async).getButtonColor(1), 'color': (themeService.settings$ | async).getButtonColor(1)}" (click)="previous()"><mat-icon>keyboard_backspace</mat-icon><span appI18nTranslation>Continue shopping</span></button>
    <button mat-button class="checkout" [ngStyle]="{'background-image': (themeService.settings$ | async).getGradienString()}" (click)="next()" appI18nTranslation>Next</button>
  </div>
</div>
