import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpProgressEvent,
  HttpResponse
}                     from '@angular/common/http';

import { scan }          from 'rxjs/operators';
import { ConfigService } from './config.service';

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(
    event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (
      event.type === HttpEventType.DownloadProgress ||
      event.type === HttpEventType.UploadProgress
  );
}

export interface Upload {
  response?: any;
  progress: number;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
}

@Injectable({
  providedIn: 'root'
})
export class UploaderService {

  constructor(
      private http: HttpClient,
      private config: ConfigService
  ) { }

  post(data: FormData) {
    const initialState: Upload = { state: 'PENDING', progress: 0 };
    const calculateState = (upload: Upload, event: HttpEvent<unknown>): Upload => {
      if (isHttpProgressEvent(event)) {
        return {
          progress: event.total
              ? Math.round((100 * event.loaded) / event.total)
              : upload.progress,
          state: 'IN_PROGRESS',
        };
      }
      if (isHttpResponse(event)) {
        return {
          response: event,
          progress: 100,
          state: 'DONE',
        };
      }
      return upload;
    };

    return this.http
        .post(this.config.getActionUrl('products/uploader'), data, {
          reportProgress: true,
          observe: 'events',
        })
        .pipe(scan(calculateState, initialState));
  }

  match(data: any) {
    return this.http
      .post(this.config.getActionUrl('products/matcher'), data)
      .toPromise();
  }

  process(data: any) {
    return this.http
      .post(this.config.getActionUrl('products/process'), data)
      .toPromise();
  }

  sendVerificationEmail(verificationData: any) {
    return this.http
      .post(this.config.getActionUrl('products/send-verification-email'), verificationData)
      .toPromise();
  }

  variifyCode( params: { code: string }) {
    return this.http
      .post(this.config.getActionUrl('products/verify-verification-code'), params)
      .toPromise();
  }
}
