import {Component, Inject, OnInit} from '@angular/core';
import {ThemeService} from "../../theme.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PromoProductsService} from "../../promo-products.service";
import {HelpdeskComponent} from "../helpdesk/helpdesk.component";

@Component({
  selector: 'global-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.sass']
})
export class ContactInformationComponent implements OnInit {
  public contactForm: FormGroup;
  confirmed: boolean = false;

  constructor(
    public themeService: ThemeService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<HelpdeskComponent>,
    private promoProductsService: PromoProductsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.setForm();
  }

  private setForm() {
    this.contactForm = this.formBuilder.group({
      promo_product_id: [this.data.id, [Validators.required]],
      FirstName: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      remarks: ['', [Validators.required]],
      Company: ['', [Validators.required]],
      amount: ['', [Validators.required]],
    });
  }

  send() {
    if (this.contactForm.invalid) {
      return;
    }

    const data = this.contactForm.value;
    data.phone = data.phone.number;

    this.promoProductsService.sendMail(data)
      .then(() => {
        this.confirmed = true;
      })
  }
}
