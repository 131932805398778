import { NgModule }                                               from '@angular/core';
import { MatInputModule, MatProgressBarModule, MatToolbarModule } from '@angular/material';
import { MatListModule }                                          from '@angular/material';
import { MatButtonModule }                                        from '@angular/material/button';
import { MatIconModule }                                          from '@angular/material/icon';
import {MatSnackBarModule}                                        from '@angular/material/snack-bar';
import {MatDialogModule}                                          from '@angular/material/dialog';
import {MatCheckboxModule}                                        from '@angular/material/checkbox';
import {MatSelectModule}                                          from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatStepperModule} from '@angular/material/stepper';

const modules = [
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
    MatSnackBarModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTooltipModule,
    MatInputModule,
    MatStepperModule
];

@NgModule({
  imports: modules,
  exports: modules
})

export class MaterialModule { }
