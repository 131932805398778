import { NgModule }                                               from '@angular/core';
import { MatInputModule, MatProgressBarModule, MatToolbarModule } from '@angular/material';
import { MatListModule }                                          from '@angular/material';
import { MatButtonModule }                                        from '@angular/material/button';
import { MatIconModule }                                          from '@angular/material/icon';
import {MatSnackBarModule}                                        from '@angular/material/snack-bar';
import {MatDialogModule}                                          from '@angular/material/dialog';
import {MatCheckboxModule}                                        from '@angular/material/checkbox';
import {MatSelectModule}                                          from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from "@angular/material/menu";
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const modules = [
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSnackBarModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTooltipModule,
    MatInputModule,
    MatMenuModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatExpansionModule
];

@NgModule({
  imports: modules,
  exports: modules
})
export class MaterialModule { }
