import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChiliEditorComponent } from './chili-editor/chili-editor.component';
import {MaterialModule} from "../../app.material";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [ChiliEditorComponent],
  exports: [ChiliEditorComponent],
  entryComponents: []
})
export class ChiliEditorModule { }
