import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {PreviewComponent} from "../../editor/proof-step/preview/preview.component";
import {ClientPage} from "../../client-page";
import {Subscription} from "rxjs/Subscription";
import {ThemeService} from "../../theme.service";
import {ClientPageService} from "../../client-page.service";
import {ExportService} from "../../editor/export.service";
import {ActivatedRoute} from "@angular/router";
import {EmbeddedOrderItemsService} from "../../editor/embedded-order-items.service";
import {DownloadService} from "../../editor/download.service";
import {StepsService} from "../../editor/steps/steps.service";
import {OrdersService} from "../../order-item.service";
import {AuthenticationService} from "../../authentication/authentication.service";
import * as _ from "lodash";
import {SendEmailComponent} from "../../editor/proof-step/send-email/send-email.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-view-popup',
  templateUrl: './view-popup.component.html',
  styleUrls: ['./view-popup.component.sass']
})
export class ViewPopupComponent implements OnInit {

  @ViewChild('previewComponent') previewComponent: PreviewComponent;

  page: ClientPage;
  orderItem: any;
  selectedProduct: any;
  selectedPage: any = {name: 1};
  groupProducts: any[] = [];
  documentPages: any[] = []
  previewIsShown: boolean = true;
  threeDIsShown: boolean = false;
  previewUrl: any = null;
  isProcessed: boolean = false;

  stepSubscription: Subscription;

  constructor(   public themeService: ThemeService,
                 public clientPageService: ClientPageService,
                 public exportService: ExportService,
                 private downloadService: DownloadService,
                 private route: ActivatedRoute,
                 private embeddedOrderItemsService: EmbeddedOrderItemsService,
                 private stepsService: StepsService,
                 private ordersService: OrdersService,
                 public dialogRef: MatDialogRef<ViewPopupComponent>,
                 @Inject(MAT_DIALOG_DATA) public data: any,
                 public dialog: MatDialog,
                 private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      if (params['params'] && params['params'].token) {
        this.getToken(params['params'].token)
          .subscribe(() => {
            this.getInitialData();
          });
      } else {
        this.getInitialData();
      }
    });
  }

  ngOnDestroy() {
    if (this.stepSubscription && !this.stepSubscription.closed) {
      this.stepSubscription.unsubscribe();
    }
  }

  private getInitialData() {
    this.getOrder();
    this.getPageSettings();
  }

  private getToken(token) {
    return this.authenticationService.getPermanentToken(token);
  }

  private getPageSettings() {
    this.page = new ClientPage();

    this.clientPageService.get('item-proof').then((response: any) => {
      this.page = response.page;
    });
  }

  private getOrder(){
    this.ordersService.getOrderItem(this.data.orderItemId)
      .then((order) => {
        this.orderItem = order.orderItem;
        this.watchForSteps();

        if (this.orderItem) {
          this.stepsService.setCurrentStep(2, this.orderItem.id, this.orderItem.status);
          this.groupProducts = this.orderItem.documents;
          this.getProduct();
          this.showGroupView();
        }
      })
  }

  private watchForSteps() {
    this.stepSubscription = this.stepsService.stepWasChanged$.subscribe((direction) => {
      if (direction && direction === 'previous' && this.orderItem.status !== 'confirmed') {
        this.stepsService.goToPrevious(this.orderItem.id);
      }

      if (
        direction
        && direction === 'next'
        && this.orderItem
        && this.orderItem.id
        && this.orderItem.status !== 'confirmed'
      ) {
        this.goToNext();
      }
    });
  }

  private getProduct(){
    return this.ordersService.getProduct(this.orderItem.productId)
      .then((data) => {
        if (data) {
          let product = data.product;
          this.orderItem['schemaType'] = product.previewSchema.schemaType;
        }
      });
  }

  public showGroupView() {
    console.log(this.groupProducts);
    if (this.orderItem['schemaType'] && this.orderItem['schemaType']  !== 'none') {
      this.showThreeD();
    }
    this.selectProduct(this.groupProducts[0]);
  }

  public selectProduct(product) {
    // console.log('selectProduct: ', product)
    this.selectedProduct = product;
    this.ordersService.getPages(this.orderItem.id, this.selectedProduct.id)
      .then((result: any) => {
        if (result && result.pages) {
          this.documentPages =  result.pages;
          this.selectedPage = this.documentPages[0];
        }
      });
    this.showPreview();

    this.previewUrl = null;
  }

  public selectPage(page) {
    this.selectedPage = page;
  }

  public goToNext() {
    this.stepsService.goToNext(this.orderItem.id);
  }

  public showPreview() {
    this.threeDIsShown = false;
    this.previewIsShown = true;
  }

  public showThreeD() {
    this.threeDIsShown = true;
    this.previewIsShown = false;
  }

  public export(){
    this.isProcessed = true;

    this.exportService.export(this.orderItem.id, this.selectedProduct.id)
      .then((result) => {
        if (result) {
          this.previewUrl = result;

          this.download()
            .then(() => {
              this.isProcessed = false;
            });
        }
      });
  }

  private downloadJpeg($event) {
    return this.exportService.getPreviewJpeg(this.previewComponent.url)
      .then((res) => {
        if (res) {
          this.downloadService.download(res);
        }
      })
  }

  private download() {
    return this.exportService.getPreviewPdf(this.previewUrl)
      .then((res) => {
        if (res) {
          this.downloadService.download(res);
        }
      })
  }

  public openSendMailDialog() {
    const data = {
      id: this.orderItem.id
    };

    const dialogRef = this.dialog.open(SendEmailComponent, {
      minWidth: '400px',
      data: data
    });

    return dialogRef.afterClosed();
  }

}
