<div class="container">
  <div class="logo">
    <img [src]="(themeService.settings$ | async).getLogoUrl()" [alt]="themeService.settings.name">
  </div>
  <div class="content">
    <span class="location"
          *ngIf="
        themeService
        && themeService.settings
        && themeService.settings.address
        && themeService.settings.address !== 'undefined'
        && themeService.settings.address !== 'null'"
    >
      <div class="icon" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}"></div>
      {{themeService.settings.address}}
    </span>
    <span class="phone"
          *ngIf="
        themeService
        && themeService.settings
        && themeService.settings.phone
        && themeService.settings.phone !== 'undefined'
        && themeService.settings.phone !== 'null'"
    >
      <div class="icon" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}"></div>
      {{themeService.settings.phone}}
    </span>
  </div>
  <div class="social-share">
    <a
      *ngIf="
        themeService
        && themeService.settings
        && themeService.settings.facebook
        && themeService.settings.facebook !== 'undefined'
        && themeService.settings.facebook !== 'null'"
      mat-button class="facebook" target="_blank" [href]="themeService.settings.facebook" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(0.15)}">
      <div class="icon facebook" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}"></div>
    </a>
    <a *ngIf="
      themeService
      && themeService.settings
      && themeService.settings.twitter
      && themeService.settings.twitter !== 'undefined'
      && themeService.settings.twitter !== 'null'"
       mat-button class="twitter" target="_blank" [href]="themeService.settings.twitter" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(0.15)}">
      <div class="icon twitter" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}"></div>
    </a>
    <a *ngIf="
        themeService
        && themeService.settings
        && themeService.settings.linkedIn
        && themeService.settings.linkedIn !== 'undefined'
        && themeService.settings.linkedIn !== 'null'"
       mat-button class="linkedin" target="_blank" [href]="themeService.settings.linkedIn" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(0.15)}">
      <div class="icon linkedin" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}"></div>
    </a>
    <a *ngIf="
      themeService
      && themeService.settings
      && themeService.settings.link
      && themeService.settings.link !== 'undefined'
      && themeService.settings.link !== 'null'"
       mat-button class="link" target="_blank" [href]="themeService.settings.link" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(0.15)}">
      <div class="icon link" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}"></div>
    </a>
  </div>
</div>
