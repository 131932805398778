<div class="container">
  <div class="wrapper">
    <h3 [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(0.7) }">{{themeService.settings.name}}</h3>
    <h1 [innerHTML]="page.title.title | safe:'html'" *ngIf="page.title && page.title.isShown"></h1>
    <h4 [innerHTML]="page.sub_title.sub_title | safe:'html'" *ngIf="page.sub_title && page.sub_title.isShown"></h4>
    <div class="upload-button-container" *ngIf="page.sub_title && page.sub_title.isShownUploadButton">
      <button mat-button (click)="openUploadDialog($event)" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}" >
        <mat-icon class="material-icons-outlined">cloud_upload</mat-icon>
        <span>{{page.sub_title.uploadButtonText}}</span>
      </button>
    </div>
  </div>
</div>
