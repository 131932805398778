<div class="container" *ngIf="page.locations && page.locations.isShown">
  <div class="point" *ngIf="page.locations.title" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}"></div>
  <h1 *ngIf="page.locations.title" [innerHTML]="page.locations.title | safe:'html'"></h1>
  <div class="locations">
    <mat-list>
      <mat-list-item *ngFor="let location of page.locations.items; let i = index" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1), 'box-shadow': '0 25px 45px 5px ' + (themeService.settings$ | async).getButtonColor(0.25)}">
        <span class="number" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">{{ i + 1 }}</span> <span class="title" [innerHTML]="location.description | safe:'html'"></span>
      </mat-list-item>
    </mat-list>
  </div>
</div>
