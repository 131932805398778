import { Injectable }    from '@angular/core';
import { HttpClient }    from '@angular/common/http';
import { Observable }    from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(public http: HttpClient, private configService: ConfigService) { }

  get(): Observable<Object> {
    return this.http.get(this.configService.getLanguagesUrl('tenant-configuration'));
  }
}
