import { Injectable }           from '@angular/core';
import {Subject}                from "rxjs/Subject";
import {BehaviorSubject}        from "rxjs/index";
import {ActivatedRoute, Router} from "@angular/router";
import { Step }                 from './step.class';

@Injectable({
  providedIn: 'root'
})
export class StepsService {
  public stepWasChanged$ = new Subject<any>();
  public stepsWasChanged$ = new BehaviorSubject<Step[]>([]);
  public currentStep$ = new BehaviorSubject<any>(null);
  public currentStep: number = 0;
  public currentPath: string = '';
  public steps: Step[] = [
    { label: 'Shopping cart', isActive: true, path: 'cart' },
    { label: 'Delivery info', isActive: false, path: 'delivery' },
    { label: 'Confirmation', isActive: false, path: 'confirmation' }
  ];

  constructor(
    private router: Router
  ) {
  }

  public setCurrentStep(stepPath) {
    this.currentPath = stepPath;
    this.currentStep = this.getStepIndexByPath(stepPath);
    for (let i = 0; i < this.steps.length; i++) {
      this.steps[i].isActive = false;
    }
    this.steps[this.currentStep].isActive = true;
    this.currentStep$.next(stepPath);
    this.stepsWasChanged$.next(this.steps);
  }

  public goToStep(stepPath) {
    this.router.navigate([`checkout/${stepPath}`]);
  }

  public goToNext() {
    const nextStep = this.steps[this.currentStep + 1].path;

    this.router.navigate([`checkout/${nextStep}`]);
  }

  public goToPrevious() {
    if (this.currentStep - 1 < 0) {
      this.router.navigate([``]);
    }

    const previousStep = this.steps[this.currentStep - 1].path;

    this.router.navigate([`checkout/${previousStep}`]);
  }

  private getStepIndexByPath(path): number {
    for (let i = 0; i < this.steps.length; i++) {
      if (this.steps[i].path === path) {
        return i;
      }
    }
  }
}
