import { Injectable } from '@angular/core';
import {Subject} from "rxjs/Subject";
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ImageStatusService {
  _statusWasChanged = new Subject<any>();
  uploadedImages: any[] = [];

  constructor() { }

  statusWasChanged$() {
    return this._statusWasChanged.asObservable();
  }

  public setUploadedImages(uploadedImages) {
    this.uploadedImages = uploadedImages;
  }

  public callStatusWasChangedEvent(id) {
    if (this.checkCurrentStatus(id)) {
      this._statusWasChanged.next(id);
    }
  }

  public checkCurrentStatus(assetId) {
    return _.find(this.uploadedImages, (uploaded) => {
      return uploaded.id === assetId && uploaded.status === 'pending';
    })
  }
}
