import { Injectable } from '@angular/core';
import {EditorCommandsService} from "./editor-commands.service";
import {EditorService} from "./editor.service";
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private editorCommandsService: EditorCommandsService,
    private editorService: EditorService
  ) {}

  saveDocument() {
    this.editorCommandsService.executeFunction('document', 'Save');

    return this.editorService.selectEvent$('DocumentSaved');
  }

  getZoom() {
    const document = this.getDocument();
    return parseInt(document.zoom, 10);
  }

  getDocument() {
    return this.editorCommandsService.getProperty(
      'document'
    );
  }

  getObject($location) {
    return this.editorCommandsService.getProperty(
      $location
    );
  }

  setZoom(value) {
    this.editorCommandsService.setProperty(
      'zoom',
      value,
      'document'
    );
  }

  changeViewMode(mode: string) {
    this.editorCommandsService.setProperty('viewMode', mode, 'document.viewPreferences');
  }

  toggleShowHelpLayers() {
    let mode: boolean = this.editorCommandsService.getProperty('document.viewPreferences.showHelpLayers');
    this.editorCommandsService.setProperty('showHelpLayers', !mode, 'document.viewPreferences');
  }

  fitPage() {
    this.editorCommandsService.executeFunction(
      'document.editor',
      'Fit',
      'page');
  }

  fitHeight() {
    this.editorCommandsService.executeFunction(
      'document.editor',
      'Fit',
      'height');
  }

  fitWidth() {
    this.editorCommandsService.executeFunction(
      'document.editor',
      'Fit',
      'width');
  }

  getVariables() {
    const variablesNumber = this.editorCommandsService.getProperty('document.variables').length;
    let variables = [];

    for (let j = 0; j < variablesNumber; j++) {
      variables[j] = this.getVariable(j);
      variables[j].options = this.getVariableList(j);

      if (variables[j].options.length) {
        variables[j].selectedOption = this.findSelectedOption(variables[j]);
      }
    }

   return this.filterVariables(variables);
  }

  private filterVariables(variables) {
    return _.filter(variables, (variable) => {
      const result = variable.name.search('restricted');

      return result === -1 ? variable : false;
    });
  }

  findSelectedOption(variable) {
    return _.find(variable.options, (option) => {
      return variable.value === option.id;
    });
  }

  getVariable(index: number) {
    return this.editorCommandsService.getProperty(`document.variables[${index}]`);
  }

  getVariableListItem(variableIndex: number, itemIndex: number) {
    return this.editorCommandsService.getProperty(`document.variables[${variableIndex}].listItems[${itemIndex}]`);
  }

  getVariableList(variableNumber: number) {
    const optionsNumber = this.editorCommandsService.getProperty(`document.variables[${variableNumber}].listItems`).length;

    const options = [];

    for (let i = 0; i < optionsNumber; i++) {
      options[i] = this.getVariableListItem(variableNumber, i);
    }

    return options;
  }

  getErrorsList() {
    const optionsNumber = this.editorCommandsService.getProperty(`document.preflightResults`).length;

    const options = [];

    for (let i = 0; i < optionsNumber; i++) {
      options[i] = this.getErrorListItem(i);
    }

    return options;
  }

  setImageFrameDynamicAssetProviderData(frameId, value) {
    return this.editorCommandsService.setProperty('dynamicAssetProviderData', value, `document.pages.first.frames[${frameId}]`);
  }

  getErrorListItem( itemIndex: number) {
    return this.editorCommandsService.getProperty(`document.preflightResults[${itemIndex}]`);
  }

  setVariableValue(variableNumber: number, value) {
    return this.editorCommandsService.setProperty('value', value,`document.variables[${variableNumber}]`);
  }

  setVariableNotes(variableNumber: number, notes) {
    return this.editorCommandsService.setProperty('notes', notes,`document.variables[${variableNumber}]`);
  }

  setVariableLocked(variableNumber: number, value) {
    return this.editorCommandsService.setProperty('allowEditStyle', value,`document.variables[${variableNumber}]`);
  }

  setVariableLabel(variableNumber: number, value) {
    return this.editorCommandsService.setProperty('displayName', value,`document.variables[${variableNumber}]`);
  }

  selectVariableListItem(variableId: number, item: any, value: boolean = true) {
    this.editorCommandsService.setProperty('isSelected', value,`document.variables[${variableId}].listItems[${item.id}]`);
    if (value) {
      this.setVariableValue(variableId, item.name);
    }
  }

  getDocumentErrors() {
    return this.editorCommandsService.getProperty('document.preflightResults');
  }
}
