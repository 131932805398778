<div *ngIf="orderItem"
     class="container"
     [ngStyle]="{'background-color': themeService.settings.backgroundColor, 'color': themeService.settings.btnActiveColor}">
<!--  <app-steps [page]="page"></app-steps>-->
  <div class="editor-container">
    <div class="editor-header-wrapper">
      <h1 *ngIf="page && page.title && page.title.isShown" [innerHTML]="page.title.title | safe: 'html'"></h1>

      <div class="products-wrapper">
        <div class="group-products-title">Group products</div>
        <div
          class="product"
          [ngStyle]="{
        'background-color': (themeService.settings$ | async).getButtonColor(1),
        'color': selectedProduct === 'group' ? '#ffffff' : 'rgba(0, 0, 0, 0.25)'
        }"
          (click)="showGroupView()"
          *ngIf="orderItem.schemaType != 'none'">
          {{orderItem.name}}
        </div>
        <div
          class="product"
          *ngFor="let product of groupProducts"
          [ngStyle]="{
        'background-color': (themeService.settings$ | async).getButtonColor(1),
        'color': selectedProduct.id === product.id ? '#ffffff' : 'rgba(0, 0, 0, 0.25)'
        }"
          (click)="selectProduct(product)">
          {{product.name}}
        </div>
      </div>

      <div class="products-wrapper">
        <div class="group-products-title">Pages</div>
        <div
          class="product"
          *ngFor="let documentPage of documentPages"
          [ngStyle]="{
        'background-color': (themeService.settings$ | async).getButtonColor(1),
        'color': selectedPage.name === documentPage.name ? '#ffffff' : 'rgba(0, 0, 0, 0.25)'
        }"
          (click)="selectPage(documentPage)">
          {{documentPage.name}}
        </div>
      </div>
<!--      <div class="text" *ngIf="page && page.text && page.text.isShown" [innerHTML]="page.text.text | safe: 'html'"></div>-->
<!--      <h4 *ngIf="page && page.sub_title && page.sub_title.isShown" [innerHTML]="page.sub_title.sub_title | safe: 'html'"></h4>-->
    </div>


    <div class="progress" *ngIf="isProcessed">
      <mat-progress-bar mode="query" ></mat-progress-bar>
    </div>

<!--    <app-proof-area *ngIf="threeDIsShown" [orderItem]="orderItem" [selectedProduct]="selectedProduct"></app-proof-area>-->
    <app-preview  #previewComponent *ngIf="previewIsShown" [orderItem]="orderItem" [selectedProduct]="selectedProduct" [documentPage]="selectedPage.name"></app-preview>
<!--    <div class="actions" *ngIf="selectedProduct !== 'group'">-->
<!--      <button (click)="export()" mat-button class="download" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}">Download preview url</button>-->
<!--      <button (click)="downloadJpeg($event)" mat-button class="preview" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1), 'border-color': (themeService.settings$ | async).getButtonColor(0.2)}">Download image</button>-->
<!--      <div class="buttons-wrapper">-->
<!--        <button (click)="showPreview()" mat-button class="preview" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1), 'border-color': (themeService.settings$ | async).getButtonColor(0.2)}">Preview</button>-->
<!--        <button [disabled]="orderItem.schemaType && orderItem.schemaType === 'none'" (click)="showThreeD()" mat-button class="preview" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1), 'border-color': (themeService.settings$ | async).getButtonColor(0.2)}">3D Preview</button>-->
<!--        <button (click)="openSendMailDialog()" mat-button class="mail-link" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1), 'border-color': (themeService.settings$ | async).getButtonColor(0.2)}">Mail preview link</button>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
