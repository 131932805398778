import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ThemeService} from "../../theme.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {MediaLibraryDialogComponent} from "../media-library-dialog/media-library-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ProductsChiliTagsService} from "./products-chili-tags.service";
import {EmbeddedOrderItemsService} from "../../editor/embedded-order-items.service";
import {OrdersService} from "../../order-item.service";
import * as _ from "lodash";
import {ImageStatusService} from "../../editor/image-status.service";
import {AssetsDataService} from "../../assets-data.service";
import {UserService} from "../../user.service";
import {ClientPageService} from "../../client-page.service";
import {ValidationService} from "./validation.service";
import {Paho} from "ng2-mqtt/mqttws31";

@Component({
  selector: 'app-uploader-popup',
  templateUrl: './uploader-popup.component.html',
  styleUrls: ['./uploader-popup.component.sass']
})
export class UploaderPopupComponent implements OnInit {
  isProcessed: boolean = false;
  uploadedImages: any[] = [];
  selectedImage: any;
  chiliTags: any = [];

  dialogSettings: any;

  @ViewChild('image') image: ElementRef;
  private selectedChiliTag: any;

  constructor(
    public themeService: ThemeService,
    public dialogRef: MatDialogRef<UploaderPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private productsChiliTags: ProductsChiliTagsService,
    private embeddedOrderItemsService: EmbeddedOrderItemsService,
    private imageStatusService: ImageStatusService,
    public assetsDataService: AssetsDataService,
    public ordersService: OrdersService,
    public clientPageService: ClientPageService,
    public usersService: UserService,
    public validationService: ValidationService
  ) { }

  ngOnInit() {
    this.themeService.get();
    this.clientPageService.get('uploader-dialog')
      .then((response: any) => {
        this.dialogSettings = response.page;
      });
    this.usersService.get()
      .then((user: any) => {
        this.productsChiliTags.get()
          .then((response: any) => {
            this.chiliTags = response.productsChiliTags;
            this.chiliTags.map((tag) => {
              if (tag.type == 'image') {
                this.getUploaded(tag);
              } else {
                if (tag.value == '' || tag.value == null) {
                  user.variableValues.map((variableValue: any) => {
                    if (tag.name.toLowerCase() == variableValue.name.toLowerCase()) {
                      tag.value = variableValue.value;
                    }
                  });
                }
              }
            })
          })
      })
  }

  public close(): void {
    this.isProcessed = true;
    const productsChiliTagsValues = this.chiliTags.map((item) => {
      return {
        id: item.valueId ? item.valueId : null,
        tag: item.id,
        asset: item.asset ? item.asset : null,
        value: item.value ? item.value : null
      };
    });
    this.productsChiliTags.saveValues(productsChiliTagsValues)
      .then((response) => {
        this.isProcessed = false;
        this.dialogRef.close();
      })
  }

  public selectImage(image, chiliTag){
    chiliTag.selectedImage = image;
    this.selectedChiliTag = chiliTag;
    chiliTag.asset = image.id;
    // this.selectedChiliTag.asset = image;
    // this.embeddedOrderItemsService.addToList(null, image.id, chiliTag.name, image.page, this.selectedImage.status);
  }

  public fileChanged($event, image, chiliTag) {
    this.addAsset(chiliTag, image);
  }

  public addAsset(chiliTag, image) {
    this.isProcessed = true;
    chiliTag.isShowPreloader = true;
    this.ordersService.saveUploadedImageToUserAssets(this.prepareFormData(chiliTag, image))
      .then((response: any) => {
        // console.log('saveUploadedImageToUserAssets', response);
        const connection = this.validationService.createConnection(response.asset.id);
        connection.onMessageArrived = (message: Paho.MQTT.Message) => {
          // console.log('Message arrived.', message.payloadString);
          const body = JSON.parse(message.payloadString);
          response.asset.status = body.status;
        };
        this.image.nativeElement.value = "";
        this.getPreviewUrl(response.asset, chiliTag)
          .then((asset: any) => {
            this.prepareImageForLoading(asset, chiliTag);
            this.imageStatusService.setUploadedImages(chiliTag.uploadedImages);
            this.isProcessed = false;
            chiliTag.isShowPreloader = false;
          });
      })
  }

  public getUploaded(chiliTag, reason = null) {

    if (chiliTag.type != 'image') {
      return;
    }

    this.isProcessed = true;
    chiliTag.uploadedImages = [];
    chiliTag.selectedImage = null;

    this.ordersService.getUserUploaded(chiliTag.name, chiliTag.id)
      .then((result) => {
        if (result && result.assets && result.assets.length) {
          // this.uploadedImages = result.assets;
          this.imageStatusService.setUploadedImages(chiliTag.uploadedImages);

          let promises = _.map(result.assets, (asset) => {
            return this.getPreviewUrl(asset, chiliTag);
          });

          Promise.all(promises)
            .then((assets) => {
              this.prepareImagesForLoading(chiliTag);
              this.imageStatusService.setUploadedImages(chiliTag.uploadedImages);
              this.isProcessed = false;
            });

        } else {
          this.isProcessed = false;
          chiliTag.uploadedImages = [];
        }
      })
  }

  private findLastCreated(uploaded) {
    return _.maxBy(uploaded, 'createdAt');
  }

  public getPreviewUrl(asset, chiliTag) {
    return this.assetsDataService.get(asset.id)
      .then((result) => {
        if (result && result.previewUrls && result.previewUrls.length) {
          _.forEach(result.previewUrls, (data) => {
            let image = _.cloneDeep(asset);

            image.previewLink = data.url;
            image.page = data.page;
            chiliTag.uploadedImages.push(image);
          })
        }

        return asset;
      })
  }

  public getCurrentEmbedded(chiliTag){
    return this.ordersService.getEmbedded(null, null)
      .then((result) => {
        if (result && result.assets && result.assets.length) {
          const image = _.find(chiliTag.uploadedImages, (asset) => {
            return asset.id === result.assets[0].assetId;
          });

          if (image) {
            this.selectImage(image, chiliTag);
          } else {
            this.findSelected(chiliTag);
          }

        } else {
          this.findSelected(chiliTag);
        }
        return;
      })
  }

  private findSelected(chiliTag, page = 1) {
    if (chiliTag.uploadedImages && chiliTag.uploadedImages.length) {
      this.selectImage(chiliTag.uploadedImages[chiliTag.uploadedImages.length - 1], chiliTag);
    } else {
      chiliTag.selectedImage = null;
    }
  }

  private prepareImagesForLoading(chiliTag) {
    chiliTag.uploadedImages = _.map(chiliTag.uploadedImages, (image) => {
      image['imageIsLoaded'] = false;
      this.chiliTags.map((chiliTag: any) => {
        if(image.id == chiliTag.asset) {
          chiliTag.selectedImage = image;
          chiliTag.asset = image.id;
        }
      });
      return image;
    })
  }

  private prepareImageForLoading(image, currentChiliTag) {
    image['imageIsLoaded'] = false;
    currentChiliTag.selectedImage = image;
    currentChiliTag.asset = image.id;
    return image;
  }

  private prepareFormData(chiliTag, image) {
    const formData = new FormData();

    const file = image.files[0];

    formData.append('file', file);
    formData.append('tag', chiliTag.name);
    formData.append('tag_id', chiliTag.id);
    return formData;
  }

  loadHandler($event, image) {
    image['imageIsLoaded'] = true;
  }

  public removeAssets(chiliTag) {
    this.assetsDataService.remove(chiliTag.selectedImage.id)
      .then(() => {
        if(!chiliTag.valueId) {
          this.handleAfterRemoveImage(chiliTag)
          return;
        }
        this.productsChiliTags.deleteValue(chiliTag.valueId)
          .then(() => {
            this.handleAfterRemoveImage(chiliTag)
          })
      })
  }

  handleAfterRemoveImage(chiliTag) {
    chiliTag.uploadedImages = chiliTag.uploadedImages.filter((image: any) => {
      return chiliTag.selectedImage.id != image.id;
    });
    chiliTag.valueId = null;
    chiliTag.value = null;
    chiliTag.asset = null;
    if(chiliTag.uploadedImages.length > 0) {
      this.selectImage(chiliTag.uploadedImages[chiliTag.uploadedImages.length - 1], chiliTag);
    } else {
      chiliTag.selectedImage = null;
    }
  }

  openMediaLibraryDialog($event, chiliTag) {
    let dialogRef = this.dialog.open(MediaLibraryDialogComponent, {
      data: { name: 'austin' },
      width: '800px',
      height: '500px',
      panelClass: 'media-library-panel'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.length === 0) return;
      this.isProcessed = true;
      chiliTag.isShowPreloader = true;
      result.forEach((url) => {
        const that = this;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.onload = function() {
          const blob: Blob = this.response;
          const formData = new FormData();

          formData.append('file', blob);
          formData.append('tag', chiliTag.name);
          formData.append('tag_id', chiliTag.id);
          that.ordersService.saveUploadedImageToUserAssets(formData)
            .then((response: any) => {
              that.image.nativeElement.value = "";
              that.getPreviewUrl(response.asset, chiliTag)
                .then((asset: any) => {
                  that.prepareImageForLoading(asset, chiliTag);
                  that.imageStatusService.setUploadedImages(chiliTag.uploadedImages);
                  that.isProcessed = false;
                  chiliTag.isShowPreloader = false;
                });
            });
        };
        xhr.send();
      });
    });
  }

}


