import { Injectable }    from '@angular/core';
import {Observable}      from "rxjs/Observable";
import {catchError, map} from "rxjs/operators";
import {of}              from "rxjs/internal/observable/of";
import {ConfigService}   from "./config.service";
import {HttpClient}      from "@angular/common/http";
import {TitleCasePipe}   from "@angular/common";
import { Subject }       from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: any;

  user$: Subject<any> = new Subject<any>();
  updateCreditBalance$: Subject<any> = new Subject<any>();
  isLocked = false;
  currentRequest: any;

  constructor(private http: HttpClient, private configService: ConfigService) { }

  setUser(data) {
    this.user = data;
  }

  getUser(): Promise<any> {
    return this.get();
  }

  public get(): Promise<any> {
    if (!this.isLocked) {
      this.isLocked = true;
      this.currentRequest = this.http.get(this.configService.getLanguagesUrl('me'))
        .pipe(
          map((data) => {
            this.setUser(data['user']);
            this.user$.next(this.user);
            this.isLocked = false;
            return this.user;
          }),
          catchError(this.handleError('get user', null))
        )
        .toPromise();
    }

    return this.currentRequest;

  }

  public save(profileData) {
    return this.http.post(this.configService.getLanguagesUrl('user'), profileData).toPromise();
  }

  public updateDeliveryInformation(deliveryData) {
    let variablesData = [];
    for(let field in deliveryData) {
      variablesData.push({
        name: field,
        value: deliveryData[field]
      });
    }
    return this.http.post(this.configService.getLanguagesUrl('user/delivery'), variablesData).toPromise();
  }

  setShowCreditBalance(creditBalance) {
    this.user.showCreditBalance = creditBalance;
    this.updateCreditBalance$.next(true);
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
