import { Injectable }    from '@angular/core';
import { HttpClient }    from '@angular/common/http';
import { ConfigService } from './config.service';
import { Locale }        from './locale';
import { Subject }       from 'rxjs';
import { OrdersService } from './order-item.service';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  private availableLocales: Locale[] = [];
  private currentLocale: Locale;

  public currentLocaleSet$: Subject<Locale> = new Subject<Locale>();

  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) {

  }

  list(): Promise<any> {
    return this.http.get(this.config.getLanguagesUrl(`languages`)).toPromise();
  }

  getAvailableLocales() {
    return this.availableLocales;
  }

  setAvailableLocales(locales) {
    this.availableLocales = locales;
  }

  setCurrentLocale(currentLocale) {
    this.currentLocale = currentLocale;
    this.currentLocaleSet$.next(currentLocale);
  }

  getCurrentLocale() {
    return this.currentLocale;
  }
}
