<div mat-dialog-content>
  <div class="container" *ngIf="order && order.orderItems && order.orderItems.length">
    <mat-list>
      <mat-list-item *ngFor="let item of order.orderItems">
        <div class="item-image">
          <img [src]="item.documents[0].files.images[0].file.url" [alt]="item.name" *ngIf="item.documents.length != 0 && item.documents[0].files.images.length > 0">
          <img [src]="item.previewUrl" [alt]="item.name" *ngIf="item.documents.length != 0 && item.documents[0].files.images.length == 0">
        </div>
        <div class="item-information">
          <div class="item-title">
            <h1 *ngIf="!item.productgroupId">{{item.name}}</h1>
            <h1 *ngIf="item.productgroupId">{{getProductgroup(item.productgroupId).name}}</h1>
          </div>
          <div class="item-price" [ngStyle]="{'color': themeService.getButtonColor(1)}" *ngIf="isShowCurrencyPrice(item)">{{item.price | currency: 'EUR':'symbol':'1.2-3':'nl-BE'}}</div>
          <div class="item-price" [ngStyle]="{'color': themeService.getButtonColor(1)}" *ngIf="isShowCreditsPrice(item)">{{(item.price / creditSettings.creditValue) | number:'1.1-3'  }} <span appI18nTranslation>Credits</span></div>
        </div>
        <div class="item-status-and-quantity">
          <div class="item-status">
            <span class="editing" *ngIf="item.status == 'editing'" appI18nTranslation>Editing</span>
            <span class="confirmed" *ngIf="item.status == 'confirmed'" appI18nTranslation>Confirmed</span>
            <span class="pending" *ngIf="item.status == 'pending'" appI18nTranslation>Pending</span>
          </div>
          <div class="item-quantity">
            <mat-form-field>
              <button mat-icon-button class="reduce" aria-label="Reduce the amount of this product" (click)="reduce(item)" [disabled]="isDisabledReduce(item)">
                <mat-icon [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">remove</mat-icon>
              </button>
              <input matInput type="text" [(ngModel)]="item.quantity" [disabled]="!item.settings.isManualInput" (change)="manualInput(item)">
              <button mat-icon-button class="increase" aria-label="Increase the amount of this product" (click)="increase(item)" [disabled]="isDisabledIncrease(item)">
                <mat-icon [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">add</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div class="item-remove">
          <button mat-icon-button aria-label="Remove product" (click)="remove(item)" *ngIf="isShowRemoveButton(item)">
            <mat-icon>close</mat-icon>
          </button>
          <button mat-icon-button [ngStyle]="{'opacity': 0}" *ngIf="!isShowRemoveButton(item)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="container empty" *ngIf="!order || !order.orderItems || order.orderItems.length == 0"  [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">
    <h3 appI18nTranslation>You cart is empty!</h3>&nbsp;<mat-icon>remove_shopping_cart</mat-icon>
  </div>
</div>
<div mat-dialog-actions *ngIf="order && order.orderItems && order.orderItems.length">
  <div class="total">
    <div>
      <span appI18nTranslation>Subtotal:</span> <span class="price" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">{{totalPrice | currency: 'EUR':'symbol':'1.2-3':'nl-BE'}}</span>
    </div>
    <div *ngIf="creditSettings.enable">
      <span appI18nTranslation>Credits used:</span> <span class="price" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">{{creditUsed | number:'1.2-3' }} <span appI18nTranslation>Credits</span></span>
    </div>
  </div>
  <button mat-button class="checkout" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}" (click)="checkout()" appI18nTranslation>Checkout</button>
</div>
