import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-error-tooltip',
  templateUrl: './error-tooltip.component.html',
  styleUrls: ['./error-tooltip.component.sass']
})
export class ErrorTooltipComponent implements OnInit {
  @Input() messages: string[] = [];

  constructor() { }

  ngOnInit() {
  }

}
