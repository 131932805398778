import { Component, Input, OnInit } from '@angular/core';
import { ThemeService }             from '../../theme.service';
import { ClientPage }               from '../../client-page';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.sass']
})
export class TextComponent implements OnInit {
  
  @Input() page: ClientPage;
  
  constructor(public themeService: ThemeService) { }

  ngOnInit() {
  }

}
