import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../../config.service";

@Injectable({
  providedIn: 'root'
})
export class ProductsChiliTagsService {

  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) { }

  get() {
    return this.http.get(this.config.getActionUrl(`chili-tags`)).toPromise();
  }

  deleteValue(chiliTagValueId) {
    return this.http.delete(this.config.getActionUrl(`chili-tags/value/${chiliTagValueId}`)).toPromise();
  }

  saveValues(productsChiliTags: any) {
    return this.http.post(this.config.getActionUrl(`chili-tags`), productsChiliTags).toPromise();
  }
}
