import { Component, OnInit }   from '@angular/core';
import { Step }                from './step.class';
import { ThemeService }        from '../../theme.service';
import { StepsService }        from './steps.service';
import { TranslationsService } from '../../translations.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.sass']
})
export class StepsComponent implements OnInit {

  steps: Step[];

  constructor(
    private themeService: ThemeService,
    private stepService: StepsService,
    private translationService: TranslationsService
  ) { }

  ngOnInit() {
    this.themeService.get();
    this.steps = this.stepService.steps;
    this.stepService.stepsWasChanged$.subscribe((steps: Step[]) => {
      this.steps = steps;
    });

    this.translationService.translationsLoaded$.subscribe(() => {
      this.steps.map((item, index) => {
        item.label = this.translationService.translate(item.label);
      });
    });
  }

  next() {
    this.stepService.goToNext();
  }

  previous() {
    this.stepService.goToPrevious();
  }

}
