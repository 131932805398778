import {AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ClientPage} from "../../client-page";
import {Subscription} from "rxjs/Subscription";
import {OrderItemDocumentsService} from "../../order-item-documents.service";
import {DocumentService} from "../../editor/chili-editor/document.service";
import {EditorService} from "../../editor/chili-editor/editor.service";
import {OrdersService} from "../../order-item.service";
import {LanguagesService} from "../../languages.service";
import * as _ from "lodash";
import {MatDialog} from "@angular/material/dialog";
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {ClientPageService} from "../../client-page.service";
import {ThemeService} from "../../theme.service";
import {ErrorsDialogComponent} from "./errors-dialog/errors-dialog.component";
import {AdjustStepConditionService} from "../../editor/adjust-step-condition.service";
import {EmbeddedOrderItemsService} from "../../editor/embedded-order-items.service";
import {ProductService} from "../../editor/product.service";
import {ProductsChiliTagsService} from "../uploader-popup/products-chili-tags.service";
import {NotificationService} from "../notification.service";
import {ImageStatusService} from "../../editor/image-status.service";
import {AssetsDataService} from "../../assets-data.service";

const IMAGE_WIDTH = 330;
const IMAGE_HEIGHT = 250;

@Component({
  selector: 'app-editor-popup',
  templateUrl: './editor-popup.component.html',
  styleUrls: ['./editor-popup.component.sass']
})
export class EditorPopupComponent implements OnInit, AfterViewInit {

  url: string;
  page: ClientPage;
  document: any;
  zoomVal: any = 75;
  currentImage: any;
  errors: any = {
    quantity: '0',
    messages: []
  };
  warnings: any = {
    quantity: '0',
    messages: []
  };
  currentProduct: any;
  isProcessed: boolean = false;
  isShowSpinner: boolean = true;
  editorWasLoaded: boolean = false;
  variablesSubmenuOpened: boolean = true;

  errorsAddedSubscription: Subscription;
  errorsRemovedSubscription: Subscription;
  languageSubscription: Subscription;
  groupProducts: any[] = [];
  points: any[] = [];
  imageRect: any;
  variables: any[] = [];

  @ViewChild('canvasElement') canvasRef;

  @Input() orderItem: any;
  public canvasCursor: string;
  private moveStarted: boolean = false;
  private frameRect: any;
  private lastMousePosition: any;
  private resizeStarted: boolean = false;
  private currentPoint: any;
  private pages: any[];
  private currentPage: any;
  private tags: any[];
  private selectedTag: any;
  private chiliTags: any[];
  public selectedFrame: any;
  public ctx: CanvasRenderingContext2D;

  @Input() set selectedProduct(value) {
    this.resetErrors();
    this.zoomVal = null;
    if (value) {
      this.initProductData(value);
    }
  }

  @Output() editorReady = new EventEmitter<any>();
  @Output() errorsReady = new EventEmitter<any>();

  previewImage: HTMLImageElement = new Image();

  previews: any[] = [];

  fitMode: string;
  fitModeAnchor: string;
  transformation: string;

  constructor(
    private orderItemDocumentsService: OrderItemDocumentsService,
    private documentService: DocumentService,
    private editorService: EditorService,
    public ordersService: OrdersService,
    public languageService: LanguagesService,
    public dialogRef: MatDialogRef<EditorPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public themeService: ThemeService,
    private snackBar: MatSnackBar,
    public clientPageService: ClientPageService,
    public embeddedOrderItemsService: EmbeddedOrderItemsService,
    private adjustStepConditionService: AdjustStepConditionService,
    private productsChiliTags: ProductsChiliTagsService,
    private productService: ProductService,
    public assetsDataService: AssetsDataService,
    private imageStatusService: ImageStatusService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this.page = new ClientPage();
    this.clientPageService.get('editor').then((response: any) => {
      this.page = response.page;
    });
    this.ordersService.getOrderItem(this.data.orderItemId)
      .then((response: any) => {
        this.orderItem = response.orderItem;
        this.groupProducts = this.orderItem.documents;

        // this.findDocumentsForAdjust();
        this.adjustStepConditionService.setDocuments(this.groupProducts, this.orderItem.id);

        const cashedDocuments = this.adjustStepConditionService.getDocuments(this.orderItem.id);

        if (cashedDocuments && cashedDocuments.length) {
          this.selectProduct(this.findUnsavedDocument());
        } else {
          this.selectProduct(this.groupProducts[0]);
        }
        // console.log(response);
      });
  }

  public getPreviewUrl(asset: any) {
    return this.assetsDataService.get(asset.assetId)
      .then((result) => {
        if (result && result.previewUrls && result.previewUrls.length) {
          _.forEach(result.previewUrls, (data) => {
            this.previews.push(data);
          })
        }
      });
  }

  ngAfterViewInit() {
    let shellSubscription = this.editorService.shellReady.subscribe(ready => {
      this.editorReady.next(ready);
      this.editorWasLoaded = ready;
      if (ready) {
        this.languageSubscription = this.languageService.currentLocaleSet$.subscribe((language) => {
          this.reload();
        });

        this.productsChiliTags.get()
          .then((response: any) => {
            this.chiliTags = response.productsChiliTags;
            this.variables = this.documentService.getVariables();
            // console.log(this.variables)
            if(this.chiliTags) {
              this.chiliTags.map((tag: any) => {
                this.getAssets(tag.name)
                if(tag.type == 'textarea' || tag.type == 'text') {
                  this.variables.map((_variable: any) => {
                    this.documentService.setVariableLocked(_variable.id, false); // && _variable.value == ''
                    let additionalInformation = { isFirstValueSetting: false };
                    if( _variable.notes != '') {
                      additionalInformation = JSON.parse(_variable.notes);
                    }
                    if(
                      _variable.displayName.trim().toLowerCase() == tag.name.trim().toLowerCase() &&
                      !additionalInformation.isFirstValueSetting
                    ) {
                      if(tag.value) {
                        this.documentService.setVariableValue(_variable.id, tag.value.trim());
                        this.documentService.setVariableNotes(_variable.id, JSON.stringify({ isFirstValueSetting: true }));
                      }
                      // _variable = this.documentService.getVariable(_variable.id);
                      //
                      // console.log(_variable);
                    }
                  });
                }
              })
            }
            this.isShowSpinner = false;
          })



        // this.ctx = this.canvasRef.nativeElement.getContext('2d');
        let parseRectString = (rectString: string) => {
          return rectString
            .replace('X: ', '')
            .replace('Y: ', '')
            .replace('W: ', '')
            .replace('H: ', '')
            .split(' , ')
            .reduce((results, item, i) => {
              switch (i) {
                case 0:
                  results.x = (item.trim()) as unknown as number * 1;
                  break;
                case 1:
                  results.y = (item.trim()) as unknown as number * 1;
                  break;
                case 2:
                  results.width = (item.trim()) as unknown as number * 1;
                  break;
                case 3:
                  results.height = (item.trim()) as unknown as number * 1;
                  break;
              }
              return results;
            }, { "x": 0, "y": 0, "width": 0, "height": 0 });
        }

        let drawScaleBorder = () => {
          this.ctx = this.canvasRef.nativeElement.getContext('2d');
          const x = ((this.canvasRef.nativeElement.width / 2) - (this.frameRect.width / 2));
          const y = ((this.canvasRef.nativeElement.height / 2) - (this.frameRect.height / 2));
          this.ctx.clearRect(0, 0, this.canvasRef.nativeElement.width, this.canvasRef.nativeElement.height);
          this.ctx.beginPath();
          this.ctx.fillStyle = "#fafafa";
          // this.ctx.fillRect((this.canvasRef.nativeElement.width / 2) - (this.frameRect.width / 2), (this.canvasRef.nativeElement.height / 2) - (this.frameRect.height / 2), this.frameRect.width, this.frameRect.height);
          this.ctx.fillRect(
            x,
            y,
            this.frameRect.width,
            this.frameRect.height
          );
          this.ctx.closePath();

          this.ctx.beginPath();
          this.ctx.lineWidth = 2;
          this.ctx.rect(
            x+5,
            y,
            this.frameRect.width - 10,
            this.frameRect.height
          );
          this.ctx.strokeStyle = "#636363";
          this.ctx.stroke();
          this.ctx.closePath();
        }

        let drawResizePoints = () => {

          const radius = this.frameRect.height * 0.04;

          let drawPoint = (x, y, position, cursor) => {
            this.ctx.beginPath();
            this.ctx.arc(x, y, radius, 0, Math.PI * 2, true); // Outer circle
            this.ctx.fillStyle = "rgba(137,200,252,0.73)";
            this.ctx.fill();
            this.ctx.closePath();

            this.ctx.beginPath();
            this.ctx.arc(x, y, radius+1, 0, Math.PI * 2, true); // Outer circle
            this.ctx.strokeStyle = "#6f7a83";
            this.ctx.lineWidth = 2;
            this.ctx.stroke();

            this.points.push({ 'x': x, 'y': y, 'radius': radius, 'position': position, 'cursor': cursor });
          }
          this.points = [];
          drawPoint(this.imageRect.x, this.imageRect.y, 'topleft', 'se-resize');
          drawPoint(this.imageRect.x, this.imageRect.y + this.imageRect.height, 'bottomleft', 'sw-resize');
          drawPoint(this.imageRect.x + this.imageRect.width, this.imageRect.y, 'topright', 'sw-resize');
          drawPoint(this.imageRect.x + this.imageRect.width, this.imageRect.y + this.imageRect.height, 'bottomright', 'se-resize');
          // console.log(this.points);
        }

        this.previewImage.onload = ($event) => {
          renderScalePanel($event);
        }

        let renderScalePanel = (event) => {
          // console.log(event);
          // console.log(this.editorService.editor.exec(`document.fullWorkSpace.rightPanels[0]`));
          const frame = this.editorService.editor.GetObject(`document.selectedFrame`);

          if(!frame || frame.type !== 'image') {
            return;
          }

          this.frameRect = parseRectString(frame.contentRectPixels);

          this.canvasRef.nativeElement.height = this.frameRect.height + (this.frameRect.height * 0.8);
          this.canvasRef.nativeElement.width = (this.frameRect.height + (this.frameRect.height * 0.8)) * 1.6 ;

          const scaleRectTo = (rect, scaleTo) => {
            if (rect.width > rect.height) {
              if (rect.width > scaleTo.width) {
                rect.height *= scaleTo.width / rect.width;
                rect.width = scaleTo.width;
              } else {
                rect.height *= rect.width / scaleTo.width ;
                rect.width = scaleTo.width;
              }
            } else {
              if (rect.height > scaleTo.height) {
                rect.width *= scaleTo.height / rect.height;
                rect.height = scaleTo.height;
              } else {
                rect.width *= rect.height / scaleTo.height;
                rect.height = scaleTo.height;
              }
            }
            return rect;
          }

          drawScaleBorder();

          this.imageRect = {
            x: ((this.canvasRef.nativeElement.width / 2) - (this.frameRect.width / 2)) + frame.ImgXPixel * 1,
            y: ((this.canvasRef.nativeElement.height / 2) - (this.frameRect.height / 2)) + frame.ImgYPixel * 1,
            width: frame.ImgWidthPixel * 1,
            height: frame.ImgHeightPixel * 1
          };

          if(this.previewImage.complete) {
            this.ctx.drawImage(this.previewImage,
              this.imageRect.x,
              this.imageRect.y,
              this.imageRect.width,
              this.imageRect.height
            );
          }
          drawResizePoints();

        };

        let prepareCanvas = (event) => {
          const frame = this.editorService.editor.GetObject(`document.selectedFrame`);

          // console.log('prepareCanvas: ', frame);

          if(!frame || frame.type !== 'image') {
            return;
          }

          // externalID
          let previewURL = this.previews.filter((preview: any) => {
            return preview.chiliId.toLowerCase() == frame.externalID.toLowerCase();
          });

          console.log('previewURL: ', previewURL);
          if(previewURL.length >= 1) {
            this.previewImage.src = previewURL[0].url;
          }
          // this.previewImage.src = "https://cp-tqo-673.chili-publish-sandbox.online/cp-tqo-673/download.aspx?type=8b717864-6cfd-49e7-be24-d8a8fbb916b0&resourceName=Assets&id=1be006d5-8a2c-4e5e-99e9-73a4a9895e3f&apiKey=_S59L+brZIljd9NnHV2SdHi0IRH_rNNAWS_evFF73cKLLOEQ0s1PHPovMOSzfiBJy5eAvocFC02gYTKySNiGx86Ne+86HqQs&pageNum=1";
          this.frameRect = parseRectString(frame.contentRectPixels);
          this.canvasRef.nativeElement.height = (this.frameRect.height + (frame.ImgHeightPixel * 1)) * 0.8;
          this.canvasRef.nativeElement.width = ((this.frameRect.height + (frame.ImgHeightPixel * 1)) * 1.6) * 0.8;
        }

        let prepareImageSettings = ($event) => {
          setTimeout(() => {
            const frame = this.editorService.editor.GetObject(`document.selectedFrame`);
            this.selectedFrame = frame;
            console.log(this.selectedFrame)
            if(!frame || frame.type !== 'image') {
              return;
            }

            this.fitMode = frame.fitMode;
            this.fitModeAnchor = frame.fitPoint;
            this.transformation = frame.transformationID;

            prepareCanvas($event);
            renderScalePanel($event);

            this.canvasRef.nativeElement.addEventListener('mousemove', ($event) => {
              let canvasRect = this.canvasRef.nativeElement.getBoundingClientRect();

              let mousePosition = {
                x: ($event.clientX - canvasRect.left) * (this.canvasRef.nativeElement.width / IMAGE_WIDTH),
                y: ($event.clientY - canvasRect.top) * (this.canvasRef.nativeElement.height / IMAGE_HEIGHT)
              };

              if(this.moveStarted) {
                let tick = (T) => {
                  this.ctx.clearRect(0, 0, this.canvasRef.nativeElement.width, this.canvasRef.nativeElement.height);
                  // console.log(mousePosition, this.imageRect, mousePosition.x - this.imageRect.x, mousePosition.y - this.imageRect.y)
                  let deltaX = (this.lastMousePosition.x - mousePosition.x);
                  let deltaY = (this.lastMousePosition.y - mousePosition.y);
                  // console.log(deltaX, deltaY)
                  this.imageRect.x = this.imageRect.x - deltaX;
                  this.imageRect.y = this.imageRect.y - deltaY;
                  drawScaleBorder();
                  drawResizePoints();
                  if(this.previewImage.complete) {
                    this.ctx.drawImage(this.previewImage,
                      this.imageRect.x,
                      this.imageRect.y,
                      this.imageRect.width,
                      this.imageRect.height
                    );
                  }
                  // this.ctx.drawImage(this.previewImage,
                  //   this.imageRect.x,
                  //   this.imageRect.y,
                  //   this.imageRect.width,
                  //   this.imageRect.height
                  // );
                  this.lastMousePosition = mousePosition
                }
                //
                window.setTimeout( tick, 1000 / 45 )
              }


              if(this.resizeStarted) {
                let tick = (T) => {
                  this.ctx.clearRect(0, 0, this.canvasRef.nativeElement.width, this.canvasRef.nativeElement.height);
                  let deltaX = (this.lastMousePosition.x - mousePosition.x);
                  let deltaY = (this.lastMousePosition.y - mousePosition.y)
                  switch(this.currentPoint.position) {
                    case 'topleft': {
                      this.imageRect.x = this.imageRect.x - deltaX;
                      this.imageRect.y = this.imageRect.y - deltaY;
                      this.imageRect.width = this.imageRect.width + deltaX;
                      this.imageRect.height = this.imageRect.height + deltaY;
                      break;
                    }
                    case 'topright': {
                      this.imageRect.y = this.imageRect.y - deltaY;
                      this.imageRect.width = this.imageRect.width - deltaX;
                      this.imageRect.height = this.imageRect.height + deltaY;
                      break;
                    }
                    case 'bottomleft': {
                      this.imageRect.width = this.imageRect.width + deltaX;
                      this.imageRect.height = this.imageRect.height - deltaY;
                      this.imageRect.x = this.imageRect.x - deltaX;
                      break;
                    }
                    case 'bottomright': {
                      this.imageRect.width = this.imageRect.width - deltaX;
                      this.imageRect.height = this.imageRect.height - deltaY;
                      break;
                    }
                  }
                  drawScaleBorder();
                  drawResizePoints();
                  this.ctx.drawImage(this.previewImage,
                    this.imageRect.x,
                    this.imageRect.y,
                    this.imageRect.width,
                    this.imageRect.height
                  );
                  this.lastMousePosition = mousePosition
                }
                //
                window.setTimeout( tick, 1000 / 45 )
              }

              if(isInsideCircle(mousePosition) && !this.resizeStarted && !this.moveStarted) {
                this.canvasRef.nativeElement.removeEventListener('mousedown', startImageMove);
                this.canvasRef.nativeElement.removeEventListener('mouseup', stopImageMove);
                this.canvasRef.nativeElement.addEventListener('mousedown', startImageResize);
                this.canvasRef.nativeElement.addEventListener('mouseup', stopImageResize);
                imageMouseResizeHandler($event, mousePosition);
              } else if(isInsideRect(mousePosition, this.imageRect) && !this.moveStarted && !this.resizeStarted) {
                this.canvasRef.nativeElement.removeEventListener('mousedown', startImageResize);
                this.canvasRef.nativeElement.removeEventListener('mouseup', stopImageResize);
                this.canvasRef.nativeElement.addEventListener('mousedown', startImageMove);
                this.canvasRef.nativeElement.addEventListener('mouseup', stopImageMove);
                imageMouseMoveHandler($event);
              } else {
                if(this.canvasCursor != 'move') {
                  this.canvasCursor = 'default';
                }
              }

            });
          }, 500);
          // this.editorService.editor.SetProperty("document.selectedFrame","fitMode", 'manual');
        }

        this.editorService.selectEvent$('SelectedFrameChanged').subscribe(prepareImageSettings);
        this.editorService.selectEvent$('FrameResized').subscribe(renderScalePanel);
        this.editorService.selectEvent$('FrameRotated').subscribe(renderScalePanel);
        this.editorService.selectEvent$('FrameMoved').subscribe(renderScalePanel);

        let startImageMove = ($event) => {
          this.canvasCursor = 'move';
          this.moveStarted = true;
          let canvasRect = this.canvasRef.nativeElement.getBoundingClientRect();
          this.lastMousePosition = {
            x: ($event.clientX - canvasRect.left) * (this.canvasRef.nativeElement.width / IMAGE_WIDTH),
            y: ($event.clientY - canvasRect.top) * (this.canvasRef.nativeElement.height / IMAGE_HEIGHT)
          };
        }

        let stopImageMove = ($event) => {
          this.canvasCursor = 'pointer';
          this.moveStarted = false;
          this.canvasRef.nativeElement.removeEventListener('mousedown', startImageMove);
          this.canvasRef.nativeElement.removeEventListener('mouseup', stopImageMove);
          this.editorService.editor.SetProperty("document.selectedFrame","imgX", (this.imageRect.x - ((this.canvasRef.nativeElement.width / 2) - (this.frameRect.width / 2))) * 0.352783462);
          this.editorService.editor.SetProperty("document.selectedFrame","imgY", (this.imageRect.y - ((this.canvasRef.nativeElement.height / 2) - (this.frameRect.height / 2))) * 0.352783462);
        }

        let startImageResize = ($event) => {
          this.resizeStarted = true;
          let canvasRect = this.canvasRef.nativeElement.getBoundingClientRect();
          this.lastMousePosition = {
            x: ($event.clientX - canvasRect.left) * (this.canvasRef.nativeElement.width / IMAGE_WIDTH),
            y: ($event.clientY - canvasRect.top) * (this.canvasRef.nativeElement.height / IMAGE_HEIGHT)
          };
          this.currentPoint = this.points.filter((rect) => {
            return Math.pow((this.lastMousePosition.x - rect.x), 2) + Math.pow((this.lastMousePosition.y - rect.y), 2) <= Math.pow(rect.radius, 2);
          })[0];

        };
        let stopImageResize = ($event) => {
          this.resizeStarted = false;
          this.canvasRef.nativeElement.removeEventListener('mousedown', startImageResize);
          this.canvasRef.nativeElement.removeEventListener('mouseup', stopImageResize);
          this.editorService.editor.SetProperty("document.selectedFrame","imgX", (this.imageRect.x - ((this.canvasRef.nativeElement.width / 2) - (this.frameRect.width / 2))) * 0.352783462);
          this.editorService.editor.SetProperty("document.selectedFrame","imgY", (this.imageRect.y - ((this.canvasRef.nativeElement.height / 2) - (this.frameRect.height / 2))) * 0.352783462);
          this.editorService.editor.SetProperty("document.selectedFrame","imgWidth", this.imageRect.width * 0.352783462);
          this.editorService.editor.SetProperty("document.selectedFrame","imgHeight", this.imageRect.height * 0.352783462);
        };

        let imageMouseMoveHandler = ($event) => {
          if(this.canvasCursor != 'move' && !this.moveStarted && !this.resizeStarted) {
            this.canvasCursor = 'pointer';
          }
        }

        let isInsideRect = (position, rect) => {
          if(!position || !rect) {
            return false;
          }
          return (position.x > rect.x && position.x < rect.x + rect.width) && (position.y > rect.y && position.y < rect.y + rect.height)
        }

        let isInsideCircle = (position) => {
          if(!position) {
            return false;
          }

          return this.points.filter((rect) => {
            return Math.pow((position.x - rect.x), 2) + Math.pow((position.y - rect.y), 2) <= Math.pow(rect.radius, 2);
          }).length > 0;
        }

        let imageMouseResizeHandler = ($event, position) => {
          const point = this.points.filter((rect) => {
            return Math.pow((position.x - rect.x), 2) + Math.pow((position.y - rect.y), 2) <= Math.pow(rect.radius, 2);
          })[0];
          this.canvasCursor = point.cursor;
        }

        this.isProcessed = false;
        this.getZoom();
        this.getErrors();

        this.errorsAddedSubscription && this.errorsAddedSubscription.unsubscribe();
        this.errorsRemovedSubscription && this.errorsRemovedSubscription.unsubscribe();

        this.errorsAddedSubscription = this.editorService
          .selectEvent$('PreflightResultAdded').subscribe((event) => {
            if (event) {
              this.getErrors();
            }
          });

        this.errorsRemovedSubscription = this.editorService
          .selectEvent$('PreflightResultDeleted').subscribe((event) => {
            if (event) {
              this.getErrors();
            }
          });
      }
    });
  }

  findTag(tag) {
    return this.chiliTags.find((item) => {
      return item.name == tag;
    })
  }

  getAssets(tag) {
    let chiliTag = this.findTag(tag);
    chiliTag.uploadedImages = [];
    this.ordersService.getUserUploaded(chiliTag.name, chiliTag.id)
      .then((result) => {
        if (result && result.assets && result.assets.length) {
          // this.uploadedImages = result.assets;
          this.imageStatusService.setUploadedImages(chiliTag.uploadedImages);

          let promises = _.map(result.assets, (asset) => {
            return this.getPreviewUrlForTag(asset, chiliTag);
          });

          Promise.all(promises)
            .then((assets) => {
              this.prepareImagesForLoading(chiliTag);
              this.imageStatusService.setUploadedImages(chiliTag.uploadedImages);
              this.isProcessed = false;
            });

        } else {
          this.isProcessed = false;
          chiliTag.uploadedImages = [];
        }
      })
  }

  public getPreviewUrlForTag(asset, chiliTag) {
    return this.assetsDataService.get(asset.id)
      .then((result) => {
        if (result && result.previewUrls && result.previewUrls.length) {
          _.forEach(result.previewUrls, (data) => {
            let image = _.cloneDeep(asset);

            image.previewLink = data.url;
            image.page = data.page;
            chiliTag.uploadedImages.push(image);
          })
        }

        return asset;
      })
  }


  private prepareImagesForLoading(chiliTag) {
    chiliTag.uploadedImages = _.map(chiliTag.uploadedImages, (image) => {
      image['imageIsLoaded'] = false;
      this.chiliTags.map((chiliTag: any) => {
        if(image.id == chiliTag.asset) {
          chiliTag.selectedImage = image;
          chiliTag.asset = image.id;
        }
      });
      return image;
    })
  }

  selectTag(tag) {
    this.selectedTag = tag;
    this.embeddedOrderItemsService.setEmbeddedByDocument().then(() => {
      this.productService.changeProductTag(this.selectedTag);
    });
  }

  close() {
    this.clientPageService.get('editor')
      .then((result: any) => {
        this.notification.customAlert(this.page.alert)
          .then((result) => {
            console.log('Click close: ', result);
            if(result.apply) {
              this.saveDocument().subscribe(() => {
                this.dialogRef.close();
              });
            } else {
              this.dialogRef.close();
            }
          })
      });
  }

  selectPage(page) {
    this.currentPage = page;
    this.ordersService.getTagsForPage(this.orderItem.id, this.currentProduct.id, this.currentPage.name)
      .then((result) => {
        if (result && result.tags && result.tags.length) {
          this.tags = result.tags;
          this.selectTag(this.tags[0]);

        }
      });
  }

  private addEmbedded(item) {
    const data = [{
      tagName: item.tagName,
      assetId: item.assetId,
      page: item.page
    }];

    return this.ordersService.saveImageToEmbedded(item.orderItemId, item.documentId, data)
      .then(() => {
        item.saved = true;
        return;
      })
  }

  ngOnDestroy() {
    this.errorsAddedSubscription && this.errorsAddedSubscription.unsubscribe();
    this.errorsRemovedSubscription && this.errorsRemovedSubscription.unsubscribe();
    this.languageSubscription && this.languageSubscription.unsubscribe();
  }


  findUnsavedDocument() {
    const document = this.adjustStepConditionService.findUnsavedDocument(this.orderItem.id);

    return document? document : this.groupProducts[this.groupProducts.length -1];
  }

  private checkUnwatchedProduct() {
    return _.find(this.groupProducts, (product) => {
      return !product.watched ? product : false;
    });
  }

  public openErrorDialog(){
    const data = this.errors;

    const dialogRef = this.dialog.open(ErrorsDialogComponent, {
      minWidth: '400px',
      data: data
    });

    return dialogRef.afterClosed();
  }

  checkErrors() {
    if (this.errors && this.errors.errors && this.errors.errors.quantity !== '0') {
      this.openErrorDialog();
      return false;
    } else {
      return true;
    }
  }

  private showNotification() {
    this.notification.alert(3);
  }


  public saveDocument(){
    return this.documentService.saveDocument();
  }

  public selectProduct(product) {
    if (this.currentProduct && !this.editorWasLoaded) {
      return this.showNotification();
    }
    if (!this.checkErrors()) {
      return;
    }

    if (this.currentProduct && this.currentProduct !== product) {
      const subscription = this.saveDocument()
        .subscribe((evt) => {
          this.adjustStepConditionService.setDocumentSavedCondition(this.currentProduct.id, this.orderItem.id)
          subscription.unsubscribe();
          product['watched'] = true;
          this.selectedProduct = product;
          this.embeddedOrderItemsService.setCurrentDocument(this.currentProduct);
          this.ordersService.getPages(this.orderItem.id, this.currentProduct.id)
            .then((result: any) => {
              if (result && result.pages) {
                this.pages =  result.pages;
                this.selectPage(this.pages[0]);
              }
            });
        }, (err) => {
          console.log(err)
        });
    }

    if(!this.currentProduct) {
      product['watched'] = true;
      this.selectedProduct = product;
      this.embeddedOrderItemsService.setCurrentDocument(this.currentProduct);
      this.ordersService.getPages(this.orderItem.id, this.currentProduct.id)
        .then((result: any) => {
          if (result && result.pages) {
            this.pages =  result.pages;
            this.selectPage(this.pages[0]);
          }
        });
    }
  }

  private initProductData(value) {
    this.currentProduct = value;
    if (value) {
      this.url = null;
      this.previews = [];
      this.getCurrentEmbedded(this.data)
        .then((result: any) => {
          result.assets.map((asset) => {
            this.getPreviewUrl(asset);
          })
        });

      this.getEditorUrl();
    }
  }

  public handleVariablesChangeCondition(condition) {
    this.variablesSubmenuOpened = condition;
  }

  private getEditorUrl() {
    this.isProcessed = true;

    this.orderItemDocumentsService.getEditorUrl(this.currentProduct.id)
      .then((result) => {
        if (result) {
          this.url = result.editorUrl;
        }
      });
  }

  public getCurrentEmbedded(data) {
    return new Promise((resolve, reject) => {
      this.ordersService.getEmbedded(this.data.orderItemId, this.currentProduct.id)
        .then((result) => {
          // console.log('getCurrentEmbedded: ', result);
          if (result && result.assets) {
            resolve(result);
          }
        });
    });

  }

  setVariable($event, variable) {
    this.documentService.setVariableValue(variable.id, variable.value);
  }

  public getUploaded(tag) {
    return new Promise((resolve, reject) => {
      this.ordersService.getUploaded(this.data.orderItemId, this.currentProduct.id, 1, tag)
        .then((result) => {
          if (result && result.assets && result.assets.length) {
            resolve(this.getCurrentEmbedded(result.assets));
          } else {
            resolve(false);
          }
        });
    })

  }

  public confirm() {
    this.isProcessed = true;
    this.saveDocument();
    setTimeout(() => {
      console.log('Document saved!!!')
      this.ordersService.confirm(this.orderItem.id)
        .then(() => {
          // this.stepsService.removeCurrentStep(this.orderItem.id);
          // this.router.navigate(['']);
          this.isProcessed = false;
          this.dialogRef.close();
        })
    }, 1000);
  }

  public save() {
    return this.documentService.saveDocument();
  }

  public reload() {
    this.url = null;
    this.selectedFrame = null;
    this.initProductData(this.currentProduct);
    // this.getEditorUrl();
  }

  private getZoom(){
    this.zoomVal = this.documentService.getZoom();
  }

  getErrors() {
    const errors = this.documentService.getDocumentErrors();

    this.errors['quantity'] = errors.numErrors;
    this.warnings['quantity'] = errors.numWarnings;

    this.getErrorsMessages();
    this.getWarningsMessages();

    this.errorsReady.next(
      {
        errors: this.errors ,
        warnings: this.warnings}
    );
  }

  getErrorsMessages() {
    let errorItems = this.documentService.getErrorsList();

    const messages = _.filter(errorItems, (error) => {
      return error.type === 'error';
    });

    this.errors.messages = _.map(messages, (message) => {
      return message.description;
    });
  }

  getWarningsMessages() {
    let errorItems = this.documentService.getErrorsList();

    const messages = _.filter(errorItems, (warning) => {
      return warning.type === 'warning';
    });

    this.warnings.messages = _.map(messages, (message) => {
      return message.description;
    });
  }

  resetErrors() {
    this.errors = {
      quantity: '0',
      messages: []
    };

    this.warnings = {
      quantity: '0',
      messages: []
    };

    this.errorsReady.next({errors: this.errors, warnings: this.warnings})
  }

  public zoomIn() {
    this.documentService.setZoom(`${this.zoomVal + 10}`);
    this.getZoom();
  }

  public zoomOut() {
    this.documentService.setZoom(`${this.zoomVal - 10}`);
    this.getZoom();
  }

  public fitPage() {
    this.documentService.fitPage();
    this.getZoom();
  }

  public fitHeight() {
    this.documentService.fitHeight();
    this.getZoom();
  }

  public fitWidth() {
    this.documentService.fitWidth();
    this.getZoom();
  }

  public toggleShowHelpLayers() {
    this.documentService.toggleShowHelpLayers();
    this.getZoom();
  }

  public setPreviewMode() {
    this.documentService.changeViewMode('preview');
    this.getZoom();
  }

  public setEditMode() {
    this.documentService.changeViewMode('edit');
    this.getZoom();
  }

  setProperty($value: any, propertyName: string) {
    const frame = this.editorService.editor.GetObject(`document.selectedFrame`);
    if(!frame || frame.type !== 'image') {
      return;
    }
    this.editorService.editor.SetProperty("document.selectedFrame", propertyName, $value);
    if (propertyName == 'transformationID') {
      setTimeout(() => {
        const frame = this.editorService.editor.GetObject(`document.selectedFrame`);
        if(!frame || frame.type !== 'image') {
          return;
        }
        // externalID
        let previewURL = this.previews.filter((preview: any) => {
          return preview.chiliId.toLowerCase() == frame.externalID.toLowerCase();
        });

        this.previewImage.src = previewURL[0].url;
      }, 800);
    }

  }

  touchAsset(asset: any, tag: any) {
    console.log(asset, this.selectedFrame);
    this.isShowSpinner = true;
    this.addEmbedded({
      tagName: tag,
      assetId: asset.id,
      page: this.currentPage.name,
      orderItemId: this.orderItem.id,
      documentId: this.currentProduct.id
    })
      .then(() => {
        // this.documentService.setImageFrameDynamicAssetProviderData(currentFrame.id, JSON.stringify({ isFirstValueSetting: true }));
        // let saveSubscription = this.documentService.saveDocument().subscribe(() => {
        //   console.log('reload');
        //   saveSubscription.unsubscribe();
        this.reload();
        // });

      });
  }
}
