import {
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren
}                                from '@angular/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { ThemeService }          from '../../theme.service';
import { ClientPageService }     from '../../client-page.service';
import { NotificationService }   from '../../global/notification.service';
import { UserService }           from '../../user.service';
import { Router }                from '@angular/router';
import { SettingService }        from '../../setting.service';
import { ConfigService }         from '../../config.service';
import { UploaderService }       from '../../uploader.service';
import { ProductsService }       from '../../products.service';
import { Subject }               from 'rxjs';

@Component({
  selector: 'app-external-uploader',
  templateUrl: './external-uploader.component.html',
  styleUrls: ['./external-uploader.component.sass']
})
export class ExternalUploaderComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,
              public themeService: ThemeService,
              public clientPageService: ClientPageService,
              private notification: NotificationService,
              private userService: UserService,
              private router: Router,
              private settingService: SettingService,
              private config: ConfigService,
              private uploaderService: UploaderService,
              private productsService: ProductsService
  ) { }

  @Input() page: any;
  password = '';
  campaign: any;
  remember_me = false;
  isProcessed = false;
  error: any;
  settings: any;
  isProcessedUploading = false;
  isProcessedMatching = false;
  isProcessedThanks = false;
  uploadedCount = 0;
  totalProgress = 0;
  totalSize = 0;
  totalSizes: any = [];

  campaigns: any[] = [];
  maintag: any;

  uploadingSubject: Subject<any> = new Subject();

  @ViewChildren('files') files: QueryList<ElementRef>;
  fileNames: any[] = [];
  selectedFiles: any[] = [];

  ngOnInit() {
    this.themeService.get();
    this.settingService.get().subscribe((settings: any) => {
      this.settings = settings;
    });

    this.productsService.getMaintags().then((response: any) => {
      this.campaigns = response.maintags;
    });

    this.uploadingSubject.subscribe((i) => {
      this.uploadedCount++;
      if (this.selectedFiles.length == this.uploadedCount) {
        this.isProcessedUploading = false;
        this.isProcessedMatching = true;
      }
    });
  }

  handleUpload($event) {
    this.isProcessed = true;
    this.isProcessedUploading = true;
    this.totalProgress = 0;
    this.prepareFilesList().then((i) => {
      if (this.selectedFiles.length == i) {
        this.matchFiles();
      }
    }, (i) => {
      if (this.selectedFiles.length == i) {
        this.matchFiles();
      }
    });
  }

  matchFiles() {
    let i = 0;
    while (this.selectedFiles[i] && (this.selectedFiles[i].status == 'matched' || this.selectedFiles[i].status == 'upload_error')) {
      i++;
      if (this.selectedFiles.length == i) {
        this.processFiles();
        return;
      }
    }
    let succesFullCallback = (response: any) => {
      this.selectedFiles[i].status = 'matched';
      i++;
      if (i == this.selectedFiles.length) {
        this.processFiles();
        return;
      }
      while (this.selectedFiles[i] && (this.selectedFiles[i].status == 'processed' || this.selectedFiles[i].status == 'matched' || this.selectedFiles[i].status == 'upload_error')) {
        i++;
        if (this.selectedFiles.length == i) {
          this.processFiles();
          return;
        }
      }
      this.uploaderService.match({
        password: this.password,
        files: [this.selectedFiles[i]]
      })
        .then(succesFullCallback, failCallback);
    };

    let failCallback = () => {
      this.selectedFiles[i].status = 'matched_error';
      i++;
      if (i == this.selectedFiles.length) {
        this.processFiles();
        return;
      }
      while (this.selectedFiles[i] && (this.selectedFiles[i].status == 'processed' || this.selectedFiles[i].status == 'matched' || this.selectedFiles[i].status == 'upload_error')) {
        i++;
        if (this.selectedFiles.length == i) {
          this.processFiles();
          return;
        }
      }
      this.uploaderService.match({
        password: this.password,
        files: [this.selectedFiles[i]]
      })
        .then(succesFullCallback, failCallback);
    };

    this.uploaderService.match({
      password: this.password,
      files: [this.selectedFiles[i]]
    })
      .then(succesFullCallback, failCallback);
  }

  processFiles() {
    if (this.selectedFiles.filter((file) => { return file.status != 'matched' && file.status != 'matched_error' && file.status != 'upload_error'; }).length = 0) {
      this.isProcessed = false;
      this.isProcessedUploading = false;
      this.isProcessedMatching = false;
      this.isProcessedThanks = true;
      return;
    }
    let succesFullCallback = (response: any) => {
      this.isProcessed = false;
      this.isProcessedUploading = false;
      this.isProcessedMatching = false;
      this.isProcessedThanks = true;
      this.selectedFiles = this.selectedFiles.map((file) => { file.status = file.status == 'matched' ? 'processed' : file.status; return file; });
    };

    let failCallback = () => {
      this.isProcessed = false;
      this.isProcessedUploading = false;
      this.isProcessedMatching = false;
      this.isProcessedThanks = true;
      this.selectedFiles = this.selectedFiles.map((file) => { file.status = file.status == 'matched' ? 'processed' : file.status; return file; });
    };

    this.uploaderService.process({
      password: this.password,
      files: this.selectedFiles.filter((file) => { return file.status == 'matched' && file.status != 'error'; })
    })
      .then(succesFullCallback, failCallback);
  }


  fileBrowseHandler(files: any[]): void {
    for (const item of files as any) {
      this.selectedFiles.push(item);
    }
  }

  onFileDropped(files: any[]): void {
    for (const item of files as any) {
      this.selectedFiles.push(item);
    }
  }

  /**
   * Convert Files list to normal array list
   */
  prepareFilesList() {
    let i = 0;
    this.totalSize = 0;
    return new Promise((resolve, reject) => {
      // for (let j = 0; j < this.selectedFiles.length; j++) {
        while (this.selectedFiles[i] && this.selectedFiles[i].progress == 100) {
          i++;
          if (this.selectedFiles.length == i) {
            resolve(i);
          }
        }
        if (!this.selectedFiles[i]) {
          resolve(i);
        }

        let item = this.selectedFiles[i];
        item.progress = 0;
        item.originalName = item.name;
        this.totalSize += item.size;

        let callbackSuccess = (file) => {
          i++;
          if (this.selectedFiles.length == i) {
            resolve(i);
          }
          while (this.selectedFiles[i] && this.selectedFiles[i].progress == 100) {
            i++;
            if (this.selectedFiles.length == i) {
              resolve(i);
            }
          }
          if (!this.selectedFiles[i]) {
            this.isProcessedUploading = false;
            this.isProcessedMatching = true;
            return;
          }
          let item = this.selectedFiles[i];
          item.progress = 0;
          item.originalName = item.name;
          this.totalSize += item.size;
          this.uploadFile(item, i).then(callbackSuccess, callbackFail);
          if (this.selectedFiles.length == i) {
            resolve(i);
          }
        };
        let callbackFail = () => {
          i++;
          if (this.selectedFiles.length == i) {
            reject(i);
          }
          while (this.selectedFiles[i] && this.selectedFiles[i].progress == 100) {
            i++;
            if (this.selectedFiles.length == i) {
              resolve(i);
            }
          }
          if (!this.selectedFiles[i]) {
            reject(i);
          }
          let item = this.selectedFiles[i];
          item.progress = 0;
          item.originalName = item.name;
          this.totalSize += item.size;
          this.uploadFile(item, i).then(callbackSuccess, callbackFail);

        };

        this.uploadFile(item, i).then(callbackSuccess, callbackFail);
      // }
    });
  }

  private uploadFile(file, index) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('password', this.password);
      formData.append('maintag', this.maintag ? this.maintag.id : '');

      this.uploaderService.post(formData).subscribe((progressState) => {

        file.progress = progressState.progress;
        if (file.progress < 100) {
          this.totalSizes[index] = file.progress;
          this.totalProgress = this.totalSizes.reduce((sum, current) => sum + current, 0);
          this.totalProgress = Math.round(this.totalProgress / this.selectedFiles.length);
        }
        if (progressState.state == 'DONE') {
          file.status = 'successfully';
          file.id = progressState.response.body.files[0];
          this.totalSizes[index] = 100;
          this.totalProgress = this.totalSizes.reduce((sum, current) => sum + current, 0);
          this.totalProgress = Math.round(this.totalProgress / this.selectedFiles.length);
          resolve(file);
        }
      }, (response) => {
        if (response.status = 404) {
          file.status = 'upload_error';
        }
        reject(file);
      });
    });

  }

  unselectFile(file: any) {
    let selectedFiles = [];
    for (let i = 0; i < this.selectedFiles.length; i++) {
      if (file == this.selectedFiles[i]) {
        continue;
      }
      selectedFiles.push(this.selectedFiles[i]);
    }
    this.selectedFiles = selectedFiles;
  }

  handleBack($event: MouseEvent) {
    this.password = '';
    this.totalProgress = 0;
    this.totalSizes = [];
    this.totalSize = 0;
    this.isProcessed = false;
    this.isProcessedUploading = false;
    this.isProcessedMatching = false;
    this.isProcessedThanks = false;
  }
}
