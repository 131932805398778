import { Injectable } from '@angular/core';
import {Subject} from "rxjs/Subject";
import {debounceTime} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  newProduct$: Subject<any> = new Subject<any>();
  changeTag$: Subject<any> = new Subject<any>();
  changePage$: Subject<any> = new Subject<any>();
  constructor() { }

  public changeProduct(value) {
    this.newProduct$.next(value)
  }

  public onChangeProduct() {
    return this.newProduct$.asObservable()
      .pipe(
        debounceTime(500)
      );
  }

  public changeProductPage(value) {
    this.changeTag$.next(value)
  }

  public onChangeProductPage() {
    return this.changePage$.asObservable()
      .pipe(
        debounceTime(500)
      );
  }

  public changeProductTag(value) {
    this.changeTag$.next(value)
  }

  public onChangeProductTag() {
    return this.changeTag$.asObservable()
      .pipe(
        debounceTime(500)
      );
  }
}
