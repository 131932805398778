import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemeService }                 from '../../theme.service';
import { OrdersService }                from '../../order-item.service';
import { OrdersResponse }               from '../../orders-response';
import { Subscription }                 from 'rxjs';
import { StepsService }                 from '../steps/steps.service';
import {NotificationService}            from "../../global/notification.service";
import {OrderItem}                      from "../../order-item";
import * as _                           from 'lodash';
import {TenantCreditSettingsService}    from "../../tenant-credit-settings.service";
import {UserService}                    from "../../user.service";
import { CreditsGroupsService }         from '../../credits-groups.service';
import {EditorPopupComponent} from "../../global/editor-popup/editor-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {ViewPopupComponent} from "../../global/view-popup/view-popup.component";
import {ExportService} from "../../editor/export.service";
import {DownloadService} from "../../editor/download.service";
import {ClientPageService} from "../../client-page.service";
import {DocumentService} from "../../editor/chili-editor/document.service";
import {EditorService} from "../../editor/chili-editor/editor.service";
import {ProductgroupService} from "../../productgroup.service";
import {CurrencyPipe} from "@angular/common";
import {ClientPage} from "../../client-page";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.sass']
})
export class CartComponent implements OnInit, OnDestroy {

  order: OrdersResponse;
  orderSubscription: Subscription;
  user: any = {creditBalance: 0}
  isEdited = false;
  processing = false;
  downloadProcessing = false;
  creditSettings: any = {creditValue: 0};
  creditUsed: number = 0;
  totalPrice: number = 0;

  creditGroups: any = [];
  quantityTimeOutIncrease: number;
  quantityTimeOutReduce: number;

  page: ClientPage;

  productGroups: any[] = [];

  constructor(
    public themeService: ThemeService,
    public ordersService: OrdersService,
    public stepService: StepsService,
    private notification: NotificationService,
    private creditSettingsService: TenantCreditSettingsService,
    private userService: UserService,
    public exportService: ExportService,
    private downloadService: DownloadService,
    public dialog: MatDialog,
    private creditGroupService: CreditsGroupsService,
    public clientPageService: ClientPageService,
    private documentService: DocumentService,
    private productgroupService: ProductgroupService,
    private editorService: EditorService
  ) { }

  ngOnInit() {
    this.processing = true;
    this.themeService.get();
    this.getSettings();
    this.clientPageService.get('shopping-cart').then((response: any) => {
      this.page = response.page;
    });
    this.productgroupService.list()
      .then((response: any) => {
        this.productGroups = response.data;
        this.ordersService.getShoppingCart();
        if (this.ordersService.currentShoppingCart) {
          this.order = this.ordersService.currentShoppingCart;
          this.order.orderItems = this.order.orderItems.map((product: any) => {
            product.notes = product.notes.replace(/\n/g, '<br>');
            return product;
          });
          this.updateTotalData();
          this.isConfirmer();
        }
        this.orderSubscription = this.ordersService.currentShoppingCart$.subscribe((order: OrdersResponse) => {
          if (!order || (order && !order.id)) {
            return;
          }
          this.order = order;
          this.order.orderItems = this.order.orderItems.map((product: any) => {
            product.notes = product.notes.replace(/\n/g, '<br>');
            return product;
          });
          this.updateTotalData();
          this.isConfirmer();
        });
      })


  }

  getItemPrice(item) {
    let totalPrice = 0.00;
    for(let orderItemDocumentIndex = 0; orderItemDocumentIndex < item.documents.length; orderItemDocumentIndex++) {
      const price = (item.documents[orderItemDocumentIndex].document.price) as number;
      const quantity = (item.quantity) as number;
      totalPrice += (price * quantity);
    }
    return totalPrice;
  }

  getOrderPrice() {
    let creditsUsed = 0.00;
    let totalPrice = 0.00;
    for(let orderItemIndex = 0; orderItemIndex < this.order.orderItems.length; orderItemIndex++) {
      for(let orderItemDocumentIndex = 0; orderItemDocumentIndex < this.order.orderItems[orderItemIndex].documents.length; orderItemDocumentIndex++) {
        const price = (this.order.orderItems[orderItemIndex].documents[orderItemDocumentIndex].document.price) as number;
        const quantity = (this.order.orderItems[orderItemIndex].quantity) as number;
        totalPrice += (price * quantity);
        if(!this.order.orderItems[orderItemIndex].product.settings.buyByCredit) {
          continue;
        }
        if(
          creditsUsed +
          ((price  * this.order.orderItems[orderItemIndex].quantity) / this.creditSettings.creditValue)
          <= this.userService.user.creditBalance
        ) {
          creditsUsed = creditsUsed + ((price * quantity) / this.creditSettings.creditValue);
        } else {
          creditsUsed = this.userService.user.creditBalance;

        }
      }
    }
    totalPrice -= creditsUsed * this.creditSettings.creditValue;
    return totalPrice;
  }

  getCreditsUsed() {
    let creditsUsed = 0.00;
    for(let orderItemIndex = 0; orderItemIndex < this.order.orderItems.length; orderItemIndex++) {
      if(!this.order.orderItems[orderItemIndex].product.settings.buyByCredit) {
        continue;
      }
      for(let orderItemDocumentIndex = 0; orderItemDocumentIndex < this.order.orderItems[orderItemIndex].documents.length; orderItemDocumentIndex++) {
        const price = (this.order.orderItems[orderItemIndex].documents[orderItemDocumentIndex].document.price) as number;
        const quantity = (this.order.orderItems[orderItemIndex].quantity) as number;
        if(
          creditsUsed +
          ((price  * this.order.orderItems[orderItemIndex].quantity) / this.creditSettings.creditValue)
          <= this.userService.user.creditBalance
        ) {
          creditsUsed = creditsUsed + ((price * quantity) / this.creditSettings.creditValue);
        } else {
          creditsUsed = this.userService.user.creditBalance;
        }
      }
    }
    this.userService.setShowCreditBalance(this.userService.user.creditBalance - creditsUsed);
    return creditsUsed;
  }

  getProductgroup(productgroupId: number) {
    return this.productGroups.find((item) => {
      return item.id == productgroupId;
    })
  }

  getCreditGroup(group_id) {
    if (!group_id || this.creditGroups[group_id]) {
      return;
    }
    this.creditGroups[group_id] = this.creditGroupService.get(group_id)
      .then((response: any) => {
        this.creditGroups[response.group.id] = response.group;
      });
  }

  async getSettings() {
    this.creditSettings = await this.creditSettingsService.getSettings();
  }

  async updateTotalData() {
    await this.getSettings();
    this.user = await this.userService.getUser();
    this.order.orderItems.map((item: OrderItem) => {
      this.getCreditGroup(item.creditGroupId);
    });
    // const creditCost = this.order.orderItems
    //   .filter(orderItem => orderItem.settings && orderItem.settings.buyByCredit)
    //   .reduce((creditCostS: any, orderItem: any, index, orderItems) => {
    //       return creditCostS;
    //     }, 0
    //   );
    //
    // const creditCostForConfirmed = this.order.orderItems
    //   .filter(orderItem => orderItem.settings && orderItem.settings.buyByCredit)
    //   .reduce((creditCostForConfirmedS: any, orderItem: any, index, orderItems) => {
    //       return creditCostForConfirmedS + orderItem.cost_on_credits;
    //     }, 0
    //   );
    //
    //
    // if (creditCost >= this.user.creditBalance) {
    //   this.creditUsed = this.user.creditBalance + creditCostForConfirmed;
    //   this.totalPrice = this.order.price - (this.creditUsed * this.creditSettings.creditValue);
    // } else {
    //   this.creditUsed = creditCost + creditCostForConfirmed;
    //   this.totalPrice = this.order.price - (this.creditUsed * this.creditSettings.creditValue);
    // }
    this.creditUsed = await this.ordersService.getCreditsUsed();
    this.totalPrice = await this.ordersService.getOrderPrice();
    // console.log('this.creditUsed ', this.creditUsed);
    this.processing = false;
  }

  ngOnDestroy() {
    this.orderSubscription.unsubscribe();
  }

  remove(orderItem): void {
    this.ordersService.removeOrderItem(orderItem.id);
  }

  manualInput(item) {
    if (this.quantityTimeOutReduce) {
      clearTimeout(this.quantityTimeOutReduce);
    }

    if (item.settings && item.quantity < item.settings.minInput) {
      item.quantity = item.settings.minInput;
    }

    if (item.settings && item.settings.maxInput != 0 && item.quantity > item.settings.maxInput && !item.settings.unlimited_stock) {
      item.quantity = item.settings.maxInput;
    }
    this.quantityTimeOutReduce = window.setTimeout(() => {
        this.processing = false;
        this.ordersService.updateQuantity(item.id, [{op: 'updateQuantity', attribute: 'quantity', value: item.quantity}]);
      },
      250);
  }

  reduce(item) {
    if (this.quantityTimeOutReduce) {
      clearTimeout(this.quantityTimeOutReduce);
    }
    if (!item.settings) {
      item.quantity--;
    } else {
      item.quantity -= item.settings.addByValue;
    }

    if (item.settings && item.quantity < item.settings.minInput) {
      item.quantity = item.settings.minInput;
    }
    this.quantityTimeOutReduce = window.setTimeout(() => {
        this.processing = false;
        this.ordersService.updateQuantity(item.id, [{op: 'updateQuantity', attribute: 'quantity', value: item.quantity}]);
      },
      250);
  }

  increase(item) {
    if (this.quantityTimeOutIncrease) {
      window.clearTimeout(this.quantityTimeOutIncrease);
    }
    if (!item.settings) {
      item.quantity++;
    } else {
      item.quantity += item.settings.addByValue;
    }

    if (item.settings && item.settings.maxInput != 0 && item.quantity > item.settings.maxInput && !item.settings.unlimited_stock) {
      item.quantity = item.settings.maxInput;
    }
    this.quantityTimeOutIncrease = window.setTimeout(() => {
        this.processing = false;
        this.ordersService.updateQuantity(item.id, [{op: 'updateQuantity', attribute: 'quantity', value: item.quantity}]);
      },
      250);

  }

  next() {
    if (this.isEdited) {
      this.notification.alert(1);
      return;
    }

    if (this.page.alert.isShown && this.getOrderPrice() > 0) {
      // currency: 'EUR':'code':'1.2-3':'nl-BE'
      this.clientPageService.get('shopping-cart')
        .then((result: any) => {
          this.notification.customAlert(this.page.alert, {totalPrice: this.getOrderPrice()})
            .then((result) => {
              // console.log('Click close: ', result);
              if (result.apply) {
                this.stepService.goToNext();
              }
            });
        });
      return;
    }

    this.stepService.goToNext();
  }

  previous() {
    this.stepService.goToPrevious();
  }

  isConfirmer() {
    if (!this.order.orderItems) {
      return;
    }
    for ( const item of this.order.orderItems) {
      if (
        (item.status !== 'confirmed' && item.isRequired)
      ) {
        this.isEdited = true;
        return;
      }
    }
    this.isEdited = false;
    return;
  }

  isWebshopItem(item: OrderItem) {
    const documents = _.filter(item.documents, (document) => {
      return document.type != 'webshop_item';
    });
    return documents.length > 0 ? false : true;
  }

  changeComments(item: OrderItem) {
    this.processing = false;
    this.ordersService.updateComments(item.id, [{op: 'updateComments', attribute: 'comments', value: item.comments}])
  }

  isMinAmountOfGroup(item: OrderItem) {
    if (!this.creditGroups || !this.creditGroups[item.creditGroupId] || !this.creditGroups[item.creditGroupId]['products']) {
      return false;
    }
    const products = this.creditGroups[item.creditGroupId].products.filter((product: any) => {
      return (product.productId == item.productId || (item.productgroupId && product.productId == item.productgroupId)) && item.quantity > product.min;
    });
    return products.length > 0;
  }

  isMaxAmountOfGroup(item: OrderItem) {
    if (!this.creditGroups || !this.creditGroups[item.creditGroupId] || !this.creditGroups[item.creditGroupId]['products']) {
      return false;
    }
    const products = this.creditGroups[item.creditGroupId].products.filter((product: any) => {
      return (product.productId == item.productId || (item.productgroupId && product.productId == item.productgroupId)) && item.quantity < product.max;
    });
    return products.length > 0;
  }

  view(item: OrderItem) {
    let dialogRef = this.dialog.open(ViewPopupComponent, {
      data: {
        orderItemId: item.id
      },
      width: '80%',
      height: '800px',
      panelClass: 'view-popup-panel'
    });
  }

  edit(item: OrderItem) {
    let dialogRef = this.dialog.open(EditorPopupComponent, {
      data: {
        orderItemId: item.id
      },
      width: '90%',
      height: '800px',
      panelClass: 'editor-popup-panel',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(() => {
      this.ordersService.getShoppingCart();
    });

    dialogRef.backdropClick()
      .subscribe(() => {
        this.clientPageService.get('editor')
          .then((result: any) => {
            this.notification.customAlert(result.page.alert)
              .then((result) => {
                if (result.apply) {
                      this.documentService.saveDocument()
                        .subscribe(() => {
                          window.setTimeout(() => {
                            this.editorService.shellReady.next(false);
                            dialogRef.close();
                          }, 1000);
                        });
                    } else {
                      dialogRef.close();
                    }
              })
          });
      });
  }

  download(item: OrderItem) {
    item.downloadProcessing = true;

    if(item.downloadType == 'pdf') {
      this.exportService.export(item.id, item.documents[0].id)
        .then((result) => {
          if (result) {
            this.downloadPdf(result)
              .then(() => {
                item.downloadProcessing = false;
              });
          }
        });
    }

    if(item.downloadType == 'jpeg') {
      this.ordersService.getPreviewOnProofStep(item.id, item.documents[0].id)
        .then((result) => {
          if (result && result.urls && result.urls.length) {
            const data = result.urls[0].split('.online')[1];
            const url  = `/CHILI${data}`;
            this.exportService.getPreviewJpeg(url)
              .then((res) => {
                if (res) {
                  this.downloadService.download(res);
                  item.downloadProcessing = false;
                }
              })
          }
        });

    }
  }

  private downloadPdf(previewUrl) {
    return this.exportService.getPreviewPdf(previewUrl)
      .then((res) => {
        if (res) {
          this.downloadService.download(res);
        }
      })
  }

  private downloadJpeg(previewUrl) {
    return this.exportService.getPreviewJpeg(previewUrl)
      .then((res) => {
        if (res) {
          this.downloadService.download(res);
        }
      })
  }

  public isShowCurrencyPrice(item: any) {
    return item.settings && item.settings.shownPrice;
  }

  public isShowCreditsPrice(item: any) {
    return item.settings && item.settings.shownCredit && this.creditSettings.enable;
  }

  public isShowEditButton(item: any) {
    return item.status == 'editing' && !this.isWebshopItem(item);
  }

  public isShowViewButton(item: any) {
    return item.status == 'confirmed' && !this.isWebshopItem(item)
  }

  public isShowDownloadButton(item: any) {
    return item.status == 'confirmed' && item.downloadType != 'none' && !this.isWebshopItem(item);
  }

  public isDisabledReduce(item: any) {
    return (item.type == 'credit' && (this.creditGroups && this.creditGroups[item.creditGroupId] && this.creditGroups[item.creditGroupId].isLockedProducts && !this.isMinAmountOfGroup(item))) || this.processing || item.quantity <= 1;
  }

  public isDisabledIncrease(item: any) {
    return this.processing || (item.quantity == item.inStock && !item.isUnlimitedStock) || (item.type == 'credit' && !this.isMaxAmountOfGroup(item));
  }

  public isShowRemoveButton(item) {
    return (item.type == 'credit' && (this.creditGroups && this.creditGroups[item.creditGroupId] && !this.creditGroups[item.creditGroupId].isLockedProducts)) || item.type != 'credit';
  }

  public isShowChiliPreview(item) {
    return item.documents.length != 0 && item.documents[0].files.images.length == 0;
  }

  public isShowDocumentPreview(item) {
    return item.documents.length != 0 && item.documents[0].files.images.length > 0;
  }

  selectOtherProduct(productId: any, orderItem: OrderItem) {
    this.ordersService.changeOrderProduct(orderItem.id, productId)
      .then((response: any) => {
        this.ordersService.getShoppingCart();
      });
  }
}
