<div class="progress-bar-container">
<!--  <mat-progress-bar mode="query"></mat-progress-bar>-->
  <mat-progress-bar mode="query" *ngIf="isProcessed"></mat-progress-bar>
</div>
<div mat-dialog-title>
  <h1>Editor</h1>
  <div  class="group-products-title" *ngIf="page && page.title && page.title.isShown" [innerHTML]="page.title.title | safe: 'html'"></div>
  <div class="products-wrapper" *ngIf="currentProduct != null">
    <div
      class="product"
      *ngFor="let product of groupProducts"
      [ngStyle]="{
        'background-color': (themeService.settings$ | async).getButtonColor(1),
        'color': currentProduct.id === product.id ? '#ffffff' : 'rgba(0, 0, 0, 0.25)'
        }"
      (click)="selectProduct(product)">
      {{product.name}}
    </div>
  </div>
  <button mat-icon-button class="close" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <div class="container">

    <div class="page-container">

      <div class="status" [ngClass]="[currentImage.status]" *ngIf="currentImage">{{currentImage.status === 'to_be_checked'? 'to be checked': currentImage.status}}</div>
      <div class="actions">
        <button class="action-button action save-button" (click)="save()" appI18nTranslation-matTooltip matTooltip="Save document">
          <div></div>
        </button>
        <div class="horizontal-divider"></div>
        <div class="actions-wrapper">
          <button class="action-button action full-screen-button" appI18nTranslation-matTooltip matTooltip="Fit page" (click)="fitPage()">
            <div></div>
          </button>
          <button class="action-button action width-button" appI18nTranslation-matTooltip matTooltip="Fit width" (click)="fitWidth()">
            <div></div>
          </button>
          <button class="action-button action height-button" appI18nTranslation-matTooltip matTooltip="Fit height" (click)="fitHeight()">
            <div></div>
          </button>
          <button class="action-button" (click)="reload()" appI18nTranslation-matTooltip matTooltip="Reload">
            <mat-icon>cached</mat-icon>
          </button>
          <button class="action-button" (click)="toggleShowHelpLayers()" appI18nTranslation-matTooltip matTooltip="Hide/Show help layers">
            <mat-icon>visibility</mat-icon>
          </button>
          <!--      <button class="action-button" (click)="setPreviewMode()">-->
          <!--        <mat-icon>visibility</mat-icon>-->
          <!--      </button>-->
          <button class="action-button action edit-button" (click)="setEditMode()" appI18nTranslation-matTooltip matTooltip="Edit mode">
            <div></div>
          </button>
          <button class="action-button" (click)="zoomOut()" appI18nTranslation-matTooltip matTooltip="Zoom out">
            <mat-icon>zoom_out</mat-icon>
          </button>
          <button class="action-button" (click)="zoomIn()" appI18nTranslation-matTooltip matTooltip="Zoom in">
            <mat-icon>zoom_in</mat-icon>
          </button>
          <div class="zoom" *ngIf="zoomVal">{{zoomVal}}%</div>
        </div>
      </div>
      <div class="vertical-divider"></div>

      <div class="editor-wrapper">
        <div class="spinner-overlay" *ngIf="isShowSpinner">
          <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <app-chili-editor class="adjust-area" *ngIf="url" [url]="url"></app-chili-editor>
      </div>
      <div class="toolbar-wrapper">
        <div class="page-message">
          <div class="text" *ngIf="page && page.text && page.text.isShown" [innerHTML]="page.text.text | safe: 'html'"></div>
          <h4 *ngIf="page && page.sub_title && page.sub_title.isShown" [innerHTML]="page.sub_title.sub_title | safe: 'html'"></h4>
          <div class="page-message-actions">
            <button (click)="confirm()" mat-button [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}" appI18nTranslation>Confirm for print</button>
          </div>
          <div class="page-message-actions">
            <div
              class="errors"
              *ngIf="errors && warnings">
              <span [ngClass]="{'has-messages': errors && errors.messages && errors.messages.length}">
                {{errors.quantity}} <span appI18nTranslation>errors</span> /
                <app-error-tooltip class="error" [messages]="errors.messages"></app-error-tooltip>
              </span>
              <span [ngClass]="{'has-messages': warnings && warnings.messages && warnings.messages.length}">
                {{warnings.quantity}} <span appI18nTranslation>warnings</span>
                <app-error-tooltip class="warning" [messages]="warnings.messages"></app-error-tooltip>
              </span>
            </div>
          </div>
        </div>
        <div class="editor-toolbar">
          <div class="variables-frame-toolbar" [style.display]="selectedFrame && selectedFrame.type != 'image' ? 'block' : 'none'">
            <div class="editor-toolbar-field" *ngFor="let variable of variables">
              <div class="editor-toolbar-field-label">{{variable.displayName}}</div>
              <div class="editor-toolbar-field-input"  *ngIf="variable.dataType === 'list'">
                <select
                  [(ngModel)]="variable.value"
                  (ngModelChange)="setVariable($event, variable)"
                  [disabled]="variable.allowEditStyle"
                >
                  <option *ngFor="let option of variable.options" [value]="option">{{option.name}}</option>
                </select>
              </div>
              <div class="editor-toolbar-field-input"  *ngIf="variable.dataType === 'string'">
                <input
                  [(ngModel)]="variable.value"
                  (change)="setVariable($event, variable)"
                  [disabled]="variable.allowEditStyle"
                  [max]="variable.maxLength"
                  [maxlength]="variable.maxLength"
                  #input
                  type="text">
                <small>{{input.value.length}} / {{variable.maxLength}}</small>
              </div>
              <div class="editor-toolbar-field-input"  *ngIf="variable.dataType === 'number'">
                <input
                  [(ngModel)]="variable.value"
                  (change)="setVariable($event, variable)"
                  [disabled]="variable.allowEditStyle"
                  [max]="variable.maximum"
                  [min]="variable.minimum"
                  minMax
                  [maxlength]="variable.maximum"
                  #input
                  type="number">
                <small>{{input.value}} / {{variable.maximum}}</small>
              </div>
              <div class="editor-toolbar-field-input"  *ngIf="variable.dataType === 'longtext'">
                <textarea
                  [(ngModel)]="variable.value"
                  (keyup)="setVariable($event, variable)"
                  [disabled]="variable.allowEditStyle"
                  [maxlength]="variable.maxLength"
                  rows="4"
                  #input
                  type="text"></textarea>
                <small>{{input.value.length}} / {{variable.maxLength}}</small>
              </div>
              <div class="editor-toolbar-field-input"  *ngIf="variable.dataType === 'checkbox'">
                <mat-checkbox
                  disableRipple
                  [(ngModel)]="variable.value"
                  (change)="setVariable($event, variable)"
                  [labelPosition]="'before'"
                  [disabled]="variable.allowEditStyle"
                >
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="image-frame-toolbar" [style.display]="selectedFrame && selectedFrame.type == 'image' ? 'block' : 'none'">
            <div class="editor-toolbar-field">
              <div class="editor-toolbar-field-label">Trasformation:</div>
              <div class="editor-toolbar-field-input">
                <select [(ngModel)]="transformation" (ngModelChange)="setProperty($event, 'transformationID')">
                  <option value=""></option>
                  <option value="b6dc304f-52bb-4cc7-b509-ad23bd35d67f">Monochrome</option>
                  <option value="7d55fee1-bb64-4ada-9e55-282e624f5b1a">Gray</option>
                  <option value="dea68ce7-6d64-4149-a8ef-a2f5af49b0dd">Perspective_01</option>
                  <option value="73967a29-db49-4c9d-89b4-eb61bd98ac75">Perspective_02</option>
                  <option value="4f6724da-792f-48ff-aabd-7b82e437cccc">Charcoal</option>
                  <option value="ae3cca8e-c846-4bda-8b2b-0b9cc10cd880">Sketch</option>
                  <option value="f34eb0db-7924-4341-a7ee-e8864014a895">Paint</option>
                </select>
              </div>
            </div>
            <div class="editor-toolbar-field">
              <div class="editor-toolbar-field-label">Fit:</div>
              <div class="editor-toolbar-field-input">
                <select [(ngModel)]="fitMode" (ngModelChange)="setProperty($event, 'fitMode')">
                  <option value="frame">Frame</option>
                  <option value="stretch">Stretch</option>
                  <option value="proportional">Proportional</option>
                  <option value="manual">Manual</option>
                  <option value="proportional_outside">Proportional, Outside</option>
                </select>
              </div>
            </div>
            <div class="editor-toolbar-field" *ngIf="fitMode != 'manual'">
              <div class="editor-toolbar-field-label">Anchor:</div>
              <div class="editor-toolbar-field-input">
                <select [(ngModel)]="fitModeAnchor" (ngModelChange)="setProperty($event, 'fitPoint')">
                  <option value="topleft">Top Left</option>
                  <option value="top">Top</option>
                  <option value="topright">Top Right</option>
                  <option value="left">Left</option>
                  <option value="center">Center</option>
                  <option value="right">Right</option>
                  <option value="bottomleft">Bottom Left</option>
                  <option value="bottom">Bottom</option>
                  <option value="bottomright">Bottom Right</option>
                </select>
              </div>
            </div>
            <div class="editor-toolbar-canvas-container" [style.display]="selectedFrame && selectedFrame.type == 'image' ? 'flex' : 'none'">
              <canvas #canvasElement width="4000" height="2500" [style.cursor]="canvasCursor"></canvas>
              <div class="assets-list" *ngIf="selectedFrame && findTag(selectedFrame.tag)">
                <div
                     *ngFor="let asset of findTag(selectedFrame.tag).uploadedImages"
                     [ngClass]="{ 'asset': true,  'selected': previewImage.src == asset.previewLink }"
                     (click)="touchAsset(asset, selectedFrame.tag)"
                >
                  <img [src]="asset.previewLink">
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
