import { Component, Input, OnInit } from '@angular/core';
import { ThemeService }             from '../../theme.service';
import { ClientPage }               from '../../client-page';
import {UploaderPopupComponent} from "../../global/uploader-popup/uploader-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {SettingService} from "../../setting.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  @Input() page: ClientPage;

  titleStyle: any = {};
  subTitleStyle: any = {};

  constructor(
    public dialog: MatDialog,
    public themeService: ThemeService,
    public settingService: SettingService
  ) { }

  ngOnInit() {
    // this.titleStyle = this.getStylesFromMarkup(this.page.title.title);
    // this.subTitleStyle = this.getStylesFromMarkup(this.page.sub_title.sub_title);
  }

  getStylesFromMarkup(html: string) {
    let styles = {};
    const expression = new RegExp('style="(.*)"');
    const style = html.match(expression);
    if (style) {
      style[1].split(';').map((item) => {
        return item.split(':');
      }).map((item) => {
        if (item[0]) {
          styles[item[0].trim()] = item[1].trim();
        }
      });
    }

    return styles;
  }

  openUploadDialog($event: MouseEvent) {
    let dialogRef = this.dialog.open(UploaderPopupComponent, {
      data: {},
      width: '70%',
      height: '600px',
      panelClass: 'media-library-panel',
      closeOnNavigation: true,
      disableClose: true
    });

    dialogRef.backdropClick().subscribe(($event) => {
      dialogRef.componentInstance.close()
    });
  }

}
