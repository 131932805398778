import { Injectable }                  from '@angular/core';
import {HttpClient, HttpHeaders}       from '@angular/common/http';
import { ConfigService }               from './config.service';
import {catchError, delay, switchMap}  from "rxjs/operators";
import {of}                            from "rxjs/internal/observable/of";
import { OrdersResponse }              from './orders-response';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService }                 from "./user.service";
import {TenantCreditSettingsService} from "./tenant-credit-settings.service";


let headers = new HttpHeaders();
headers = headers.set('Accept', 'application/pdf');

@Injectable({
  providedIn: 'root'
})

export class OrdersService {

  public currentOrder$ = new BehaviorSubject<OrdersResponse>(new OrdersResponse());
  public currentOrder: OrdersResponse;

  public currentShoppingCart$ = new BehaviorSubject<OrdersResponse>(new OrdersResponse());
  public currentShoppingCart: OrdersResponse;
  private creditSettings: any;

  constructor(private http: HttpClient, private creditSettingsService: TenantCreditSettingsService, private configService: ConfigService, private userService: UserService) {

  }

  async getSettings() {
    this.creditSettings = await this.creditSettingsService.getSettings();
  }

  public async getItemPrice(item) {
    await this.getSettings();
    let totalPrice = 0.00;
    for(let orderItemDocumentIndex = 0; orderItemDocumentIndex < item.documents.length; orderItemDocumentIndex++) {
      const price = (item.documents[orderItemDocumentIndex].document.price) as number;
      const quantity = (item.quantity) as number;
      totalPrice += (price * quantity);
    }
    return totalPrice;
  }

  public async getOrderPrice() {
    await this.getSettings();
    let creditsUsed = 0.00;
    let totalPrice = 0.00;
    for(let orderItemIndex = 0; orderItemIndex < this.currentShoppingCart.orderItems.length; orderItemIndex++) {
      for(let orderItemDocumentIndex = 0; orderItemDocumentIndex < this.currentShoppingCart.orderItems[orderItemIndex].documents.length; orderItemDocumentIndex++) {
        const price = (this.currentShoppingCart.orderItems[orderItemIndex].documents[orderItemDocumentIndex].document.price) as number;
        const quantity = (this.currentShoppingCart.orderItems[orderItemIndex].quantity) as number;
        totalPrice += (price * quantity);
        if(!this.currentShoppingCart.orderItems[orderItemIndex].product.settings.buyByCredit) {
          continue;
        }
        if(
          creditsUsed +
          ((price  * this.currentShoppingCart.orderItems[orderItemIndex].quantity) / this.creditSettings.creditValue)
          <= this.userService.user.creditBalance
        ) {
          creditsUsed = creditsUsed + ((price * quantity) / this.creditSettings.creditValue);
        } else {
          creditsUsed = this.userService.user.creditBalance;

        }
      }
    }
    totalPrice -= creditsUsed * this.creditSettings.creditValue;
    return totalPrice;
  }

  public async getCreditsUsed() {
    await this.getSettings();
    let creditsUsed = 0.00;
    for(let orderItemIndex = 0; orderItemIndex < this.currentShoppingCart.orderItems.length; orderItemIndex++) {
      if(!this.currentShoppingCart.orderItems[orderItemIndex].product.settings.buyByCredit) {
        continue;
      }
      for(let orderItemDocumentIndex = 0; orderItemDocumentIndex < this.currentShoppingCart.orderItems[orderItemIndex].documents.length; orderItemDocumentIndex++) {
        const price = (this.currentShoppingCart.orderItems[orderItemIndex].documents[orderItemDocumentIndex].document.price) as number;
        const quantity = (this.currentShoppingCart.orderItems[orderItemIndex].quantity) as number;

        if(
          creditsUsed +
          ((price  * this.currentShoppingCart.orderItems[orderItemIndex].quantity) / this.creditSettings.creditValue)
          <= this.userService.user.creditBalance
        ) {
          creditsUsed = creditsUsed + ((price * quantity) / this.creditSettings.creditValue);
        } else {
          creditsUsed = this.userService.user.creditBalance;
        }
      }
    }
    this.userService.setShowCreditBalance(this.userService.user.creditBalance - creditsUsed);
    return creditsUsed;
  }

  get(): void {
    this.http.get(this.configService.getActionUrl('orders')).pipe(
        catchError(this.handleError('get order', null))
      ).subscribe((response: OrdersResponse) => {
        this.currentOrder = response;
        this.currentOrder$.next(response);
      });
  }

  find(orderId: number) {
    return this.http.get(this.configService.getActionUrl('order/' + orderId)).pipe(
      catchError(this.handleError('get order', null))
    ).toPromise();
  }

  list(offset = 0, size = 10) {
    return this.http.get(this.configService.getActionUrl('orders/list?offset=' + offset + "&size=" + size)).pipe(
      catchError(this.handleError('get orders list', null))
    ).toPromise();
  }

  public getProduct(id): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`products/${id}`))
      .pipe(
        catchError(this.handleError('get product', null))
      )
      .toPromise()
  }

  public getOrderItem(id): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`order-items/${id}`))
      .pipe(
        catchError(this.handleError('get item', null))
      )
      .toPromise()
  }

  public sendMail(id, email): Promise<any> {
    return this.http.post(this.configService.getActionUrl(`order-items/${id}/share-email`), {emails: [email]})
      .pipe(
        catchError(this.handleError('send mail', null))
      )
      .toPromise()
  }

  public sendHelpdeskMail(id, data): Promise<any> {
    return this.http.post(this.configService.getActionUrl(`order-items/${id}/support-email`), data)
      .pipe(
        catchError(this.handleError('send support mail', null))
      )
      .toPromise()
  }

  public confirm(id): Promise<any> {
    return this.http.patch(this.configService.getActionUrl(`order-items/${id}`), [{op: 'confirm'}])
      .pipe(
        catchError(this.handleError('confirm order', null))
      )
      .toPromise()
  }

  public getFullPreview(id): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`order-items/${id}/preview?type=full`))
      .pipe(
        catchError(this.handleError('get full preview', null))
      )
      .toPromise()
  }

  public getThumbnailPreview(id): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`order-items/${id}/preview?type=thumbnail`))
      .pipe(
        catchError(this.handleError('get full thumbnail', null))
      )
      .toPromise()
  }

  public getTagsForPage(id: number, documentId: number, page: number): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`order-items/${id}/${documentId}/${page}/assets/tags`))
      .pipe(
        catchError(this.handleError('get tags', null))
      )
      .toPromise()
  }

  public async getTags(id: number, documentId: number): Promise<any> {
    return await this.http.get(this.configService.getActionUrl(`order-items/${id}/${documentId}/assets/tags`))
      .pipe(
        catchError(this.handleError('get tags', null))
      )
      .toPromise()
  }

  public getPages(id: number, documentId: number): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`order-items/${id}/${documentId}/assets/pages`))
      .pipe(
        catchError(this.handleError('get pages', null))
      )
      .toPromise()
  }

  public export(id: number, documentId: number): Promise<any> {
    return this.http.post(this.configService.getActionUrl(`order-items/${id}/${documentId}/export`), {highQuality: false, isPreview: true})
      .pipe(
        catchError(this.handleError('export', null))
      )
      .toPromise()
  }

  public getExported(id: number): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`order-items/documents/export/${id}`))
      .pipe(
        delay(4000),
        catchError(this.handleError('export', null))
      )
      .toPromise()
  }

  public getPreviewPdf(url){
    return this.http.get(url, { responseType: 'blob', observe: 'response', headers: headers })
      .pipe(
        catchError(this.handleError('preview', null))
      )
      .toPromise()
  }

  public getPreviewJpeg(url){
    let jpegHeaders = new HttpHeaders();
    jpegHeaders = jpegHeaders.set('Accept', 'image/jpeg');
    return this.http.get(url, { responseType: 'blob', observe: 'response', headers: jpegHeaders })
      .pipe(
        catchError(this.handleError('preview', null))
      )
      .toPromise()
  }


  public getEditorUrlOnProofStep(id: number, documentId: number): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`order-items/${id}/${documentId}/3d-or-folding-preview-url`))
      .pipe(
        catchError(this.handleError('get editor url', null))
      )
      .toPromise()
  }

  public getGroupedEditorUrlOnProofStep(id: number): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`order-items/${id}/3d-preview-url`))
      .pipe(
        catchError(this.handleError('get editor url', null))
      )
      .toPromise()
  }

  public getPreviewOnProofStep(id: number, documentId: number): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`order-items/${id}/${documentId}/preview`))
      .pipe(
        catchError(this.handleError('get editor url', null))
      )
      .toPromise()
  }

  public getEmbedded(id: number, documentId: number): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`order-items/${id}/${documentId}/assets/embedded`))
      .pipe(
        catchError(this.handleError('get embedded', null))
      )
      .toPromise()
  }

  public removeEmbedded(id: number, documentId: number): Promise<any> {
    return this.http.delete(this.configService.getActionUrl(`order-items/${id}/${documentId}/assets/embedded`))
      .pipe(
        catchError(this.handleError('delete embedded', null))
      )
      .toPromise()
  }

  public getEmbeddedList(id: number): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`order-items/${id}/assets/embedded`))
      .pipe(
        catchError(this.handleError('get embedded list', null))
      )
      .toPromise()
  }

  public getUploaded(id: number, documentId: number, documentPage: number, documentTag: string): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`order-items/${id}/${documentId}/${documentPage}/${documentTag}/assets/uploaded`))
      .pipe(
        catchError(this.handleError('get uploaded', null))
      )
      .toPromise()
  }

  public getUserUploaded(documentTag: string, chiliTagId: number): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`assets/${documentTag}/${chiliTagId}/uploaded`))
      .pipe(
        catchError(this.handleError('get uploaded', null))
      )
      .toPromise()
  }

  public getUploadedItem(id: number, documentId: number, uploadeItemId: number): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`order-items/${id}/${documentId}/assets/uploaded/${uploadeItemId}`))
      .pipe(
        delay(2000),
        catchError(this.handleError('get uploaded', null))
      )
      .toPromise()
  }

  public saveUploadedImageToAssets(id: number, documentId: number, data: any): Promise<any> {
    return this.http.post(this.configService.getActionUrl(`order-items/${id}/${documentId}/assets`), data)
      .pipe(
        catchError(this.handleError('save image', null))
      )
      .toPromise()
  }

  public saveUploadedImageToUserAssets(data: any): Promise<any> {
    return this.http.post(this.configService.getActionUrl(`assets`), data)
      .pipe(
        catchError(this.handleError('save image', null))
      )
      .toPromise()
  }

  public saveImageToEmbedded(id: number, documentId: number, data: any): Promise<any> {
    return this.http.post(this.configService.getActionUrl(`order-items/${id}/${documentId}/assets/embedded`), data)
      .pipe(
        catchError(this.handleError('save image to embedded', null))
      )
      .toPromise()
  }

  public addOrderItem(data): Promise<any> {
    const observerable = this.http.post(this.configService.getActionUrl(`orders/order-items`), data)
      .pipe(
        catchError(this.handleError('add order item to order', null))
      ).toPromise()
    .then((response: any) => {
      this.getShoppingCart();
    });
    return observerable;
  }

  public removeOrderItem(orderItemId): Promise<any> {
    const observerable = this.http.delete(this.configService.getActionUrl(`order-items/${orderItemId}`))
    .pipe(
      catchError(this.handleError('remove order item from order', null))
    ).toPromise()
    .then((response: any) => {
      this.getShoppingCart();
    });
    return observerable;
  }

  public updateQuantity(orderItemId, data): Promise<any> {
    const observerable = this.http.patch(this.configService.getActionUrl(`order-items/${orderItemId}`), data)
    .pipe(
      catchError(this.handleError('remove order item from order', null))
    ).toPromise()
    .then((response: any) => {
      // this.getShoppingCart();
      // this.userService.getUser();
    });
    return observerable;
  }

  public updateComments(orderItemId, data): Promise<any> {
    const observerable = this.http.patch(this.configService.getActionUrl(`order-items/${orderItemId}`), data)
      .pipe(
        catchError(this.handleError('remove order item from order', null))
      ).toPromise()
      .then((response: any) => {
        this.getShoppingCart();
        this.userService.getUser();
      });
    return observerable;
  }

  public confirmOrder(data): Promise<any> {
    const observerable = this.http.post(this.configService.getActionUrl(`orders/${this.currentShoppingCart.id}/confirm`), data)
    .pipe(
      catchError(this.handleError('remove order item from order', null))
    ).toPromise();
    this.userService.getUser();
    return observerable;
  }

  public confirmDelivery(data): Promise<any> {
    const observerable = this.http.post(this.configService.getActionUrl(`orders/${this.currentShoppingCart.id}/delivery`), data)
      .pipe(
        catchError(this.handleError('remove order item from order', null))
      ).toPromise();
    return observerable;
  }

  public orderAgain(orderId: number): Promise<any> {
    const observerable = this.http.post(this.configService.getActionUrl(`orders/${orderId}/again`), {})
      .pipe(
        catchError(this.handleError('remove order item from order', null))
      ).toPromise();
    return observerable;
  }

  public orderItemAgain(orderItemId: number): Promise<any> {
    const observerable = this.http.post(this.configService.getActionUrl(`order-items/${orderItemId}/again`), {})
      .pipe(
        catchError(this.handleError('remove order item from order', null))
      ).toPromise();
    return observerable;
  }

  public getShoppingCart() {
    this.http.get(this.configService.getActionUrl('shopping-cart')).pipe(
      catchError(this.handleError('get product', null))
    ).subscribe((response: OrdersResponse) => {
      this.currentShoppingCart = response;
      this.currentShoppingCart$.next(response);
    });
  }

  public confirmShoppingCart(data) {
    const observerable = this.http.post(this.configService.getActionUrl(`orders/${this.currentShoppingCart.id}/confirm`), data)
      .pipe(
        catchError(this.handleError('remove order item from order', null))
      ).toPromise();
    return observerable;
  }



  public changeOrderProduct(orderItemId: number, productId) {
    const observerable = this.http.patch(this.configService.getActionUrl(`order-items/${orderItemId}`), [{
      op: 'changeProduct',
      attribute: "productId",
      value: productId
    }])
      .pipe(
        catchError(this.handleError('remove order item from order', null))
      ).toPromise();
    return observerable;
  }

  public getBillingDetails(orderId) {
    const observerable = this.http.get(this.configService.getActionUrl(`orders/${orderId}/details`))
      .pipe(
        catchError(this.handleError('remove order item from order', null))
      ).toPromise();
    return observerable;
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
