<form [ngStyle]="{ 'width': (selectedFiles.length == 0 ? '529px' : '1038px') }">
  <div class="wrapper">
    <div class="files" *ngIf="selectedFiles.length > 0">
      <div class="file" *ngFor="let file of selectedFiles" [ngClass]="file.status ? file.status : 'selected'">
        <div class="file-wrapper">
          <div class="file-icon">PDF</div>
          <div class="file-name">{{file.name}}</div>
          <div class="file-size">{{file.size | bytes}}</div>
          <div class="file-action">
            <button mat-icon-button [disabled]="isProcessed" (click)="unselectFile(file)">
              <mat-icon *ngIf="!isProcessed && !file.status">close</mat-icon>
              <mat-icon *ngIf="isProcessed || file.status == 'successfully'">lock</mat-icon>
            </button>
          </div>
        </div>
        <mat-progress-bar mode="determinate" [value]="file.progress" *ngIf="file.progress > 0 && file.progress < 100"></mat-progress-bar>
      </div>
    </div>
    <div class="form" [ngStyle]="{ 'border-left': (selectedFiles.length == 0 ? 'none' : '1px solid #e3e3e3'), 'padding-bottom': isProcessed ? '45px' : '50px' }">
      <div class="logo">
        <img [src]="themeService.getLogoUrl()">
      </div>
      <div class="login-header" *ngIf="!isProcessed && !isProcessedUploading && !isProcessedMatching">
        <h1>{{themeService.getWelcomeMessage()}}</h1>
        <h4 *ngIf="page && page.text.isShown" [innerHTML]="page.text.text | safe: 'html'"></h4>
      </div>
      <div class="login-header" *ngIf="isProcessed && isProcessedUploading">
        <mat-progress-bar mode="determinate" [value]="totalProgress"></mat-progress-bar>
        <div class="progress-wrapepr"><span>-</span><h1>{{totalProgress}} %</h1><span>-</span></div>

      </div>
      <div class="login-header" *ngIf="(isProcessed && isProcessedUploading)">
        <h1 *ngIf="page.textUploadingStatus.isShown" [innerHTML]="page.textUploadingStatus.title | safe: 'html'"></h1>
        <img [src]="page.imageUploadingStatus.image.url" *ngIf="page.imageUploadingStatus.isShown">
      </div>
      <div class="login-header" *ngIf="(isProcessed && isProcessedMatching)">
        <h1 *ngIf="page.textMatchingStatus.isShown" [innerHTML]="page.textMatchingStatus.title | safe: 'html'"></h1>
        <img [src]="page.imageMatchingStatus.image.url" *ngIf="page.imageMatchingStatus.isShown">
      </div>
      <div class="login-header" *ngIf="(isProcessedThanks)">
        <h1  *ngIf="page.textThanksStatus.isShown" [innerHTML]="page.textThanksStatus.title | safe: 'html'"></h1>
        <img [src]="page.imageThanksStatus.image.url" *ngIf="page.imageThanksStatus.isShown">
      </div>
      <input type="file" #fileDropRef multiple (change)="fileBrowseHandler($event.target.files)" [ngStyle]="{ 'width': '0px', 'height': '0px', 'border': 'none' }">

      <div class="field uploader" *ngIf="!isProcessed && !isProcessedThanks">
        <div class="uploader-area" appFileUploader (onFileDropped)="onFileDropped($event)">
          <div class="button">
            <button mat-fab color="primary" [ngStyle]="{ 'background-color': (themeService.settings$ | async).getButtonColor(1)}" (click)="fileDropRef.click()">
              <mat-icon>backup</mat-icon>
            </button>
          </div>
          <div class="messages">
            <h1>Upload files</h1>
            <h4 (click)="fileDropRef.click()">Or select a folder</h4>
          </div>
        </div>
      </div>
      <div class="field" *ngIf="!isProcessed && !isProcessedThanks">
        <label for="password" appI18nTranslation>Password</label>
        <input type="password" name="password" id="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
      </div>
      <div class="field" *ngIf="!isProcessed && !isProcessedThanks && campaigns && page && page.campaign.isShown">
        <mat-label>{{page.campaign.title}}</mat-label>
        <mat-select id="campaigns" [(ngModel)]="maintag" [ngModelOptions]="{standalone: true}">
          <mat-option [value]="item" *ngFor="let item of campaigns">{{item.name}}</mat-option>
        </mat-select>
      </div>
      <div class="field" *ngIf="error">
        <span class="error">{{error.message}}</span>
      </div>
      <div class="forgot" *ngIf="!isProcessed && !isProcessedThanks">
        <label for="remember" appI18nTranslation>Remember me</label>
        <input type="checkbox" id="remember" [(ngModel)]="remember_me" [ngModelOptions]="{standalone: true}">
      </div>
      <button
        *ngIf="!isProcessedThanks"
        (click)="handleUpload($event)"
        [disabled]="isProcessed || (fileDropRef && fileDropRef.files.length == 0) || password == ''"
        mat-button
        [ngStyle]="{
        'background-color': (themeService.settings$ | async).getButtonColor(1)
        }" appI18nTranslation>
        TRANSFER
      </button>
      <button
        *ngIf="isProcessedThanks"
        (click)="handleBack($event)"
        mat-button
        [ngStyle]="{
        'background-color': (themeService.settings$ | async).getButtonColor(1)
        }" appI18nTranslation>
        BACK
      </button>
    </div>
  </div>
  <div class="progress">
    <mat-progress-bar mode="query" *ngIf="isProcessed" [ngStyle]="{ 'background-color': (themeService.settings$ | async).getButtonColor(1)}"></mat-progress-bar>
  </div>
</form>
