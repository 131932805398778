import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {ThemeService}                          from "../../theme.service";
import {AuthenticationService} from "../../authentication/authentication.service";
import {MatDialog}             from "@angular/material/dialog";
import {SettingService}              from "../../setting.service";
import {OrdersService}               from "../../order-item.service";
import {UserService}                 from "../../user.service";
import {OrdersResponse}              from "../../orders-response";
import {CartPopupComponent}          from "../cart-popup/cart-popup.component";
import {FilemanagerComponent}        from "../filemanager/filemanager.component";
import {HelpdeskComponent}           from "../helpdesk/helpdesk.component";
import {ActivatedRoute, Router} from "@angular/router";
import { DecimalPipe }               from '@angular/common';
import { Subscription }              from "rxjs";
import {TenantCreditSettingsService} from "../../tenant-credit-settings.service";
import { LanguagesService }          from '../../languages.service';
import {UploaderPopupComponent} from "../uploader-popup/uploader-popup.component";
import {EditorPopupComponent} from "../editor-popup/editor-popup.component";

@Component({
  selector: 'global-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() page;

  isOpenedCart = false;
  settings: any;
  user: any = {creditBalance: 0};
  shoppingCart: any = {orderItems: []};
  showGoToHome = false;
  decimalPipe: DecimalPipe;
  creditBalance = '';
  userSubscription: Subscription;
  userCreditBalanceSubscription: Subscription;
  creditSettings: any = {creditValue: 0};

  constructor(
    public route: ActivatedRoute,
    public themeService: ThemeService,
    private authenticateService: AuthenticationService,
    public dialog: MatDialog,
    public settingService: SettingService,
    private userService: UserService,
    private ordersService: OrdersService,
    private creditSettingsService: TenantCreditSettingsService,
    private languagesService: LanguagesService,
    public router: Router
  ) {
    this.decimalPipe = new DecimalPipe('nl-BE');
  }

  ngOnInit() {

    this.getSettings();
    this.settingService.get().subscribe((settings) => {
      this.settings = settings;
      // this.openEditorDialog(new MouseEvent('click'));
    });
    this.userService.getUser().then((user: any) => {
      if (user) {
        this.user = user;
        this.user.showCreditBalance = this.user.creditBalance;
        this.creditBalance = this.decimalPipe.transform(this.user.showCreditBalance, '1.2-2');
      }
    });
    this.userSubscription = this.userService.user$.subscribe((user: any) => {
      this.user = user;
      this.user.showCreditBalance = this.user.creditBalance;
      this.creditBalance = this.decimalPipe.transform(this.user.showCreditBalance, '1.2-2');
    });

    this.userCreditBalanceSubscription = this.userService.updateCreditBalance$.subscribe(() => {
      this.creditBalance = this.decimalPipe.transform(this.user.showCreditBalance, '1.2-2');
    })
    if (this.ordersService.currentShoppingCart) {
      this.shoppingCart = this.ordersService.currentShoppingCart;
      if(this.shoppingCart.orderItems) {
        this.ordersService.getCreditsUsed();
      }
    } else {
      this.ordersService.currentShoppingCart$.subscribe((shoppingCartResponse: OrdersResponse) => {
        if (shoppingCartResponse) {
          this.shoppingCart = shoppingCartResponse;
          if(this.shoppingCart.orderItems) {
            this.ordersService.getCreditsUsed();
          }
        }
      });
    }

    this.showGoToHome = (this.route.snapshot.url.length != 0);

    if(!this.ordersService.currentShoppingCart) {
      this.ordersService.getShoppingCart();
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription && !this.userSubscription.closed) {
      this.userSubscription.unsubscribe();
    }

    if (this.userCreditBalanceSubscription && !this.userCreditBalanceSubscription.closed) {
      this.userCreditBalanceSubscription.unsubscribe();
    }
  }

  async getSettings() {
    this.creditSettings = await this.creditSettingsService.getSettings();
  }

  openCart($event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.isOpenedCart = true;
    const dialogRef = this.dialog.open(CartPopupComponent, {
      width: '715px',
      data: {test: 'test'},
      role: 'alertdialog',
      position: {
        top: '90px',
        right: '100px'
      },
      panelClass: 'cart-popup',
      backdropClass: 'backdrop-transparent'
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isOpenedCart = false;
    });

  }

  checkout(): void {
    this.router.navigate(['checkout', 'cart']);
  }

  logout($event) {
    this.authenticateService.logout();
  }

  openFilemanagerDialog($event) {
    let dialogRef = this.dialog.open(FilemanagerComponent, {
      data: {},
      width: '800px',
      height: '500px',
      panelClass: 'media-library-panel'
    });
  }

  public openHelpDeskDialog(){

    const data = {
      id: 0
    };

    const dialogRef = this.dialog.open(HelpdeskComponent, {
      minWidth: '400px',
      data: data
    });

    return dialogRef.afterClosed();
  }

  openUploadDialog($event: MouseEvent) {
    let dialogRef = this.dialog.open(UploaderPopupComponent, {
      data: {},
      width: '70%',
      height: '600px',
      panelClass: 'media-library-panel',
      closeOnNavigation: true,
      disableClose: true
    });

    dialogRef.backdropClick().subscribe(($event) => {
      dialogRef.componentInstance.close()
    });
  }


  openEditorDialog($event: MouseEvent) {
    let dialogRef = this.dialog.open(EditorPopupComponent, {
      data: {
        orderItemId: 3110
      },
      width: '80%',
      height: '800px',
      panelClass: 'editor-popup-panel'
    });
  }
}
