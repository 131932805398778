import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {ThemeService} from "../../../theme.service";

@Component({
  selector: 'app-errors-dialog',
  templateUrl: './errors-dialog.component.html',
  styleUrls: ['./errors-dialog.component.sass']
})
export class ErrorsDialogComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<ErrorsDialogComponent>,
               public themeService: ThemeService,
               @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
