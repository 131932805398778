export class AuthenticateCommand {
    public email: string;
    public password: string;
    public remember: boolean;
    constructor(email: string, password: string) {
        this.email = email;
        this.password = password;
    }

    getEmail(): string {
        return this.email;
    }

    getPassword(): string {
        return this.password;
    }
}
