<mat-toolbar [ngStyle]="{ 'border-bottom' : page && page.image && page.image.isFullWidth ? 'none' : '2px solid #e3e3e3' }">
  <div class="logo">
    <img [src]="(themeService.settings$ | async).getLogoUrl()" [alt]="themeService.settings.name">
  </div>
  <div class="menu">
    <mat-list>
      <mat-list-item>
        <a mat-button routerLink="/" appEditorChangesHoverStyle *ngIf="showGoToHome"
                        [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <span appI18nTranslation>Go to home page</span></a>
      </mat-list-item>
    </mat-list>

    <div class="list">

      <div class="item">
        <app-locale-selector></app-locale-selector>
      </div>

      <div class="item" *ngIf="page && page.sub_title.isShownUploadButton">
        <button mat-button [disableRipple]="false" (click)="openUploadDialog($event)">
          <span>{{page.sub_title.uploadButtonText}}</span>
          <mat-icon class="material-icons-outlined">cloud_upload</mat-icon>
        </button>
      </div>

<!--      <mat-list-item>-->
<!--        <button mat-button [disableRipple]="false" (click)="openEditorDialog($event)">-->
<!--          <mat-icon class="material-icons-outlined">folder_open</mat-icon>-->
<!--          <span appI18nTranslation>Editor</span>-->
<!--        </button>-->
<!--      </mat-list-item>-->

      <div class="item" *ngIf="settings && settings.settings.isShowMedialibrary">
        <button mat-button [disableRipple]="false" (click)="openFilemanagerDialog($event)">
          <span appI18nTranslation>Media library</span>
          <mat-icon class="material-icons-outlined">folder_open</mat-icon>
        </button>
      </div>

      <div class="item my-wallet" *ngIf="creditSettings.enable">
        <span>credits</span>
        <mat-icon class="material-icons-outlined" [matBadge]="creditBalance">account_balance_wallet
        </mat-icon>
      </div>

      <div *ngIf="settings && settings.tenantType == 'webshop'" class="item icon shopping-cart">
        <button mat-button [disableRipple]="false" (click)="checkout()">
          <mat-icon class="material-icons-outlined" [matBadge]="shoppingCart.orderItems ? shoppingCart.orderItems.length : 0">shopping_cart</mat-icon>
        </button>
      </div>

      <div class="item icon">
        <a mat-button (click)="openHelpDeskDialog()" [disableRipple]="false" >
          <mat-icon class="material-icons-outlined">live_help</mat-icon>
        </a>
      </div>

      <div class="item icon">
        <button mat-button [disableRipple]="false" [matMenuTriggerFor]="menu">
          <mat-icon class="material-icons-outlined">person</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <a mat-menu-item href="/profile/dashboard"><span appI18nTranslation>Profile</span></a>
          <a mat-menu-item href="/profile/orders"><span appI18nTranslation>Orders history</span></a>
          <button mat-menu-item (click)="logout($event)"><span appI18nTranslation>Logout</span></button>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>

