import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSnackBar} from "@angular/material";

@Component({
  selector: 'app-image-loading-detector',
  templateUrl: './image-loading-detector.component.html',
  styleUrls: ['./image-loading-detector.component.sass']
})
export class ImageLoadingDetectorComponent implements OnInit {

  @Input() image;
  @Output() wasLoaded = new EventEmitter();

  constructor(private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  loadHandler() {
    this.wasLoaded.emit(true);
  }

  onErrorHandler() {
    this.showNotification();
    this.wasLoaded.emit(false);
  }

  private showNotification() {
    const message = `Sorry,but some images weren't loaded.`;

    this.snackBar.open(message, '', { horizontalPosition: 'right', duration: 2000 });

  }

}
