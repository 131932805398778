<div class="container">
  <div class="header">
    <div class="avatar">
      <mat-icon
        [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">
        call
      </mat-icon>
    </div>
    <div class="title">
      <h1 appI18nTranslation>Contact information</h1>
    </div>
  </div>

  <div class="body">
    <form class="send-mail-customer-form" [formGroup]="form">
      <div class="form-field">
        <label for="firstname" appI18nTranslation>First name</label>
        <input type="text" id="firstname" formControlName="firstName">
      </div>
      <div class="form-field">
        <label for="lastname" appI18nTranslation>Last name</label>
        <input type="text" id="lastname" formControlName="lastName">
      </div>
      <div class="form-field">
        <label for="phone" appI18nTranslation>Phone number</label>
        <input type="text" id="phone" formControlName="phoneNo">
      </div>
      <div class="form-field">
        <label for="email" appI18nTranslation>Email</label>
        <input type="text" id="email" formControlName="email">
      </div>
      <div class="form-field">
        <label for="reason" appI18nTranslation>Contact reason</label>
        <textarea
          placeholder="Explain your question or problem here in a few lines"
          appI18nTranslation
          type="text"
          id="reason"
          formControlName="message">
        </textarea>
      </div>

      <div class="button-row">
        <button mat-stroked-button mat-dialog-close class="cancel" appI18nTranslation>Cancel</button>
        <button
          mat-button
          [disabled]="form.invalid"
          class="send-mail"
          [ngStyle]="{'background': (themeService.settings$ | async).getButtonColor(1), 'border-color': (themeService.settings$ | async).getButtonColor(0.2)}"
          (click)="send()" appI18nTranslation>
          Send
        </button>
      </div>
    </form>
  </div>
</div>
