import { Component, OnInit }      from '@angular/core';
import { AuthenticationService }  from '../../authentication/authentication.service';
import { ThemeService }           from '../../theme.service';
import { ClientPageService }      from '../../client-page.service';
import { AuthenticateCommand }    from '../commands/autheticate.command';
import { Subscription }           from 'rxjs';
import { AuthenticationResponse } from '../../authentication/authentication-response';
import {NotificationService}      from "../../global/notification.service";
import {Router}                   from "@angular/router";
import {UserService}              from "../../user.service";
import {SettingService}           from "../../setting.service";
import { ConfigService }          from '../../config.service';

@Component({
  selector: 'fu-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.sass']
})
export class LoginFormComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,
              public themeService: ThemeService,
              public clientPageService: ClientPageService,
              private notification: NotificationService,
              private userService: UserService,
              private router: Router,
              private settingService: SettingService,
              private config: ConfigService
  ) { }

  page: any;
  data: AuthenticateCommand = new AuthenticateCommand('', '');
  loginSubscription: Subscription;
  isProcessed = false;
  error: any;
  settings: any;
  isPasswordVisible = false;

  ngOnInit() {
    this.themeService.get();

    this.clientPageService.get('login-page').then((response: any) => {
      this.page = response.page;
    });

    this.settingService.get().subscribe((settings: any) => {
      this.settings = settings;
    });

    // window.addEventListener('keyup', this.loginByEnter);
  }

  // loginByEnter($event) {
  //   if ($event.keyCode === 13) {
  //     this.handleLogin($event)
  //   }
  // }

  ngOnDestroy() {
    // window.removeEventListener('keyup', this.loginByEnter);
  }

  handleLogin($event) {
    this.isProcessed = true;
    localStorage.removeItem('token');
    this.loginSubscription = this.authenticationService.login(this.data)
    .subscribe((data: AuthenticationResponse) => {
        this.isProcessed = false;
        localStorage.setItem('token', data.token);
        this.userService.currentRequest = null;
        this.userService.isLocked = false;
        this.userService.getUser().then(() => {
          localStorage.removeItem('isCurrentLocaleFromLocalStorage');
          this.router.navigate(['']);
        });

      },
      error => {
        this.isProcessed = false;
        this.error = error.error.error;
      });
  }

}
