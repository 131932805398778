import { Injectable }      from '@angular/core';
import { ConfigService }   from './config.service';
import { HttpClient }      from '@angular/common/http';
import { Translation }     from './translation';
import { Subject }         from 'rxjs';
import { BehaviorSubject } from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  get translations(): Translation[] {
    return this._translations;
  }

  private _translations: Translation[] = [];
  public translationsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) { }

  list(languageId): Promise<any> {
    return this.http.get(this.config.getLanguagesUrl(`translation/${languageId}`)).toPromise()
      .then((response: any) => {
        this._translations = response.data;
        this.translationsLoaded$.next(true);
      });
  }

  translate(source) {
      const translation = this.translations.find((t) => {
        return t.source.toLowerCase() === source.toLowerCase();
      });

      return translation &&  translation.target != '' ? translation.target : source;
  }
}
