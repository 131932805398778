<form>
  <div class="logo">
    <img [src]="themeService.getLogoUrl()">
  </div>
  <div class="login-header">
    <h1>{{themeService.getWelcomeMessage()}}</h1>
    <h4 *ngIf="page && page.text.isShown" [innerHTML]="page.text.text | safe: 'html'"></h4>
  </div>
  <div class="field">
    <label for="name" appI18nTranslation>Name</label>
    <input type="name" name="name" id="name" [(ngModel)]="data.name" [ngModelOptions]="{standalone: true}">
  </div>
  <div class="field">
    <label for="email" appI18nTranslation>Email</label>
    <input type="email" name="email" id="email" [(ngModel)]="data.email" [ngModelOptions]="{standalone: true}">
  </div>
  <div class="field">
    <label for="password" appI18nTranslation>Password</label>
    <input type="password" name="password" id="password" [(ngModel)]="data.password" [ngModelOptions]="{standalone: true}">
  </div>
  <div class="field">
    <label for="confirm_password" appI18nTranslation>Confirm password</label>
    <input type="password" name="confirm_password" id="confirm_password" [(ngModel)]="data.confirm_password" [ngModelOptions]="{standalone: true}">
  </div>
  <div class="field" *ngIf="error">
    <span class="error">{{error.message}}</span>
  </div>
  <div class="forgot">
    <span appI18nTranslation>Already have an account?</span>
    <a routerLink="/login" appI18nTranslation>Log in</a>
  </div>
  <button
    (click)="handleRegistration($event)"
    [disabled]="isProcessed"
    mat-button
    [ngStyle]="{
        'background-color': (themeService.settings$ | async).getButtonColor(1)
        }" appI18nTranslation>
    Submit
  </button>
  <div class="progress" [ngStyle]="{'margin-top': isProcessed ? '45px' : '50px'}">
    <mat-progress-bar mode="query" *ngIf="isProcessed"></mat-progress-bar>
  </div>
</form>
