import { Component, Input, OnInit } from '@angular/core';
import { ThemeService }             from '../../theme.service';
import { ClientPage }               from '../../client-page';

@Component({
  selector: 'app-deadline',
  templateUrl: './deadline.component.html',
  styleUrls: ['./deadline.component.sass']
})
export class DeadlineComponent implements OnInit {

  @Input() page: ClientPage;

  date: Date = new Date();

  constructor(public themeService: ThemeService) { }

  ngOnInit() {
  }

}
