<div class="container" *ngIf="order" [ngClass]="{'hide-title': page && page.orderItems && !page.orderItems.isShown}">
  <div
    class="point"
    *ngIf="page && page.orderItems && page.orderItems.isShown"
    [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}">
  </div>
  <h1
    *ngIf="page && page.orderItems && page.orderItems.isShown"
    [innerHTML]="page.orderItems.title | safe: 'html'">
  </h1>
  <div class="order-items" *ngIf="order.orderItems && order.orderItems.length">
    <mat-list>
      <mat-list-item [class]="[orderItem.status]" *ngFor="let orderItem of order.orderItems">
        <div class="image">
          <div class="quantity">{{orderItem.quantity}} <span appI18nTranslation>Pieces</span></div>
          <img [src]="findPreview(orderItem)" [alt]="orderItem.name" (click)="showProductImage(orderItem)">
        </div>
        <div class="description">
          <h3>{{orderItem.name}}</h3>
          <h5 appI18nTranslation>Order item status:</h5>
          <div class="status" *ngIf="statuses && statuses.length > 0">
            <div [class]="orderItem.status"
                 [style.backgroundColor]="themeService.getBackgroundColor(getStatus(orderItem.status).color)"
                 [style.color]="getStatus(orderItem.status).color"
            >{{getStatus(orderItem.status).text | titlecase}}</div>
          </div>
        </div>
        <a mat-button *ngIf="!isPlaceholder(orderItem) && !isWebshopItem(orderItem) && orderItem.status == 'editing'" (click)="goToEditOrder(orderItem)" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}" appI18nTranslation>Start editor</a>
        <a mat-button *ngIf="!isPlaceholder(orderItem) && !isWebshopItem(orderItem) && orderItem.status == 'confirmed'" routerLink="editor/proof/{{orderItem.id}}" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1), 'border-color': (themeService.settings$ | async).getButtonColor(0.25)}" appI18nTranslation>View order</a>
      </mat-list-item>
    </mat-list>
  </div>
</div>
