import { Injectable } from '@angular/core';
import {Paho} from "ng2-mqtt/mqttws31";
import {ConfigService} from "../../config.service";

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  private _client: Paho.MQTT.Client;

  private assetId: number;

  constructor(
    private config: ConfigService
  ) {
  }


  createConnection(assetid: number) {
    this.assetId = assetid;
    console.log('Create connection', this.config.getWsUrl());
    this._client = new Paho.MQTT.Client(this.config.getWsUrl(), 15676, "/ws", "clientId" + Math.random() * 100);
    // this._client = new Paho.MQTT.Client('18.195.152.229', 15676, "/ws", "clientId" + Math.random() * 100);
    this._client.onConnectionLost = (responseObject: Object) => {
      console.log('Connection lost.');
    };

    // this._client.onMessageArrived = (message: Paho.MQTT.Message) => {
    //   console.log('Message arrived.', message.payloadString);
    //   const body = JSON.parse(message.payloadString);
    //
    // };

    this._client.connect({
      userName: 'mqtt-user',
      password: 'mqtt-user',
      useSSL: true,
      onSuccess: this.onConnected.bind(this),
      onFailure: this.onConnectionFailure.bind(this)
    });

    return this._client;
  }



  private onConnectionFailure(message):void {
    console.log(message);
    console.log("CONNECTION FAILURE - " + message.errorMessage);
  }

  private onConnected():void {
    this._client.subscribe('/printlink/assets.validate/' + this.assetId, {qos: 1})
    console.log('Connected to broker.');
  }
}
