import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef }            from '@angular/material';
import { ThemeService }                                        from '../../theme.service';
import { OrdersService }                                       from '../../order-item.service';
import { Router }                                              from '@angular/router';
import { OrdersResponse }                                      from '../../orders-response';
import { Subscription }                                        from 'rxjs';
import { TenantCreditSettingsService }                         from '../../tenant-credit-settings.service';
import { UserService }                                         from '../../user.service';
import { CreditsGroupsService }                                from '../../credits-groups.service';
import { OrderItem }                                           from '../../order-item';
import * as _ from "lodash";
import {ProductgroupService} from "../../productgroup.service";

@Component({
  selector: 'global-cart-popup',
  templateUrl: './cart-popup.component.html',
  styleUrls: ['./cart-popup.component.sass']
})
export class CartPopupComponent implements OnInit, OnDestroy, AfterViewInit {

  order: any;
  orderSubscription: Subscription;
  processing = false;

  user: any = {creditBalance: 0};
  creditSettings: any = {creditValue: 0};
  creditUsed = 0;
  totalPrice = 0;

  creditGroups: any = [];
  px_ratio: number;
  quantityTimeOutIncrease: number;
  quantityTimeOutReduce: number;
  productGroups: any;

  constructor(
    public dialogRef: MatDialogRef<CartPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public themeService: ThemeService,
    public ordersService: OrdersService,
    public router: Router,
    private creditSettingsService: TenantCreditSettingsService,
    private userService: UserService,
    private productgroupService: ProductgroupService,
    private creditGroupService: CreditsGroupsService
  ) {}

  ngOnInit() {
    this.processing = true;
    this.themeService.get();
    this.ordersService.getShoppingCart();
    this.getSettings();
    this.productgroupService.list()
      .then((response: any) => {
        this.productGroups = response.data;
      })
    if (this.ordersService.currentShoppingCart) {
      this.order = this.ordersService.currentShoppingCart;
      this.updateTotalData();
    }
    this.orderSubscription = this.ordersService.currentShoppingCart$.subscribe((order: OrdersResponse) => {
      this.order = order;
      this.updateTotalData();
      this.processing = true;
    });

    this.px_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;

    document.documentElement.style.setProperty('--pixel-ratio', this.px_ratio + '');
    window.addEventListener('resize', ($event) => {
      this.isZooming();
    });
  }

  getProductgroup(productgroupId: number) {
    return this.productGroups.find((item) => {
      return item.id == productgroupId;
    })
  }

  isZooming() {
    let newPx_ratio = window.devicePixelRatio || (window.screen.availWidth / document.documentElement.clientWidth);
    newPx_ratio = newPx_ratio / 2;
    if (newPx_ratio != this.px_ratio) {
      this.px_ratio = newPx_ratio;
      document.documentElement.style.setProperty('--pixel-ratio', this.px_ratio + '');
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    this.orderSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    (document.querySelector('.mat-dialog-container') as HTMLElement).style.borderColor = this.themeService.getButtonColor(1);
  }

  getCreditGroup(group_id) {
    if (!group_id || this.creditGroups[group_id]) {
      return;
    }
    this.creditGroups[group_id] = this.creditGroupService.get(group_id)
      .then((response: any) => {
        this.creditGroups[response.group.id] = response.group;
      });
  }

  async getSettings() {
    this.creditSettings = await this.creditSettingsService.getSettings();
  }

  async updateTotalData() {
    await this.getSettings();
    this.user = await this.userService.getUser();
    this.order.orderItems.map((item: OrderItem) => {
      this.getCreditGroup(item.creditGroupId);
    });
    const creditCost = this.order.orderItems
      .filter(orderItem => orderItem.settings && orderItem.settings.buyByCredit)
      .reduce((creditCostS: any, orderItem: any, index, orderItems) => {
          return creditCostS;
        }, 0
      );

    const creditCostForConfirmed = this.order.orderItems
      .filter(orderItem => orderItem.settings && orderItem.settings.buyByCredit)
      .reduce((creditCostForConfirmedS: any, orderItem: any, index, orderItems) => {
          return creditCostForConfirmedS + orderItem.cost_on_credits;
        }, 0
      );


    if (creditCost >= this.user.creditBalance) {
      this.creditUsed = this.user.creditBalance + creditCostForConfirmed;
      this.totalPrice = this.order.price - (this.creditUsed * this.creditSettings.creditValue);
    } else {
      this.creditUsed = creditCost + creditCostForConfirmed;
      this.totalPrice = this.order.price - (this.creditUsed * this.creditSettings.creditValue);
    }
    this.processing = true;
  }

  close(): void {
    this.dialogRef.close();
  }

  checkout(): void {
    this.close();
    this.router.navigate(['checkout', 'cart']);
  }

  remove(orderItem): void {
    this.ordersService.removeOrderItem(orderItem.id)
      .then((response) => {
        this.ordersService.getShoppingCart();
      });
  }

  manualInput(item) {
    if (this.quantityTimeOutReduce) {
      clearTimeout(this.quantityTimeOutReduce);
    }

    if (item.settings && item.quantity < item.settings.minInput) {
      item.quantity = item.settings.minInput;
    }

    if (item.settings && item.settings.maxInput != 0 && item.quantity > item.settings.maxInput && !item.settings.unlimited_stock) {
      item.quantity = item.settings.maxInput;
    }
    this.quantityTimeOutReduce = window.setTimeout(() => {
        this.processing = false;
        this.ordersService.updateQuantity(item.id, [{op: 'updateQuantity', attribute: 'quantity', value: item.quantity}]);
      },
      250);
  }

  reduce(item) {
    if (this.quantityTimeOutReduce) {
      clearTimeout(this.quantityTimeOutReduce);
    }
    if (!item.settings) {
      item.quantity--;
    } else {
      item.quantity -= item.settings.addByValue;
    }

    if (item.settings && item.quantity < item.settings.minInput) {
      item.quantity = item.settings.minInput;
    }
    this.quantityTimeOutReduce = window.setTimeout(() => {
        console.log(item);
        this.processing = false;
        this.ordersService.updateQuantity(item.id, [{op: 'updateQuantity', attribute: 'quantity', value: item.quantity}]);
      },
      250);
  }

  increase(item) {
    if (this.quantityTimeOutIncrease) {
      window.clearTimeout(this.quantityTimeOutIncrease);
    }
    if (!item.settings) {
      item.quantity++;
    } else {
      item.quantity += item.settings.addByValue;
    }

    if (item.settings && item.settings.maxInput != 0 && item.quantity > item.settings.maxInput && !item.settings.unlimited_stock) {
      item.quantity = item.settings.maxInput;
    }
    this.quantityTimeOutIncrease = window.setTimeout(() => {
        console.log(item);
        this.processing = false;
        this.ordersService.updateQuantity(item.id, [{op: 'updateQuantity', attribute: 'quantity', value: item.quantity}]);
      },
      250);

  }

  isWebshopItem(item: OrderItem) {
    const documents = _.filter(item.documents, (document) => {
      return document.type != 'webshop_item';
    });
    return documents.length > 0 ? false : true;
  }

  isMinAmountOfGroup(item: OrderItem) {
    if (!this.creditGroups || !this.creditGroups[item.creditGroupId] || !this.creditGroups[item.creditGroupId]['products']) {
      return false;
    }
    const products = this.creditGroups[item.creditGroupId].products.filter((product: any) => {
      return product.productId == item.product && item.quantity > product.min;
    });
    return products.length > 0;
  }

  isMaxAmountOfGroup(item: OrderItem) {
    if (!this.creditGroups || !this.creditGroups[item.creditGroupId] || !this.creditGroups[item.creditGroupId]['products']) {
      return false;
    }
    const products = this.creditGroups[item.creditGroupId].products.filter((product: any) => {
      return product.productId == item.product && item.quantity < product.max;
    });

    return products.length > 0;
  }

  public isShowCurrencyPrice(item: any) {
    return item.settings && item.settings.shownPrice;
  }

  public isShowCreditsPrice(item: any) {
    return item.settings && item.settings.shownCredit && this.creditSettings.enable;
  }

  public isDisabledReduce(item: any) {
    return (item.type == 'credit' && !this.isMinAmountOfGroup(item))
      || !this.processing
      || item.quantity <= 1;
  }

  public isDisabledIncrease(item: any) {
    return !this.processing || (item.quantity == item.inStock && !item.isUnlimitedStock) || (item.type == 'credit' && !this.isMaxAmountOfGroup(item));
  }

  public isShowRemoveButton(item: any) {
    return (item.type == 'credit' && (this.creditGroups && this.creditGroups[item.creditGroupId] && !this.creditGroups[item.creditGroupId].isLockedProducts)) || item.type != 'credit';
  }
}

