<div class="progress-bar-container">
  <mat-progress-bar mode="query" *ngIf="isProcessed"></mat-progress-bar>
</div>
<div mat-dialog-title class="uploader-dialog-title">
  <button mat-icon-button aria-label="Close uploader window" disableRipple="true" [disabled]="isProcessed" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <div class="container">
    <div class="titles">
<!--      <div class="title">TYPE</div>-->
<!--      <div class="tag-title">TAG</div>-->
      <div class="title" *ngIf="dialogSettings && dialogSettings.title.isShown" [innerHTML]="dialogSettings.title.title | safe:'html'"></div>
    </div>
    <div class="tags">
      <div class="tag" *ngFor="let chiliTag of chiliTags">
<!--        <div class="tag-type">{{chiliTag.type | uppercase}}</div>-->
        <div class="tag-name" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}">{{chiliTag.name | uppercase}}</div>
        <div class="tag-actions" [ngSwitch]="chiliTag.type">
          <div class="tag-text" *ngSwitchCase="'text'">
            <div class="tag-text-wrapper">
              <input type="text" [(ngModel)]="chiliTag.value">
            </div>
          </div>
          <div class="tag-textarea" *ngSwitchCase="'textarea'">
            <div class="tag-textarea-wrapper">
              <textarea [(ngModel)]="chiliTag.value"></textarea>
            </div>
          </div>
          <div class="tag-image" *ngSwitchCase="'image'">
            <div class="uploaded-list-wrapper">
              <div class="image-container">
                <button mat-icon-button class="image-uploader-wrapper" [matMenuTriggerFor]="fileSelectMenu">
                  <mat-icon class="material-icons-outlined">cloud_upload</mat-icon>
                </button>
                <mat-menu #fileSelectMenu="matMenu">
                  <button mat-menu-item *ngIf="themeService.settings.isShowMedialibrary" (click)="openMediaLibraryDialog($event, chiliTag)" appI18nTranslation>
                    Choose from media library
                  </button>
                  <button mat-menu-item>
                    <span appI18nTranslation>Upload from your computer</span>
                    <input #image type="file" class="upload-input" (change)="fileChanged($event, image, chiliTag)" accept="image/*, .pdf"/>
                  </button>
                </mat-menu>
              </div>

              <div class="uploaded-list" *ngIf="(chiliTag.uploadedImages && chiliTag.uploadedImages.length) || chiliTag.isShowPreloader">
                <div *ngFor="let image of chiliTag.uploadedImages; let i = index">
                  <div
                    class="image-container"
                    (click)="selectImage(image, chiliTag)"
                    [ngStyle]="{'border-color': chiliTag.selectedImage && image.id == chiliTag.selectedImage.id ? (themeService.settings$ | async).getButtonColor(1): '#e0e0e0', 'border-width': chiliTag.selectedImage && image.id == chiliTag.selectedImage.id ? '3px': '1px' }">
                    <img [src]="image.previewLink" *ngIf="image.previewLink && image.imageIsLoaded">
                    <div class="image-status" *ngIf="image.mime == 'application/pdf' && image.previewLink && image.imageIsLoaded" [ngClass]="[image.status]">{{image.status === 'to_be_checked'? 'to be checked': image.status}}</div>
                    <div class="image-progress" *ngIf="image.previewLink && !image.imageIsLoaded">
                      <mat-progress-spinner mode="indeterminate" diameter="60"></mat-progress-spinner>
                    </div>
                    <app-image-loading-detector
                      *ngIf="image.previewLink && !image.imageIsLoaded"
                      [image]="image.previewLink"
                      (wasLoaded)="loadHandler($event, image)">
                    </app-image-loading-detector>
                  </div>
                </div>
                <div *ngIf="chiliTag.isShowPreloader">
                  <div class="image-container">
                    <div class="image-progress">
                      <mat-progress-spinner mode="indeterminate" diameter="60"></mat-progress-spinner>
<!--                      <img src="/assets/icons/loading.webp">-->
                    </div>
                  </div>
                </div>
              </div>
              <div class="uploaded-actions">
                <button mat-button class="remove-button" *ngIf="selectedChiliTag && selectedChiliTag == chiliTag" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1), 'border-color': (themeService.settings$ | async).getButtonColor(0.2)}" (click)="removeAssets(chiliTag)" appI18nTranslation>Remove image</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
