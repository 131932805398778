import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {Observable} from "rxjs/Observable";
import {of} from "rxjs/internal/observable/of";
import {ConfigService} from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class ProductgroupService {

  constructor(
      private http: HttpClient,
      private config: ConfigService
  ) { }

  list() {
    return this.http.get(this.config.getActionUrl('productgroups'))
        .pipe(
            catchError(this.handleError('save product', 'error'))
        )
        .toPromise();
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
