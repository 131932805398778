<div class="frame-wrapper">
  <iframe
    id="chili-iframe"
    (load)="iFrameLoaded()"
    [src]="iframeUrl"
    class="iframe-chili"
    #chili>
  </iframe>
  <div class="zoom-toolbar" *ngIf="activeFrame" [style.top.px]="zoomToolbarStyle.top" [style.left.px]="zoomToolbarStyle.left">
    <button mat-stroked-button (click)="zoomIn()" [ngStyle]="{
        'background-color': (themeService.settings$ | async).getButtonColor(1),
        'color': '#ffffff'
        }">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-stroked-button (click)="zoomOut()" [ngStyle]="{
        'background-color': (themeService.settings$ | async).getButtonColor(1),
        'color': '#ffffff'
        }">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
</div>
