import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.sass']
})
export class AlertDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AlertDialogComponent>
  ) {}

  ngOnInit() {
    document.documentElement.style.setProperty('--alert-background-color', this.data.backgroundColor);
    document.documentElement.style.setProperty('--alert-text-color', this.data.textColor);
    document.documentElement.style.setProperty('--promt-background-color', this.data.backgroundColor);
    document.documentElement.style.setProperty('--promt-text-color', this.data.textColor);
  }

  close() {
    this.dialogRef.close({ ok: true});
  }

}
