import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {DetailsView, FileManager, NavigationPane, Toolbar} from "@syncfusion/ej2-filemanager";
import {SettingService} from "../../setting.service";
import {UserService} from "../../user.service";

@Component({
  selector: 'app-media-library-dialog',
  templateUrl: './media-library-dialog.component.html',
  styleUrls: ['./media-library-dialog.component.sass']
})
export class MediaLibraryDialogComponent implements OnInit {

  public filemanagerInstance: FileManager;
  public hostUrl: string = '/api/fs/';
  public settings: any;
  public user: any;

  constructor(public dialogRef: MatDialogRef<MediaLibraryDialogComponent>, private settingService: SettingService, private usersService: UserService) { }

  ngOnInit() {
    this.usersService.getUser().then((user: any) => {
      this.user = user;

      this.settingService.get().subscribe((response:any) => {
        this.settings = response;
        FileManager.Inject(Toolbar, NavigationPane, DetailsView);

        const toolbarSettingsItems = ['Download', 'SortBy', 'Refresh', 'Selection', 'View', 'Details'];
        let layoutContextMenuSettings = ['SortBy', 'View', 'Refresh', '|', 'Paste', '|'];

        if(this.user.allowCreateFolder) {
          layoutContextMenuSettings.push('NewFolder');
        }

        if(this.user.allowUpload) {
          toolbarSettingsItems.push('Upload');
          layoutContextMenuSettings.push('Upload');
        }

        if(this.user.allowCreateFolder || this.user.allowUpload) {
          layoutContextMenuSettings.push('|');
        }

        layoutContextMenuSettings.push('Details', '|', 'SelectAll');

        this.filemanagerInstance = new FileManager({
          uploadSettings: {
            allowedExtensions: '.jpg,.jpeg,.png,.pdf'
          },
          ajaxSettings: {
            url: this.hostUrl + 'file-operations/' + response.tenantName,
            downloadUrl: this.hostUrl + 'download/' + response.tenantName,
            uploadUrl: this.hostUrl + 'upload/' + response.tenantName,
            getImageUrl: this.hostUrl + 'get-image/' + response.tenantName,
          },
          contextMenuSettings: {
            file: ['Open','|', 'Cut', 'Copy', '|', 'Delete', 'Rename', '|', 'Details'],
            folder: ['Open','|', 'Cut', 'Copy', 'Paste', '|', 'Delete', 'Rename', '|', 'Details'],
            layout: layoutContextMenuSettings,
            visible: true,
          },
          toolbarSettings: { items: toolbarSettingsItems, visible: true},
          height: '432px',
          path: '/' + this.user.name + '/'
        });

        this.filemanagerInstance.appendTo('#filemanager');
      });
    });


  }

  applyDialog() {
    const files = [];
      this.filemanagerInstance.getSelectedFiles().forEach((value: any) => {
        files.push(value['_fm_imageUrl']);
    });
    this.dialogRef.close(files);
  }

  closeDialog() {
    this.dialogRef.close([]);
  }

}
