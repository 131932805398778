import { Injectable }         from '@angular/core';
import { HttpClient }         from '@angular/common/http';
import { ClientPage }         from './client-page';
import { ConfigService }      from './config.service';
import { ClientPageResponse } from './client-page-response';
import { VariablesService }   from './global/variables.service';
import { Location }           from './location';
import { Deadline }           from './deadline';
import { Router }             from '@angular/router';
import {Delivery} from "./delivery";

@Injectable({
  providedIn: 'root'
})
export class ClientPageService {

  clientPage: ClientPage;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private variablesService: VariablesService,
    private router: Router
    ) {
    this.clientPage = new ClientPage();
  }

  get(pageCode: string): Promise<Object> {




    const promise = this.http.get(this.configService.getActionUrl('pages/code/' + pageCode)).toPromise();
    promise.then((pageResoponse: ClientPageResponse) => {
      if (
        this.router.url == '/uploader'
      ) {
        document.documentElement.style.setProperty('--alert-background-color', pageResoponse.page.alert.backgroundColor );
        document.documentElement.style.setProperty('--alert-content-background-color', pageResoponse.page.alert.backgroundColor );
        return pageResoponse;
      }

      if (
        this.router.url.indexOf('/reset') == 0
      ) {
        return pageResoponse;
      }

      this.variablesService.replaceUserVariables(pageResoponse.page.title.title)
        .then((text: string) => {
          pageResoponse.page.title.title = text;
        });
      this.variablesService.replaceUserVariables(pageResoponse.page.text.text)
        .then((text: string) => {
          pageResoponse.page.text.text = text;
        });
      this.variablesService.replaceUserVariables(pageResoponse.page.sub_title.sub_title)
        .then((text: string) => {
          pageResoponse.page.sub_title.sub_title = text;
        });
      this.variablesService.replaceUserVariables(pageResoponse.page.locations.title)
        .then((text: string) => {
          pageResoponse.page.locations.title = text;
        });
      this.variablesService.replaceUserVariables(pageResoponse.page.deliveries.title)
        .then((text: string) => {
          pageResoponse.page.deliveries.title = text;
        });
      this.variablesService.replaceUserVariables(pageResoponse.page.deadlines.title)
        .then((text: string) => {
          pageResoponse.page.deadlines.title = text;
        });
      pageResoponse.page.locations.items.forEach((item: Location) => {
        this.variablesService.replaceUserVariables(item.description)
          .then((text: string) => {
            item.description = text;
          });
      });
      pageResoponse.page.deadlines.items.forEach((item: Deadline) => {
        this.variablesService.replaceUserVariables(item.description)
          .then((text: string) => {
            item.description = text;
          });
      });
      pageResoponse.page.deliveries.items.forEach((item: Delivery) => {
        this.variablesService.replaceUserVariables(item.description)
          .then((text: string) => {
            item.description = text;
          });
        this.variablesService.replaceUserVariables(item.title)
          .then((text: string) => {
            item.title = text;
          });
      });

      if (pageResoponse.page.alert.isShown) {
        this.variablesService.replaceUserVariables(pageResoponse.page.alert.text)
          .then((text: string) => {
            pageResoponse.page.alert.text = text;
          });

        this.variablesService.replaceUserVariables(pageResoponse.page.alert.buttonNoText)
          .then((text: string) => {
            pageResoponse.page.alert.buttonNoText = text;
          });

        this.variablesService.replaceUserVariables(pageResoponse.page.alert.buttonYesText)
          .then((text: string) => {
            pageResoponse.page.alert.buttonYesText = text;
          });

        document.documentElement.style.setProperty('--alert-background-color', pageResoponse.page.alert.backgroundColor );
        document.documentElement.style.setProperty('--alert-content-background-color', pageResoponse.page.alert.backgroundColor );
      }

      return pageResoponse;
    });
    return promise;
  }
}
