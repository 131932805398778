import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ThemeService }                        from '../../theme.service';
import { OrdersService }                       from '../../order-item.service';
import { OrdersResponse }                      from '../../orders-response';
import { ClientPage }                          from '../../client-page';
import {StepsService}                          from "../../editor/steps/steps.service";
import {ActivatedRoute, Router}                from "@angular/router";
import * as _                                  from 'lodash';
import { Subscription }                        from 'rxjs';
import {StatusService} from "../../global/status.service";
import {OrderItem} from "../../order-item";
import {ImagePopoverDialogComponent} from "../catalog/image-popover-dialog/image-popover-dialog.component";
import {MatDialog} from "@angular/material";

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.sass']
})
export class OrderItemsComponent implements OnInit, OnDestroy {

  order: OrdersResponse;
  orderSubscription: Subscription;
  statuses: any = [];
  @Input() page: ClientPage;

  constructor(
    public themeService: ThemeService,
    private ordersService: OrdersService,
    private stepsService: StepsService,
    private statusService: StatusService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    if (this.ordersService.currentShoppingCart) {
      this.order = this.ordersService.currentShoppingCart;
    }

    this.statusService.list()
      .then((response: any) => {
        this.statuses = response.data;
      });

    this.orderSubscription = this.ordersService.currentShoppingCart$.subscribe((order: OrdersResponse) => {
      this.order = order;
    });
  }

  ngOnDestroy() {
    this.orderSubscription.unsubscribe();
  }

  public getStatus(status: string) {
    let result = this.statuses.filter((item: any) => {
      return status == item.slug;
    });
    return result[0];
  }

  public goToEditOrder(order) {
    this.stepsService.getCurrentStep(order.id)
      .then((step) => {
        if (step) {
          this.router.navigate([`editor/${step}/${order.id}`], {relativeTo: this.route});
        }
      })

  }

  public findPreview(order) {
    const document = _.find(order.documents, (document) => {
      return document.files && document.files.images.length;
    });

    if (document) {
      return document.files.images[0].file.url;
    } else {
      return order.previewUrl;
    }
  }

  public isWebshopItem(item: OrderItem) {

    let results = item.documents.filter((document: any) => {
      return document.type != 'webshop_item'
    });

    return !(results.length > 0);
  }



  public isPlaceholder(item: OrderItem) {

    let results = item.documents.filter((document: any) => {
      return document.type != 'placeholder'
    });

    return !(results.length > 0);
  }

  showProductImage(product: any) {
    const imagesForShow = [];
    _.each(product.documents, (document: any) => {
      if (document.files) {
        if (document.files.images) {
          _.each(document.files.images, (image: any) => {
            imagesForShow.push(image.file.url);
          });
        }
        if (document.files.previewUrl) {
          _.each(document.files.previewUrl, (image: any) => {
            imagesForShow.push(image.file.url);
          });
        }
      }
    });

    const uniqueSet = new Set(imagesForShow);

    const unqiueValues = Array.from(uniqueSet);


    const dialogRef = this.dialog.open(ImagePopoverDialogComponent, {
      data: unqiueValues,
      width: (window.innerWidth * 0.3) + 'px',
      height: (window.innerWidth * 0.3) + 'px',
      panelClass: 'image-popover-dialog'
    });
  }

}
