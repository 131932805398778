import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import FlipClock                                                  from 'flipclock/src/main';
import DayCounter                                                 from 'flipclock/src/js/Faces/DayCounter';

@Directive({
    selector: '[appFlipclock]'
})

export class FlipclockDirective implements OnChanges {

    public clock: any;

    @Input() date: Date;

    constructor(public el: ElementRef) {
    }

    ngOnChanges() {
        this.clock = new FlipClock(this.el.nativeElement, this.date, {
            face: DayCounter,
            countdown: true,
            showSeconds: false,
            showLabels: true,
            autoStart: false
        });
    }

}
