import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from '../app.material';
import { HomeRoutingModule } from './home-routing.module';
import { HeaderComponent } from './header/header.component';
import { ImageComponent } from './image/image.component';
import { LocationsComponent }           from './locations/locations.component';
import { DeliveryComponent }            from './delivery/delivery.component';
import { DeadlineComponent }          from './deadline/deadline.component';
import { FlipclockDirective }         from './deadline/flipclock.directive';
import { OrderItemsComponent }        from './order-items/order-items.component';
import { TextComponent }              from './text/text.component';
import { SubscriptionComponent }      from './subscription/subscription.component';
import { ChangesHoverStyleDirective } from './changes-hover-style.directive';
import { SafePipe }                   from './safe.pipe';
import { PromoProductsComponent } from './promo-products/promo-products.component';

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BsDropdownModule} from "ngx-bootstrap";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CatalogComponent } from './catalog/catalog.component';
import { TruncatePipe } from "./truncate.pipe";

import {GlobalModule} from "../global/global.module";
import {CartComponent} from "../global/cart/cart.component";
import {CartPopupComponent} from "../global/cart-popup/cart-popup.component";
import {FilemanagerComponent} from "../global/filemanager/filemanager.component";
import {HelpdeskComponent} from "../global/helpdesk/helpdesk.component";
import {ContactInformationComponent} from "../global/contact-information/contact-information.component";
import { ImagePopoverDialogComponent } from './catalog/image-popover-dialog/image-popover-dialog.component';

@NgModule({
    declarations: [
      HomeComponent,
      HeaderComponent,
      ImageComponent,
      LocationsComponent,
      DeliveryComponent,
      DeadlineComponent,
      FlipclockDirective,
      OrderItemsComponent,
      TextComponent,
      SubscriptionComponent,
      ChangesHoverStyleDirective,
      SafePipe,
      PromoProductsComponent,
      CatalogComponent,
      TruncatePipe,
      ImagePopoverDialogComponent
    ],
  imports: [
    CommonModule,
    MaterialModule,
    HomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    GlobalModule
  ],
  entryComponents: [
    ContactInformationComponent,
    CartComponent,
    CartPopupComponent,
    FilemanagerComponent,
    HelpdeskComponent,
    ImagePopoverDialogComponent
  ]
})
export class HomeModule {
}
