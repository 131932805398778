import { Component, OnInit } from '@angular/core';
import { ThemeService }      from '../../theme.service';
import { UserService }       from '../../user.service';
import { ClientPageService } from '../../client-page.service';
import {OrdersService} from "../../order-item.service";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.sass']
})
export class ConfirmationComponent implements OnInit {

  user: any = { email: '' };
  page: any;
  order: any;

  constructor(
    public themeService: ThemeService,
    public usersService: UserService,
    public pageService: ClientPageService,
    public ordersService: OrdersService
  ) { }

  ngOnInit() {
    this.themeService.get();
    this.ordersService.currentShoppingCart$.subscribe((order) => {
      this.order = order;
      this.usersService.getUser()
        .then((user) => {
          this.user = user;
          this.pageService.get('order-confirmation')
            .then((response: any) => {
              this.page = response.page;
            });
        });
    });
  }

}
