import { NgModule } from '@angular/core';
import { CommonModule }                   from '@angular/common';
import { HeaderComponent }                from './header/header.component';
import {CartPopupComponent}               from "./cart-popup/cart-popup.component";
import {CartComponent}                    from "./cart/cart.component";
import {MaterialModule}                   from "../app.material";
import {FilemanagerComponent}             from "./filemanager/filemanager.component";
import {ContactInformationComponent}      from "./contact-information/contact-information.component";
import {HelpdeskComponent}                from "./helpdesk/helpdesk.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxIntlTelInputModule}            from "ngx-intl-tel-input";
import {FooterComponent}                  from "./footer/footer.component";
import { LocaleSelectorComponent }        from './locale-selector/locale-selector.component';
import { I18nTranslationDirective }       from './i18n-translation.directive';
import { MediaLibraryDialogComponent }    from './media-library-dialog/media-library-dialog.component';
import { SafePipe }                       from './safe.pipe';
import { UploaderPopupComponent } from './uploader-popup/uploader-popup.component';
import {ImageLoadingDetectorComponent} from "./uploader-popup/image-loading-detector/image-loading-detector.component";
import { EditorPopupComponent } from './editor-popup/editor-popup.component';
import {ErrorTooltipComponent} from "./editor-popup/error-tooltip/error-tooltip.component";
import {ErrorsDialogComponent} from "./editor-popup/errors-dialog/errors-dialog.component";
import {ChiliEditorModule} from "../editor/chili-editor/chili-editor.module";
import {VariablesComponent} from "./editor-popup/variables/variables.component";
import { ViewPopupComponent } from './view-popup/view-popup.component';
import {PreviewComponent} from "./view-popup/preview/preview.component";
import { PromtDialogComponent } from './dialogs/promt-dialog/promt-dialog.component';
import {AlertDialogComponent} from "./dialogs/alert-dialog/alert-dialog.component";
import { CustomAlertDialogComponent } from './dialogs/custom-alert-dialog/custom-alert-dialog.component';
import {MinMaxDirective} from "./min-max.directive";
import {RouterModule} from "@angular/router";
import {BytesPipe} from "./bytes.pipe";

@NgModule({
  declarations: [
    HeaderComponent,
    CartPopupComponent,
    CartComponent,
    FilemanagerComponent,
    ContactInformationComponent,
    HelpdeskComponent,
    FooterComponent,
    LocaleSelectorComponent,
    I18nTranslationDirective,
    SafePipe,
    BytesPipe,
    UploaderPopupComponent,
    ImageLoadingDetectorComponent,
    EditorPopupComponent,
    ErrorTooltipComponent,
    ErrorsDialogComponent,
    VariablesComponent,
    ViewPopupComponent,
    PreviewComponent,
    PromtDialogComponent,
    AlertDialogComponent,
    CustomAlertDialogComponent,
    MediaLibraryDialogComponent,
    MinMaxDirective
  ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        ChiliEditorModule,
        RouterModule,
    ],
  exports: [
    HeaderComponent,
    CartPopupComponent,
    CartComponent,
    FilemanagerComponent,
    ContactInformationComponent,
    HelpdeskComponent,
    FooterComponent,
    I18nTranslationDirective,
    AlertDialogComponent,
    ErrorTooltipComponent,
    ErrorsDialogComponent,
    VariablesComponent,
    ViewPopupComponent,
    MediaLibraryDialogComponent,
    SafePipe,
    BytesPipe
  ],
  entryComponents: [
    PromtDialogComponent,
    AlertDialogComponent,
    CustomAlertDialogComponent,
    UploaderPopupComponent,
    EditorPopupComponent,
    ErrorsDialogComponent,
    ViewPopupComponent,
    MediaLibraryDialogComponent
  ]
})
export class GlobalModule { }
