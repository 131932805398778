import { Component, Input, OnInit } from '@angular/core';
import { ThemeService }             from '../../theme.service';
import { ClientPageService }        from '../../client-page.service';
import { ClientPage }               from '../../client-page';
import { SettingService }           from '../../setting.service';
import {OrdersService}              from "../../order-item.service";
import { LanguagesService }         from '../../languages.service';
import { ConfigService }            from '../../config.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  @Input() page: ClientPage;

  settings: any;

  pageBlocks: any[] = [];

  constructor(
    public themeService: ThemeService,
    public clientPageService: ClientPageService,
    public settingService: SettingService,
    private orderService: OrdersService,
    private languagesService: LanguagesService
  ) { }

  ngOnInit() {
    this.themeService.get();
    this.clientPageService.get('homepage').then((response: any) => {
      for (const deadline in response.page.deadlines.items) {
        response.page.deadlines.items[deadline].start = new Date(response.page.deadlines.items[deadline].start.date);
        response.page.deadlines.items[deadline].end = new Date(response.page.deadlines.items[deadline].end.date);
      }
      this.page = response.page;
      this.pageBlocks = [];
      this.pageBlocks.push({ type: 'deadlines', data: this.page.deadlines });
      this.pageBlocks.push({ type: 'deliveries', data: this.page.deliveries });
      this.pageBlocks.push({ type: 'locations', data: this.page.locations });
      this.pageBlocks.push({ type: 'orderItems', data: this.page.orderItems });
      this.pageBlocks.push({ type: 'products', data: this.page.products });
      this.pageBlocks.push({ type: 'text', data: this.page.text });
      this.pageBlocks.sort((a, b) => (a.data.order > b.data.order) ? 1 : -1);
    });
    this.settingService.get().subscribe((settings) => {
      this.settings = settings;
    });

    this.languagesService.currentLocaleSet$.subscribe(() => {
      this.clientPageService.get('homepage').then((response: any) => {
        for (const deadline in response.page.deadlines.items) {
          response.page.deadlines.items[deadline].start = new Date(response.page.deadlines.items[deadline].start.date);
          response.page.deadlines.items[deadline].end = new Date(response.page.deadlines.items[deadline].end.date);
        }
        this.page = response.page;
        this.pageBlocks = [];
        this.pageBlocks.push({ type: 'deadlines', data: this.page.deadlines });
        this.pageBlocks.push({ type: 'deliveries', data: this.page.deliveries });
        this.pageBlocks.push({ type: 'locations', data: this.page.locations });
        this.pageBlocks.push({ type: 'orderItems', data: this.page.orderItems });
        this.pageBlocks.push({ type: 'products', data: this.page.products });
        this.pageBlocks.push({ type: 'text', data: this.page.text });
        this.pageBlocks.sort((a, b) => (a.data.order > b.data.order) ? 1 : -1);
      });
    });

  }

}
