import { NgModule }              from '@angular/core';
import { CommonModule }          from '@angular/common';
import { CartComponent }         from './cart/cart.component';
import { DeliveryComponent }                from './delivery/delivery.component';
import { ConfirmationComponent }            from './confirmation/confirmation.component';
import { CheckoutComponent }                from './checkout/checkout.component';
import { StepsComponent }                   from './steps/steps.component';
import { CheckoutRoutingModule }            from './checkout-routing.module';
import { FooterComponent }                  from './footer/footer.component';
import { MaterialModule }                   from './app.material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe }                   from './safe.pipe';
import { TruncatePipe } from "./truncate.pipe";
import {GlobalModule} from "../global/global.module";
import {RxReactiveFormsModule} from "@rxweb/reactive-form-validators";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    CartComponent,
    DeliveryComponent,
    ConfirmationComponent,
    CheckoutComponent,
    StepsComponent,
    FooterComponent,
    SafePipe,
    TruncatePipe
  ],
  imports: [
    CommonModule,
    CheckoutRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    GlobalModule,
    MatProgressSpinnerModule
  ],
  exports: [
    CheckoutComponent
  ]
})
export class CheckoutModule { }
