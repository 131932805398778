import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {ThemeService} from "../../theme.service";
import {OrdersService} from "../../order-item.service";

@Component({
  selector: 'global-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.sass']
})
export class HelpdeskComponent implements OnInit {
  form: FormGroup;
  order: any;

  constructor(
    public themeService: ThemeService,
    private formBuilder: FormBuilder,
    private ordersService: OrdersService,
    public dialogRef: MatDialogRef<HelpdeskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.setForm();
  }

  private setForm() {
    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phoneNo: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],
      hasError: ['', []],
    });
  }

  send() {
    if (this.form.invalid) {
      return;
    }

    this.ordersService.sendHelpdeskMail(this.data.id, this.form.value)
      .then(() => {
        this.close();
      });
  }

  close() {
    this.dialogRef.close({success: true});
  }

}
