<div class="dialog-container">
  <div class="dialog-title">
    <img *ngIf="data.icon" [src]="data.icon | safe: 'resourceUrl'" alt="Alert">
    <mat-icon *ngIf="!data.icon">warning</mat-icon>
  </div>
  <div class="dialog-content">
    <div class="dialog-text" [innerHtml]="data.text | safe:'html' "></div>
    <div class="dialog-action">
      <button mat-button cdkFocusInitial (click)="close()" [innerHtml]="data.buttonNoText | safe:'html' "></button>
      <button mat-button cdkFocusInitial (click)="apply()" [innerHtml]="data.buttonYesText | safe:'html' "></button>
    </div>
  </div>
</div>
