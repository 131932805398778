<div class="container" [ngStyle]="{'background-color': themeService.settings.backgroundColor, 'color': themeService.settings.btnActiveColor}">
  <global-header></global-header>
  <app-steps></app-steps>
  <app-cart *ngIf="path == 'cart'"></app-cart>
  <app-delivery *ngIf="path == 'delivery'"></app-delivery>
<!--  <app-payment *ngIf="path == 'payment'"></app-payment>-->
  <app-confirmation *ngIf="path == 'confirmation'"></app-confirmation>
  <global-footer></global-footer>
</div>

