import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ThemeService }             from '../../theme.service';
import { ClientPage }               from '../../client-page';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.sass']
})
export class DeliveryComponent implements OnInit {

  @Input() page: ClientPage;

  height: number = 430;

  constructor(public themeService: ThemeService) { }

  ngOnInit() {
    const heights = [];
    setTimeout(() => {
      this.page.deliveries.items.map((value, index) => {
        heights.push(document.querySelector('#listItem'+index+' > .mat-list-item-content').clientHeight);
      });
      this.height = (heights.sort())[2];
    }, 100);
  }

}
