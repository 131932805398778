import { Injectable }    from '@angular/core';
import { HttpClient }    from "@angular/common/http";
import {ConfigService} from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class BuilderService {

  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) { }

  get(formName) {
    return this.http.get(this.config.getActionUrl('form/' + formName)).toPromise();
  }
}
