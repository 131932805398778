import { Injectable } from '@angular/core';
import {AssetsService} from "./assets.service";
import {ImageStatusService} from "./editor/image-status.service";


@Injectable({
  providedIn: 'root'
})
export class AssetsDataService {

  constructor( private assetsService: AssetsService,
               private imageStatusService: ImageStatusService) { }

  private countersContainer = {};

  public get(id) {
    if (!this.countersContainer[id]) {
      this.countersContainer[id] = 0;
    }
    this.countersContainer[id]++;

    return this.assetsService.get(id)
      .then((result) => {
        if (result && result.previewUrls && result.previewUrls.length) {
          this.imageStatusService.callStatusWasChangedEvent(id);
          return result;
        } else {
          if(this.countersContainer[id] > 30) {
            return  { previewUrls: [] };
          }
          return this.get(id);
        }
      })
  }

  public remove(id) {
    return this.assetsService.remove(id);
  }
}
