import { Component, OnInit } from '@angular/core';
import { ThemeService }      from '../theme.service';
import { ClientPageService } from '../client-page.service';
import { ClientPage }        from '../client-page';
import { LanguagesService }  from '../languages.service';
import { ActivatedRoute }    from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.sass']
})
export class ErrorComponent implements OnInit {

  page: ClientPage;

  constructor(
    public themeService: ThemeService,
    public clientPageService: ClientPageService,
    public languagesService: LanguagesService,
    public route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.themeService.get();
    this.clientPageService.get('error-page').then((response: any) => {
      this.page = response.page;
    });
  }

}
