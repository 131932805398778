import { Component, OnInit }      from '@angular/core';
import { ThemeService }           from '../theme.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientPageService }      from '../client-page.service';
import { ResetPasswordCommand }   from '../login/commands/reset-password.command';
import { RestorePasswordService } from '../login/restore-password.service';
import { CheckResetKeyCommand }   from '../login/commands/check-reset-key.command';
import { NotificationService }    from '../global/notification.service';
import { HttpErrorResponse }      from '@angular/common/http';
import { LanguagesService }       from '../languages.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    public themeService: ThemeService,
    public route: ActivatedRoute,
    private languagesService: LanguagesService,
    public router: Router,
    public clientPageService: ClientPageService,
    public restoreService: RestorePasswordService,
    public notification: NotificationService
  ) {}

  page: any;
  isProcessed = false;
  data: ResetPasswordCommand = new ResetPasswordCommand('', '', '');
  error: any;
  validationError: any =  { message: '', fields: []};
  path: string;

  ngOnInit() {
    this.themeService.get();

    this.path = this.route.snapshot.url[0].path;
    this.restoreService.check(new CheckResetKeyCommand(this.route.snapshot.paramMap.get('restore_key')))
    .then((response: any) => {
      this.error = null;
    }, (response: any) => {
      this.error = { message: 'Restore key expired' };
    });

    this.clientPageService.get('login-page').then((response: any) => {
      this.page = response.page;
    });
  }

  handleSetNewPassword() {
    this.validationError = { message: '', fields: []};
    if (this.data.password !== this.data.confirm_password) {
      this.validationError = { message: 'Passwords do not match', fields: [ 'password', 'confirm_password' ] };
      return;
    }
    if (this.data.password === '') {
      this.validationError = { message: 'The password field must be filled', fields: [ 'password' ]  };
      return;
    }
    if (this.data.confirm_password === '') {
      this.validationError = { message: 'The confirm password field must be filled', fields: [ 'confirm_password' ]  };
      return;
    }

    this.data.key = this.route.snapshot.paramMap.get('restore_key');

    this.restoreService.set(this.data)
    .then((value) => {
      this.notification.alert(7)
        .then(() => {
          this.router.navigate(['login']);
        });
    }, (response: HttpErrorResponse) => {
      this.isProcessed = false;
      this.error = response.error.error;
      this.notification.alert(this.error.message);
    });
  }

}
