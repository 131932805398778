import { EventEmitter, Injectable } from '@angular/core';
import { SettingService }           from './setting.service';
import { BehaviorSubject }          from 'rxjs';
import { Color }                     from './color';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  public settings$ = new BehaviorSubject<any>(this);
  public settings: any = false;

  constructor(private settingService: SettingService) { }

  get () {
    if (this.settings) {
      this.settings$.next(this);
    } else {
      const subscription = this.settingService.get().subscribe((response: any) => {
        this.settings = response.settings;
        this.settings.termsAndConditions = this.settings.termsAndConditions.replace(/\n/g, '<br>');
        this.settings$.next(this);
        subscription.unsubscribe();
      });
    }

  }

  public getButtonColor(opacity: number) {
    const btnColor = new Color(this.settings.btnColor);
    btnColor.a = opacity;
    return btnColor.toRgba();
  }

  public getBackgroundColor(color: string) {
    const btnColor = new Color(color);
    btnColor.a = 0.15;
    return btnColor.toRgba();
  }

  public getLogoUrl() {
    return this.settings.logoUrl;
  }

  public getWelcomeMessage() {
    return this.settings.welcomeMessage;
  }

  public getGradienString() {
    return 'linear-gradient(to bottom, ' + this.getButtonColor(1) + ',' + this.getButtonColor(1) + '), linear-gradient(to right, ' + this.getButtonColor(1) + ', ' + this.getButtonColor(0.25) + ')';
  }
}
