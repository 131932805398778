import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Subject} from "rxjs/Subject";
import {filter} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class EditorService {
  editor: any;
  public shellReady = new BehaviorSubject<boolean>(false);
  private _events = new Subject<any>();

  constructor(private _zone: NgZone) { }

  get() {
    return this.editor;
  }

  set(editor) {
    this.editor = editor;
    (window as any).chili = this.editor;
    this.selectEvent$('DocumentFullyLoaded')
      .subscribe(evt => {
        if (!this.editorReadyForConsole()) {
          const rfcInterval = setInterval(() => {
              const ready = this.editorReadyForConsole();

              if (ready) {
                clearInterval(rfcInterval);
              }
            }, 200
          );
        }
      });
  }

  editorReadyForConsole() {
    const statusMessage = this.editor.GetObject('document.readyForConsole');

    if (statusMessage.length > 0) {
      return false;
    } else {
      this.shellReady.next(true);
      return true;
    }
  }

  events$() {
    return this._events.asObservable();
  }

  startEventListening() {
    this._zone.run(() => {
      window['OnEditorEvent'] = this.OnEditorEvent.bind(this);
    });
  }

  OnEditorEvent(type, targetID, data) {
    this._zone.run(() => this._events.next({ type, targetID, data }));
  }


  selectEvent$(eventName: string) {
    if (this.editor.AddListener) {
      this.editor.AddListener(eventName);
    }

    return this.events$()
      .pipe(
        filter(evt => evt.type === eventName),
      );
  }
}
