import {NgModule}                from '@angular/core';
import {Routes, RouterModule}    from '@angular/router';
import { CheckoutComponent }     from './checkout/checkout.component';

const routes: Routes = [
  {
    path: 'cart',
    component: CheckoutComponent
  },
  {
    path: 'delivery',
    component: CheckoutComponent
  },
  {
    path: 'confirmation',
    component: CheckoutComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CheckoutRoutingModule {
}
