<div class="container" *ngIf="page.deliveries && page.deliveries.isShown">
  <div class="point" *ngIf="page.deliveries.title" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}"></div>
  <h1 *ngIf="page.deliveries.title" [innerHTML]="page.deliveries.title | safe:'html'"></h1>
  <div class="delivery">
    <mat-list>
      <mat-list-item *ngFor="let delivery of page.deliveries.items; let i = index" [id]="'listItem' + i" [style.height.px]="height">
        <div class="icon" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1), 'box-shadow': '0 25px 45px 5px ' + (themeService.settings$ | async).getButtonColor(0.25)}">
          <div class="img" [ngStyle]="{'background-color': '#ffffff', 'mask': 'url(' + delivery.icon.url + ')', '-webkit-mask-image': 'url(' + delivery.icon.url + ')', 'mask-size': '54px 54px', 'mask-position': 'center center'}" ></div>
        </div>
        <div class="title" [innerHTML]="delivery.title | safe:'html'"></div>
        <div class="description" [innerHTML]="delivery.description | safe:'html'"></div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
