export class RegistrationCommand {
    public name: string;
    public email: string;
    public password: string;
    public confirm_password: string;

    constructor(name: string, email: string, password: string, confirm_password: string) {
        this.name = name;
        this.email = email;
        this.password = password;
        this.confirm_password = confirm_password;
    }

    getName(): string {
      return this.name;
    }

    getEmail(): string {
        return this.email;
    }

    getPassword(): string {
        return this.password;
    }

    getConfirmPassword(): string {
      return this.confirm_password;
    }
}
