import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
}                     from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  DefaultUrlSerializer,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import 'rxjs/add/operator/do';
import {AuthenticationService} from "./authentication.service";
import {UserService} from "../user.service";

@Injectable({
  providedIn: 'root'
})
export class CanActivateExpoCode implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private userService: UserService) {}

  checked = false;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    const isExpodoc = !(document.referrer.indexOf('expodoc.com') === -1);
    const urlSerializer = new DefaultUrlSerializer();
    const urlTree = urlSerializer.parse(document.location.pathname + document.location.search);

    if(isExpodoc && urlTree.queryParams.code) {
      return new Promise(async (resolve) => {
        this.authenticationService.loginByExpodocCode(urlTree.queryParams.code).then(() => {
          this.checked = true;
        });

        do {
          await this.delay();

        } while (!this.checked);

        if(this.userService.user) {
          resolve(true);
        }

        resolve(false);

      });

    }

    return true;
  }

  async delay() {
    return new Promise( resolve => setTimeout(resolve, 100) );
  }
}
