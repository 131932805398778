import {Component, Input, OnInit} from '@angular/core';
import {OrdersService} from "../../../order-item.service";
import * as _ from "lodash";
import {HttpCancelService} from "../../../http-cancel.service";

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.sass']
})
export class PreviewComponent implements OnInit {
  url: string;
  currentImage: any;
  currentPage: any = 0;
  isProcessed: boolean = false;
  showPreview = false;

  @Input() set documentPage(value) {
    this.currentPage = value;
    this.url = null;
    this.showPreview = false;
    if (this.currentProduct) {
      this.getPreviewUrl();
    }

  }

  @Input() orderItem: any;
  @Input() set selectedProduct(value) {

    if(this.currentProduct) {
      this.httpCancelService.cancelPendingRequests();
    }

    this.currentProduct = value;

    if (value) {
      this.url = null;
      this.showPreview = false;

      this.getPreviewUrl();
      // this.getUploaded();
    }
  }
  currentProduct: any;

  constructor(
    public ordersService: OrdersService,
    private httpCancelService: HttpCancelService
  ) { }

  ngOnInit() {
  }

  loadHandler($event) {
    this.showPreview = true;
    this.isProcessed = false;
  }

  private getPreviewUrl() {

    if (!this.orderItem || !this.currentProduct || !this.currentPage) {
      return;
    }

    this.isProcessed = true;

    this.ordersService.getPreviewOnProofStep(this.orderItem.id, this.currentProduct.id)
      .then((result) => {
        if (result && result.urls && result.urls.length) {
          const data = result.urls[this.currentPage - 1].split('.online')[1];
          this.url  = `/CHILI${data}`;
        }
      });
  }

  public getCurrentEmbedded(data){
    this.ordersService.getEmbedded(this.orderItem.id, this.currentProduct.id)
      .then((result) => {
        if (result && result.assets && result.assets.length) {
          this.currentImage = _.find(data, (asset) => {
            return asset.id === result.assets[0].assetId;
          });
        } else {
          this.currentImage = null;
        }
      })
  }

  public getUploaded(){
    this.ordersService.getUploaded(this.orderItem.id, this.currentProduct.id, 1, '')
      .then((result) => {
        if (result && result.assets && result.assets.length) {
          this.getCurrentEmbedded(result.assets);
        }
      })
  }

}
