import { Injectable } from '@angular/core';
import {ConfigService} from "./config.service";
import {HttpClient} from "@angular/common/http";
import {catchError, delay} from "rxjs/operators";
import {Observable} from "rxjs/Observable";
import {of} from "rxjs/internal/observable/of";

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  constructor(private http: HttpClient,
              private configService: ConfigService) { }

  public get(id): Promise<any> {
    return this.http.get(this.configService.getActionUrl(`assets/${id}/preview-url`))
      .pipe(
        delay(2000),
        catchError(this.handleError('get preview url', null))
      )
      .toPromise()
  }

  public remove(id): Promise<any> {
    return this.http.delete(this.configService.getActionUrl(`assets/${id}`))
      .pipe(
        catchError(this.handleError('remove asset', null))
      )
      .toPromise()
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
