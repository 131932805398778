<div class="container" *ngIf="page" [ngStyle]="{'background-color': themeService.settings.backgroundColor, 'color': themeService.settings.btnActiveColor}">
  <global-header [page]="page"></global-header>
  <app-image [page]="page" *ngIf="page.image.isShown && page.image.isFullWidth"></app-image>
  <app-header [page]="page"></app-header>
  <app-image [page]="page" *ngIf="page.image.isShown && !page.image.isFullWidth"></app-image>
  <ng-template  ngFor let-block [ngForOf]="pageBlocks" let-i="index" >
    <app-locations [page]="page" *ngIf="page.locations.isShown && block.type == 'locations'"></app-locations>
    <app-delivery [page]="page" *ngIf="page.deliveries.isShown && block.type == 'deliveries'"></app-delivery>
    <app-catalog *ngIf="settings && settings.tenantType == 'webshop' && block.type == 'products'" [page]="page"></app-catalog>
    <app-deadline [page]="page" *ngIf="page.deadlines.isShown && block.type == 'deadlines'"></app-deadline>
    <app-order-items *ngIf="settings && settings.tenantType == 'orderPlatform' && block.type == 'orderItems'" [page]="page"></app-order-items>
    <app-text [page]="page" *ngIf="page.text.isShown && block.type == 'text'"></app-text>
  </ng-template>
  <app-promo-products [page]="page"></app-promo-products>

  <global-footer [page]="page"></global-footer>
</div>
