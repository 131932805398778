<div class="container" #container *ngIf="page.products.isShown" [ngStyle]="{ 'margin-top' : page.products.title == '' ? '30px' : '100px' }">
  <div    *ngIf="page.products.title"
          class="point"
          [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}">
  </div>
  <h1 [innerHTML]="page.products.title | safe:'html'">
  </h1>
  <div class="catalog">
    <div class="toolbar">
      <div class="right">
        <mat-form-field appearance="fill" #searchField class="search-field">
          <mat-label><span appI18nTranslation>Search</span></mat-label>
          <input matInput type="text" (keyup.enter)="handleSearch($event)" [formControl]="search">
          <button mat-icon-button matSuffix (click)="handleSearch($event)">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
        <div class="maintag-field" *ngIf="page.products.isShownMaintag && productsMainTags.length > 0">
          <label appI18nTranslation>YOUR SHOP TYPE</label>
          <mat-form-field>
            <mat-select (selectionChange)="selectMainTags($event)">
              <mat-option *ngFor="let productsMainTag of productsMainTags" [value]="productsMainTag">{{productsMainTag.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>

      <div class="left">
        <mat-form-field  #productPerPageField  class="per-page">
          <mat-label><span appI18nTranslation>Per page</span></mat-label>
          <mat-select (selectionChange)="selectProductPerPageCounter($event)" [value]="productPerPage">
            <mat-option *ngFor="let productPerPageCounter of productPerPageCounters" [value]="productPerPageCounter">{{productPerPageCounter}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field  #filterField *ngIf="productsTags.length > 0">
          <mat-label><span appI18nTranslation>Filter</span></mat-label>
          <mat-select multiple (selectionChange)="selectTags($event)">
            <mat-option *ngFor="let productsTag of productsTags" [value]="productsTag">{{productsTag.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-list>
      <mat-list-item *ngFor="let product of productsOnCurrentPage; let i = index;" [id]="'productsListItem' + i" [style.height.px]="height">
        <div class="top">
          <div class="labels" *ngIf="product && product.productType != 'group' && product.labels && product.labels.length > 0">
            <ng-container *ngFor="let label of product.labels">
              <div class="label" [ngStyle]="{ 'background-color': label.color }">{{label.name}}
                <div class="label-arrow" [ngStyle]="{ 'border-top': '10px solid ' + label.color }"></div>
              </div>
            </ng-container>
          </div>
          <div class="labels" *ngIf="product && product.productType == 'group' && product.products[0].labels && product.products[0].labels.length > 0">
            <ng-container *ngFor="let label of product.products[0].labels">
              <div class="label" [ngStyle]="{ 'background-color': label.color }">{{label.name}}
                <div class="label-arrow" [ngStyle]="{ 'border-top': '10px solid ' + label.color }"></div>
              </div>
            </ng-container>
          </div>
          <div class="image" *ngIf="product && product.productType != 'group' && product.documents && product.documents.length">
            <img [src]="product.documents[0].images[0].file.url" [alt]="product.name" (click)="showProductImage(product)" *ngIf="isShowProductPreview(product)">
            <img [src]="product.documents[0].previewUrl" [alt]="product.name" (click)="showProductImage(product)" *ngIf="isShowChiliPreview(product)">
          </div>
          <div class="image" *ngIf="product && product.productType == 'group' && product.products && product.products[0].documents && product.products[0].documents.length">
            <img [src]="product.products[0].documents[0].images[0].file.url" [alt]="product.name" (click)="showProductImage(product.products[0])" *ngIf="isShowProductPreview(product.products[0])">
            <img [src]="product.products[0].documents[0].previewUrl" [alt]="product.name" (click)="showProductImage(product.products[0])" *ngIf="isShowChiliPreview(product.products[0])">
          </div>
          <div class="title">
            <h3>{{product.name}}</h3>
          </div>
          <div class="description" [innerHTML]="product.productType != 'group' ? product.notes : product.products[0].notes | truncate:38"></div>
        </div>
        <div class="bottom" *ngIf="product.productType != 'group'">
          <div class="price" *ngIf="isShowCurrencyPrice(product)">
            <span class="label" *ngIf="isShowCurrencyPrice(product)">{{page.products.labelForPrice}}:</span> <span *ngIf="isShowCurrencyPrice(product)" class="price" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">{{product.price | currency: 'EUR':'symbol':'1.2-3':'nl-BE'}}</span>
          </div>
          <div class="credits" *ngIf="isShowCreditPrice(product)">
            <span class="label" *ngIf="isShowCreditPrice(product)">{{page.products.labelForCredits}}:</span> <span *ngIf="isShowCreditPrice(product)" class="credits" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">{{(product.price / creditSettings.creditValue) | number:'1.1-3' }} <span appI18nTranslation>Credits</span></span>
          </div>
          <a mat-button (click)="add(product)" [disabled]="isDisabledAddButton(product)" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}"><mat-icon>add_shopping_cart</mat-icon> {{page.products.labelForAddToCardButton}}</a>
        </div>
        <div class="bottom" *ngIf="product.productType == 'group'">
          <div class="price" *ngIf="isShowCurrencyPrice(product.products[0])">
            <span class="label" *ngIf="isShowCurrencyPrice(product.products[0])">{{page.products.labelForPrice}}:</span> <span *ngIf="isShowCurrencyPrice(product.products[0])" class="price" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">{{product.products[0].price | currency: 'EUR':'symbol':'1.2-3':'nl-BE'}}</span>
          </div>
          <div class="credits" *ngIf="isShowCreditPrice(product.products[0])">
            <span class="label" *ngIf="isShowCreditPrice(product.products[0])">{{page.products.labelForCredits}}:</span> <span *ngIf="isShowCreditPrice(product.products[0])" class="credits" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">{{(product.products[0].price / creditSettings.creditValue) | number:'1.1-3' }} <span appI18nTranslation>Credits</span></span>
          </div>
          <a mat-button (click)="add(product)" [disabled]="isDisabledAddButton(product.products[0])" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}"><mat-icon>add_shopping_cart</mat-icon> {{page.products.labelForAddToCardButton}}</a>
        </div>
      </mat-list-item>
    </mat-list>
    <div class="pagination">
      <mat-button-toggle-group #first="matButtonToggleGroup">
        <mat-button-toggle (click)="setCurrentPage(this.firstPage)" [disabled]="currentPage == 1">
          <mat-icon>first_page</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle (click)="setCurrentPage(currentPage - 1)" [disabled]="currentPage == 1">
          <mat-icon>navigate_before</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      <mat-button-toggle-group #pages="matButtonToggleGroup" class="pages">
        <mat-button-toggle *ngFor="let pageNumber of productPages" (click)="setCurrentPage(pageNumber)" [checked]="currentPage == pageNumber">{{pageNumber}}</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-button-toggle-group #last="matButtonToggleGroup">
        <mat-button-toggle (click)="setCurrentPage(currentPage + 1)" [disabled]="currentPage == this.lastPage">
          <mat-icon>navigate_next</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle (click)="setCurrentPage(this.lastPage)" [disabled]="currentPage == this.lastPage">
          <mat-icon>last_page</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</div>
