import { Injectable } from '@angular/core';
import {Subject} from "rxjs/Subject";

@Injectable({
  providedIn: 'root'
})
export class HttpCancelService {
  cancelPendingRequests$: Subject<void> = new Subject<void>();

  constructor() { }

  public cancelPendingRequests() {
    this.cancelPendingRequests$.next()
  }

  public onCancelPendingRequests() {
    return this.cancelPendingRequests$.asObservable()
  }
}
