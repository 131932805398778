import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class TenantCreditSettingsService {

  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) { }

  settings: any;

  async  getSettings() {
    if (!this.settings) {
      this.settings = await (this.http.get(this.config.getActionUrl('credit-settings')).toPromise());
    }
    return this.settings.creditSettings;
  }
}
