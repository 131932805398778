<form>
  <div class="logo">
    <img [src]="themeService.getLogoUrl()">
  </div>
  <div class="login-header">
    <h1>{{themeService.getWelcomeMessage()}}</h1>
    <h4 *ngIf="page && page.text.isShown" [innerHTML]="page.text.text | safe: 'html'"></h4>
  </div>
  <div class="field">
    <label for="email" appI18nTranslation>Email</label>
    <input type="email" name="email" id="email" [(ngModel)]="data.email" [ngModelOptions]="{standalone: true}">
  </div>
  <div class="field password">
    <label for="password" appI18nTranslation>Password</label>
    <input [type]="isPasswordVisible ? 'text':'password'" name="password" id="password" [(ngModel)]="data.password" [ngModelOptions]="{standalone: true}">
    <mat-icon (click)="isPasswordVisible = !isPasswordVisible">{{ isPasswordVisible ? "visibility":"visibility_off"}}</mat-icon>
  </div>
  <div class="field" *ngIf="error">
    <span class="error">{{error.message}}</span>
  </div>
  <div class="forgot">
    <label for="remember" appI18nTranslation>Remember me</label>
    <input type="checkbox" id="remember" [(ngModel)]="data.remember" [ngModelOptions]="{standalone: true}">
    <a routerLink="/forgot" appI18nTranslation>Forgot password?</a>
  </div>
  <div class="registration" *ngIf="settings && settings.registrationEnabled">
    <a routerLink="/registration" appI18nTranslation>Registration</a>
  </div>
  <button
          (click)="handleLogin($event)"
          [disabled]="isProcessed"
          mat-button
          [ngStyle]="{
        'background-color': (themeService.settings$ | async).getButtonColor(1)
        }" appI18nTranslation>
    Submit
  </button>
  <div class="progress" [ngStyle]="{'margin-top': isProcessed ? '45px' : '50px'}">
    <mat-progress-bar mode="query" *ngIf="isProcessed"></mat-progress-bar>
  </div>
</form>
