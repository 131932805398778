import { Component, OnInit } from '@angular/core';
import { ConfigService }     from '../../config.service';
import { Router }            from '@angular/router';
import { LanguagesService }  from '../../languages.service';

@Component({
  selector: 'app-locale-selector',
  templateUrl: './locale-selector.component.html',
  styleUrls: ['./locale-selector.component.sass']
})
export class LocaleSelectorComponent implements OnInit {

  constructor(
    public config: ConfigService,
    public languagesService: LanguagesService,
    private router: Router
  ) {
  }

  ngOnInit(): void {

  }

  changeCurrentLocale($event): void {
    this.config.setCurrentLocale(this.config.currentLocale);
    this.languagesService.setCurrentLocale(this.config.currentLocale);
  }

}
