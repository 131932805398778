import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../../authentication/authentication.service";
import {ThemeService} from "../../theme.service";
import {ClientPageService} from "../../client-page.service";
import {Subscription} from "rxjs";
import {AuthenticationResponse} from "../../authentication/authentication-response";
import {RegistrationCommand} from "../commands/registration.command";
import {NotificationService} from "../../global/notification.service";
import {Router} from "@angular/router";

@Component({
  selector: 'fu-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.sass']
})
export class RegistrationFormComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,
              public themeService: ThemeService,
              public clientPageService: ClientPageService,
              private notification: NotificationService,
              private router: Router
  ) { }

  page: any;
  data: RegistrationCommand = new RegistrationCommand('', '', '', '');
  loginSubscription: Subscription;
  isProcessed = false;
  error: any;

  ngOnInit() {
    this.themeService.get();

    this.clientPageService.get('login-page').then((response: any) => {
      this.page = response.page;
    });
  }

  ngOnDestroy() {}

  handleRegistration($event) {
    this.isProcessed = true;

    if(this.data.password != this.data.confirm_password) {
      this.isProcessed = false;
      this.error = {
        message: 'Passwords do not match, check the password and its confirmation'
      };
    }

    if(this.data.password == this.data.confirm_password) {
      this.loginSubscription = this.authenticationService.registration(this.data)
        .subscribe((data: AuthenticationResponse) => {
            this.isProcessed = false;
            this.notification.alert(4)
              .then(() => {
                this.router.navigate(['login']);
              });
          },
          error => {
            this.isProcessed = false;
            this.error = error.error.error;
          });
    }

  }

}
