<div class="container">
  <div class="header">
    <div class="avatar">
      <mat-icon
        [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}">
        error
      </mat-icon>
    </div>
    <button class="close-button" mat-dialog-close>
      <mat-icon>clear</mat-icon>
    </button>

    <div class="title">
      <h1 appI18nTranslation>Document has problems</h1>
    </div>
  </div>

  <div class="body">
    <div *ngIf="data.errors.messages && data.errors.messages.length" class="error-block">
      <div class="error-title">Errors:</div>
      <div *ngFor="let error of data.errors.messages; let i = index;">
        {{i+1}}. {{error}}
      </div>
    </div>
   <div *ngIf="data.warnings.messages && data.warnings.messages.length" class="error-block">
     <div class="error-title" appI18nTranslation>Warnings:</div>
     <div *ngFor="let warning of data.warnings.messages; let j = index;">
       {{j+1}}. {{warning}}
     </div>
   </div>
  </div>
</div>

