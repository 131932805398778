<div class="container" [ngClass]="{'without-cover': page && page.image && page.image.isShown && page.image.image.url}">
  <div *ngIf="page" class="wrapper"  [ngStyle]="{'background-image': page.image && page.image.isShown && page.image.image.url ? 'url(' + page.image.image.url + ')' : 'none', 'opacity': '1'}">
    <div class="wrapper" *ngIf="page && page.image && !page.image.isShown"></div>
    <fu-login-form *ngIf="path == 'login'"></fu-login-form>
    <fu-restore-password *ngIf="path == 'forgot'"></fu-restore-password>
    <fu-registration-form *ngIf="path == 'registration'"></fu-registration-form>
    <app-external-uploader [page]="page" *ngIf="path == 'uploader'"></app-external-uploader>
    <h1
      *ngIf="page && page.title.isShown"
      class="title"
      [innerHTML]="page.title.title | safe: 'html'">
    </h1>
  </div>

</div>
