import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {ConfigService} from "./config.service";
import {Observable} from "rxjs/Observable";
import {of} from "rxjs/internal/observable/of";

@Injectable({
  providedIn: 'root'
})
export class PromoProductsService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  public getList(query?): Promise<any> {
    return this.http.get(this.configService.getActionUrl('promo-products'))
      .pipe(
        catchError(this.handleError('get promo product list', null))
      )
      .toPromise()
  }

  public sendMail(data): Promise<any> {
    return this.http.post(this.configService.getActionUrl('promo-products/send'), data)
      .pipe(
        catchError(this.handleError('send promo product form', null))
      )
      .toPromise()
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
