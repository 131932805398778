<div class="container" *ngIf="page.deadlines && page.deadlines.items.length > 0 && page.deadlines.isShown">
  <div class="point" *ngIf="page.deadlines.title" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}"></div>
  <h1 *ngIf="page.deadlines.title" [innerHTML]="page.deadlines.title | safe:'html'"></h1>
  <div class="deadlines">
    <mat-list>
      <ng-template ngFor let-deadline [ngForOf]="page.deadlines.items" let-i="index" >
        <mat-list-item *ngIf="deadline.end >= date">
          <div *ngIf="deadline.end >= date">
            <div class="timer" appFlipclock [date]="deadline.end" [ngStyle]="{'color': (themeService.settings$ | async).getButtonColor(1)}"></div>
            <div class="description" [innerHTML]="deadline.description | safe:'html'"></div>
          </div>
        </mat-list-item>
      </ng-template>
    </mat-list>
  </div>
</div>
