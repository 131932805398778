import { Injectable } from '@angular/core';
import {EditorService} from "./editor.service";

@Injectable({
  providedIn: 'root'
})
export class EditorCommandsService {
  editor: any;

  constructor(private editorService: EditorService) {
    this.editorService.shellReady.subscribe(ready => {
      if (ready) {
        this.editor = this.editorService.get();
      }
    });
  }

  setProperty(propertyName, value , place = null) {
    const target = place ? place : 'document.selectedFrame';
    this.editor.SetProperty(target, propertyName, value);
  }

  getProperty(location) {
    if (!this.editor.GetObject) {
      return false;
    }
    return this.editor.GetObject(location);
  }

  executeFunction(location, functionName, value = null) {
    if (value) {
      return this.editor.ExecuteFunction(location, functionName, ...value);
    } else {
      return this.editor.ExecuteFunction(location, functionName);
    }
  }
}
