import {
  EventEmitter,
  Injectable
}                     from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {ConfigService} from "../config.service";

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  statusesListWasChanged$: EventEmitter<any> = new EventEmitter<any>();

  constructor(
      private http: HttpClient,
      private config: ConfigService
  ) { }

  get(statusId: number): Observable<any> {
      return this.http.get(this.config.getActionUrl(`status/${statusId}`));
  }

  list(): Promise<any> {
    return this.http.get(this.config.getActionUrl(`status`)).toPromise();
  }
}
