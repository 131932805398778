import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {AlertBlock} from "../../../blocks/alert-block";
import {CurrencyPipe} from "@angular/common";
import {ConfigService} from "../../../config.service";

@Component({
  selector: 'app-custom-alert-dialog',
  templateUrl: './custom-alert-dialog.component.html',
  styleUrls: ['./custom-alert-dialog.component.sass']
})
export class CustomAlertDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CustomAlertDialogComponent>,
    public config: ConfigService
  ) {}

  ngOnInit() {
    document.documentElement.style.setProperty('--custom-promt-text-color', this.data.textColor);
    if(this.data && this.data.totalPrice && this.data.totalPrice > 0) {
      const currencyPipe = new CurrencyPipe(this.config.currentLocale.code)
      this.data.text = this.data.text.replace('#{{Order total}}', currencyPipe.transform(this.data.totalPrice, 'EUR','', '1.2-3', 'nl-BE'));
    }
  }

  close() {
    this.dialogRef.close({ status: 'cancel', apply: false });
  }

  apply() {
    this.dialogRef.close({ status: 'apply', apply: true });
  }

}
