import {
  Directive,
  HostBinding,
  HostListener,
  Output,
  EventEmitter
}                       from '@angular/core';

@Directive({
  selector: '[appFileUploader]'
})
export class FileUploaderDirective {

  @HostBinding('class.fileover') fileOver: boolean;

  @Output() onFileDropped = new EventEmitter<FileList>();

  constructor() { }

  @HostListener('dragover', ['$event']) onDragOver($event): void {
   $event.preventDefault();
   $event.stopPropagation();
   this.fileOver = true;
   // console.log('Drag Over');
  }

  @HostListener('dragleave', ['$event']) public onDragLeave($event): void {
    $event.preventDefault();
    $event.stopPropagation();
    // console.log('Drag Leave');
  }

  @HostListener('drop', ['$event']) public ondrop($event: DragEvent): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.fileOver = false;
    const files = $event.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }

  }

}
