<div class="container" *ngIf="promoProducts && promoProducts.length">
  <div
    class="point"
    [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}">
  </div>
  <h1 appI18nTranslation>
    EXCLUSIVE PRODUCTS
  </h1>
  <div class="promo-products">
    <mat-list>
      <mat-list-item *ngFor="let promoProduct of promoProducts">
        <div class="image" *ngIf="promoProduct.image && promoProduct.image.file && promoProduct.image.file.url">
          <img [src]="promoProduct.image.file.url" [alt]="promoProduct.name" (click)="showProductImage($event)">
        </div>
        <div class="description">
          <h3>{{promoProduct.name}}</h3>
        </div>
        <a mat-button (click)="openPromoProductDialog(promoProduct)" [ngStyle]="{'background-color': (themeService.settings$ | async).getButtonColor(1)}" appI18nTranslation>MORE INFO</a>
      </mat-list-item>
    </mat-list>
  </div>
</div>

