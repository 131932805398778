<div class="container" [ngClass]="{'without-cover': page && page.image && page.image.isShown && page.image.image.url}">
  <div *ngIf="page && page.image && page.image.isShown && page.image.image.url" class="wrapper"  [ngStyle]="{'background-image': 'url(' + page.image.image.url + ')', 'opacity': '1'}"></div>
  <div class="wrapper" *ngIf="page && page.image && !page.image.isShown"></div>
  <form>
    <div class="logo">
      <img [src]="themeService.getLogoUrl()">
    </div>
    <div class="login-header">
      <h1>{{themeService.getWelcomeMessage()}}</h1>
      <h4 *ngIf="page && page.text.isShown" [innerHTML]="page.text.text | safe: 'html'"></h4>
    </div>
    <div class="field" [ngClass]="{field: true, error: validationError.fields.includes('password')}" *ngIf="!error">
      <label for="new-password" appI18nTranslation>New password</label>
      <input type="password" name="password" id="new-password" [(ngModel)]="data.password" [ngModelOptions]="{standalone: true}">
    </div>
    <div class="field" [ngClass]="{field: true, error: validationError.fields.includes('confirm_password')}" *ngIf="!error">
      <label for="confirm-new-password" appI18nTranslation>Confirm new password</label>
      <input type="password" name="confirm_password" id="confirm-new-password" [(ngModel)]="data.confirm_password" [ngModelOptions]="{standalone: true}">
    </div>
    <div class="field" *ngIf="error">
      <span class="error">{{error.message}}</span>
    </div>
    <div class="field" *ngIf="validationError.message != ''">
      <span class="error">{{validationError.message}}</span>
    </div>
    <button
            *ngIf="!error"
            (click)="handleSetNewPassword($event)"
            [disabled]="isProcessed"
            mat-button
            [ngStyle]="{
        'background-color': (themeService.settings$ | async).getButtonColor(1)
        }" appI18nTranslation>
      Set new password
    </button>
    <div class="progress" [ngStyle]="{'margin-top': isProcessed ? '45px' : '50px'}">
      <mat-progress-bar mode="query" *ngIf="isProcessed"></mat-progress-bar>
    </div>
  </form>
  <h1
          *ngIf="page && page.title.isShown"
          class="title"
          [innerHTML]="page.title.title | safe: 'html'">
  </h1>
</div>
