import { Injectable }    from '@angular/core';
import { HttpClient }    from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CreditsGroupsService {

  constructor(
    private http: HttpClient,
    private config: ConfigService
  ) { }

  get(group_id) {
    return this.http.get(this.config.getActionUrl(`credit-products/${group_id}`)).toPromise();
  }
}
