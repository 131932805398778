import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef }            from '@angular/material';
import { ThemeService }                                        from '../../theme.service';
import { OrdersService }                                       from '../../order-item.service';
import { Router }                                              from '@angular/router';
import { OrdersResponse }                                      from '../../orders-response';
import { Subscription }                                        from 'rxjs';
import { TenantCreditSettingsService }                         from "../../tenant-credit-settings.service";
import { UserService }                                         from "../../user.service";
import { CreditsGroupsService }                                from '../../credits-groups.service';
import { OrderItem }                                           from '../../order-item';
import * as _ from "lodash";
import {DocumentService} from "../../editor/chili-editor/document.service";
import {ProductgroupService} from "../../productgroup.service";

@Component({
  selector: 'global-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.sass']
})
export class CartComponent implements OnInit, OnDestroy, AfterViewInit {

  order: any;
  processing = false;
  orderSubscription: Subscription;

  user: any = {creditBalance: 0};
  creditSettings: any = { creditValue: 0 };
  creditUsed: number = 0;
  totalPrice: number = 0;

  creditGroups: any = [];
  quantityTimeOutIncrease: number;
  quantityTimeOutReduce: number;
  productGroups: any = [];

  constructor(
    public dialogRef: MatDialogRef<CartComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public themeService: ThemeService,
    public ordersService: OrdersService,
    public router: Router,
    private creditSettingsService: TenantCreditSettingsService,
    private userService: UserService,
    private productgroupService: ProductgroupService,
    private creditGroupService: CreditsGroupsService
  ) {}

  ngOnInit() {
    this.processing = true;
    this.themeService.get();
    this.getSettings();
    this.productgroupService.list()
      .then((response: any) => {
        this.productGroups = response.data;
        this.ordersService.getShoppingCart();
        if (this.ordersService.currentShoppingCart) {
          this.order = this.ordersService.currentShoppingCart;
          this.order.orderItems = this.order.orderItems.map((product: any) => {
            product.notes = product.notes.replace(/\n/g, '<br>');
            return product;
          });
          this.updateTotalData();
        }
        this.orderSubscription = this.ordersService.currentShoppingCart$.subscribe((order: OrdersResponse) => {
          if (!order || (order && !order.id)) {
            return;
          }
          this.order = order;
          this.order.orderItems = this.order.orderItems.map((product: any) => {
            product.notes = product.notes.replace(/\n/g, '<br>');
            return product;
          });
          this.updateTotalData();
        });
      })
  }

  getItemPrice(item) {
    let totalPrice = 0.00;
    for(let orderItemDocumentIndex = 0; orderItemDocumentIndex < item.documents.length; orderItemDocumentIndex++) {
      const price = (item.documents[orderItemDocumentIndex].document.price) as number;
      const quantity = (item.quantity) as number;
      totalPrice += (price * quantity);
    }
    return totalPrice;
  }

  getOrderPrice() {
    let creditsUsed = 0.00;
    let totalPrice = 0.00;
    for(let orderItemIndex = 0; orderItemIndex < this.order.orderItems.length; orderItemIndex++) {
      for(let orderItemDocumentIndex = 0; orderItemDocumentIndex < this.order.orderItems[orderItemIndex].documents.length; orderItemDocumentIndex++) {
        const price = (this.order.orderItems[orderItemIndex].documents[orderItemDocumentIndex].document.price) as number;
        const quantity = (this.order.orderItems[orderItemIndex].quantity) as number;
        totalPrice += (price * quantity);
        if(!this.order.orderItems[orderItemIndex].product.settings.buyByCredit) {
          continue;
        }
        if(
          creditsUsed +
          ((price  * this.order.orderItems[orderItemIndex].quantity) / this.creditSettings.creditValue)
          <= this.userService.user.creditBalance
        ) {
          creditsUsed = creditsUsed + ((price * quantity) / this.creditSettings.creditValue);
        } else {
          creditsUsed = this.userService.user.creditBalance;

        }
      }
    }
    totalPrice -= creditsUsed * this.creditSettings.creditValue;
    return totalPrice;
  }

  getCreditsUsed() {
    let creditsUsed = 0.00;
    for(let orderItemIndex = 0; orderItemIndex < this.order.orderItems.length; orderItemIndex++) {
      if(!this.order.orderItems[orderItemIndex].product.settings.buyByCredit) {
        continue;
      }
      for(let orderItemDocumentIndex = 0; orderItemDocumentIndex < this.order.orderItems[orderItemIndex].documents.length; orderItemDocumentIndex++) {
        const price = (this.order.orderItems[orderItemIndex].documents[orderItemDocumentIndex].document.price) as number;
        const quantity = (this.order.orderItems[orderItemIndex].quantity) as number;

        if(
          creditsUsed +
          ((price  * this.order.orderItems[orderItemIndex].quantity) / this.creditSettings.creditValue)
          <= this.userService.user.creditBalance
        ) {
          creditsUsed = creditsUsed + ((price * quantity) / this.creditSettings.creditValue);
        } else {
          creditsUsed = this.userService.user.creditBalance;
        }
      }
    }
    this.userService.setShowCreditBalance(this.userService.user.creditBalance - creditsUsed);
    return creditsUsed;
  }

  getProductgroup(productgroupId: number) {
    return this.productGroups.find((item) => {
      return item.id == productgroupId;
    })
  }

  ngOnDestroy() {
    this.orderSubscription.unsubscribe();
  }

  getCreditGroup(group_id) {
    if (!group_id || this.creditGroups[group_id]) {
      return;
    }
    this.creditGroups[group_id] = this.creditGroupService.get(group_id)
      .then((response: any) => {
        this.creditGroups[response.group.id] = response.group;
      });
  }

  async getSettings() {
    this.creditSettings = await this.creditSettingsService.getSettings();
  }

  async updateTotalData() {
    await this.getSettings();
    this.user = await this.userService.getUser();
    this.order.orderItems.map((item: OrderItem) => {
      this.getCreditGroup(item.creditGroupId);
    });
    // const creditCost = this.order.orderItems
    //   .filter(orderItem => orderItem.settings && orderItem.settings.buyByCredit)
    //   .reduce((creditCostS: any, orderItem: any, index, orderItems) => {
    //       return creditCostS;
    //     }, 0
    //   );
    //
    // const creditCostForConfirmed = this.order.orderItems
    //   .filter(orderItem => orderItem.settings && orderItem.settings.buyByCredit)
    //   .reduce((creditCostForConfirmedS: any, orderItem: any, index, orderItems) => {
    //       return creditCostForConfirmedS + orderItem.cost_on_credits;
    //     }, 0
    //   );
    //
    //
    // if (creditCost >= this.user.creditBalance) {
    //   this.creditUsed = this.user.creditBalance + creditCostForConfirmed;
    //   this.totalPrice = this.order.price - (this.creditUsed * this.creditSettings.creditValue);
    // } else {
    //   this.creditUsed = creditCost + creditCostForConfirmed;
    //   this.totalPrice = this.order.price - (this.creditUsed * this.creditSettings.creditValue);
    // }
    this.creditUsed = await this.ordersService.getCreditsUsed();
    this.totalPrice = await this.ordersService.getOrderPrice();
    // console.log('this.creditUsed ', this.creditUsed);
    this.processing = false;
  }

  ngAfterViewInit() {
    (document.querySelector('.mat-dialog-container') as HTMLElement).style.borderColor = this.themeService.getButtonColor(1);
  }

  close(): void {
    this.dialogRef.close();
  }

  checkout(): void {
    this.close();
    this.router.navigate(['checkout', 'cart']);
  }

  remove(orderItem): void {
    this.ordersService.removeOrderItem(orderItem.id)
      .then((response) => {
        this.ordersService.getShoppingCart();
      });
  }

  manualInput(item) {
    if (this.quantityTimeOutReduce) {
      clearTimeout(this.quantityTimeOutReduce);
    }

    if (item.settings && item.quantity < item.settings.minInput) {
      item.quantity = item.settings.minInput;
    }

    if (item.settings && item.settings.maxInput != 0 && item.quantity > item.settings.maxInput && !item.settings.unlimited_stock) {
      item.quantity = item.settings.maxInput;
    }
    this.quantityTimeOutReduce = window.setTimeout(() => {
        this.processing = false;
        this.ordersService.updateQuantity(item.id, [{op: 'updateQuantity', attribute: 'quantity', value: item.quantity}]);
      },
      250);
  }

  reduce(item) {
    if (this.quantityTimeOutReduce) {
      clearTimeout(this.quantityTimeOutReduce);
    }
    if (!item.settings) {
      item.quantity--;
    } else {
      item.quantity -= item.settings.addByValue;
    }

    if (item.settings && item.quantity < item.settings.minInput) {
      item.quantity = item.settings.minInput;
    }
    this.quantityTimeOutReduce = window.setTimeout(() => {
        console.log(item);
        this.processing = false;
        this.ordersService.updateQuantity(item.id, [{op: 'updateQuantity', attribute: 'quantity', value: item.quantity}]);
      },
      250);
  }

  increase(item) {
    if (this.quantityTimeOutIncrease) {
      window.clearTimeout(this.quantityTimeOutIncrease);
    }
    if (!item.settings) {
      item.quantity++;
    } else {
      item.quantity += item.settings.addByValue;
    }

    if (item.settings && item.settings.maxInput != 0 && item.quantity > item.settings.maxInput && !item.settings.unlimited_stock) {
      item.quantity = item.settings.maxInput;
    }
    this.quantityTimeOutIncrease = window.setTimeout(() => {
        console.log(item);
        this.processing = false;
        this.ordersService.updateQuantity(item.id, [{op: 'updateQuantity', attribute: 'quantity', value: item.quantity}]);
      },
      250);

  }

  isWebshopItem(item: OrderItem) {
    const documents = _.filter(item.documents, (document) => {
      return document.type != 'webshop_item';
    });
    return documents.length > 0 ? false : true;
  }

  isMinAmountOfGroup(item: OrderItem) {
    if (!this.creditGroups || !this.creditGroups[item.creditGroupId] || !this.creditGroups[item.creditGroupId]['products']) {
      return false;
    }
    const products = this.creditGroups[item.creditGroupId].products.filter((product: any) => {
      return (product.productId == item.productId || (item.productgroupId && product.productId == item.productgroupId)) && item.quantity > product.min;
    });
    return products.length > 0;
  }

  isMaxAmountOfGroup(item: OrderItem) {
    if (!this.creditGroups || !this.creditGroups[item.creditGroupId] || !this.creditGroups[item.creditGroupId]['products']) {
      return false;
    }
    const products = this.creditGroups[item.creditGroupId].products.filter((product: any) => {
      return (product.productId == item.productId || (item.productgroupId && product.productId == item.productgroupId)) && item.quantity < product.max;
    });
    return products.length > 0;
  }

  public isShowCurrencyPrice(item: any) {
    return item.settings && item.settings.shownPrice;
  }

  public isShowCreditsPrice(item: any) {
    return item.settings && item.settings.shownCredit && this.creditSettings.enable;
  }

  public isDisabledReduce(item: any) {
    return (item.type == 'credit' && (this.creditGroups && this.creditGroups[item.creditGroupId] && this.creditGroups[item.creditGroupId].isLockedProducts && !this.isMinAmountOfGroup(item))) || this.processing || item.quantity <= 1;
  }

  public isDisabledIncrease(item: any) {
    return this.processing || (item.quantity == item.inStock && !item.isUnlimitedStock) || (item.type == 'credit' && !this.isMaxAmountOfGroup(item));
  }

  public isShowRemoveButton(item: any) {
    return (item.type == 'credit' && (this.creditGroups && this.creditGroups[item.creditGroupId] && !this.creditGroups[item.creditGroupId].isLockedProducts)) || item.type != 'credit';
  }
}
