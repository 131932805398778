import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ThemeService }             from '../../theme.service';
import { MatDialog }                from '@angular/material';
import { ClientPage }                  from '../../client-page';
import { ProductsService }             from '../../products.service';
import { ListResponse }                from '../../http/list-response';
import { OrdersService }               from '../../order-item.service';
import { TenantCreditSettingsService } from "../../tenant-credit-settings.service";
import { FormControl }                 from "@angular/forms";
import { MatFormField }                from "@angular/material/form-field";
import * as _                          from 'lodash';
import { MatSelectChange }             from "@angular/material/select";
import {CartComponent}                 from "../../global/cart/cart.component";
import {ImagePopoverDialogComponent}   from "./image-popover-dialog/image-popover-dialog.component";
import { LanguagesService }            from '../../languages.service';
import { isString }                    from '../../utils/utils';
import {CreditsGroupsService} from "../../credits-groups.service";
import {OrdersResponse} from "../../orders-response";
import {Subscription} from "rxjs/Subscription";
import {OrderItem} from "../../order-item";
import {ProductgroupService} from "../../productgroup.service";


interface _Iterable extends Iterable < {} > {
  length: number;
}

class _Array < T > extends Array < T > {
  static range(from: number, to: number, step: number): number[] {
    return Array.from(
      ( < _Iterable > { length: Math.floor((to - from) / step) }),
      (v, k) => from + k * step
    );
  }
}

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.sass']
})
export class CatalogComponent implements OnInit {

  products: any[] = [];
  filteredProducts: any[] = [];
  productsOnCurrentPage: any[] = [];

  currentMainTag: any;
  currentTag: any[] = [];

  productsTags = [];
  productsMainTags = [];

  processed = false;

  creditSettings: any = {creditValue: 0.0};

  productPerPage = 8;
  productPages: any[] = [];
  currentPage = 1;
  lastPage = 1;
  firstPage = 1;
  productPerPageCounters: any = [1, 8, 16, 32, 64];

  lastQuery: any = {offset: 0, size: 8};
  lastFilters: any = {maintag: [], tag: []};

  @Input() page: ClientPage;

  @ViewChild('searchField') searchField: MatFormField;
  @ViewChild('filterField') filterField: MatFormField;
  @ViewChild('container') container: ElementRef;

  search = new FormControl();

  height: number = 430;

  creditGroups: any = [];
  private orderSubscription: Subscription;
  private order: OrdersResponse;

  constructor(
    public themeService: ThemeService,
    private productsService: ProductsService,
    private productgroupService: ProductgroupService,
    private orderService: OrdersService,
    private creditSettingsService: TenantCreditSettingsService,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private creditGroupService: CreditsGroupsService,
    private languagesService: LanguagesService
  ) { }

  async ngOnInit() {

    this.productsService.filtersData()
      .then((filtersData: any) => {
        this.productsTags = filtersData.tags;
        this.productsMainTags = filtersData.maintags;
      });

    this.themeService.get();
    this.orderService.getShoppingCart();
    window.addEventListener("resize", () => {
      this.updateStyles();
    });
    this.currentPage = 1;

    this.creditSettings = await this.creditSettingsService.getSettings();
    this.productPerPage = this.page.products.productsPerPage;
    this.makeProductsList({ size: this.productPerPage, offset: 0 });
    const productsSubscription = this.languagesService.currentLocaleSet$.subscribe(() => {
      productsSubscription.unsubscribe();
      this.makeProductsList();
    });
    this.orderSubscription = this.orderService.currentShoppingCart$
      .subscribe((order: OrdersResponse) => {
        if (!order || !order.id) {
          return;
        }
        this.order = order;
        this.order.orderItems.map((orderItem: OrderItem) => {
          this.getCreditGroup(orderItem.creditGroupId);
        });
      });
  }

  makeProductsList(query: any = null) {

    if(!query) {
      query = this.lastQuery;
    }
    // this.lastFilters
    const filters = {
      maintags: { value: []},
      tags: { value: []},
      name: ''
    };

    if(this.currentMainTag) {
      filters.maintags.value.push(this.currentMainTag.id)
    }

    if(this.currentTag.length > 0) {
      this.currentTag.map((tag) => {
        filters.tags.value.push(tag.id)
      });
    }

    this.lastFilters = filters;

    query.filters = JSON.stringify(filters);
    this.lastQuery = query;
    this.productsService.list(query)
      .then((results: any) => {
      this.setProducts(results.data);
      const pages = Math.ceil(results.meta.total / this.productPerPage);
      this.productPages = this.getPagesForPaginator(pages);
    })
  }

  getPagesForPaginator(length: number) {
    const pages = [];

    let start = 0;
    let stop = 7;

    this.lastPage = length;

    if( length <= 7) {
      stop = length;
    }

    if(length > 7 && this.currentPage > 4 && this.currentPage + 3 < length) {
      start = this.currentPage - 4;
      stop = this.currentPage + 3;
    }

    if(length > 7 && this.currentPage > 4 && this.currentPage + 3 >= length) {
      start = length - 7;
      stop = length;
    }

    for (let i = start; i < stop; i++) {
      pages.push(i+1);
    }

    return pages;
  }

  isDisabledAddButton(product: any) {
    return this.processed || (product.in_stock <= product.minimum_stock && !product.unlimited_stock) || product.isSingleProduct;
  }

  isShowCreditPrice(product: any) {
    return product.settings && product.settings.shownCredit && this.creditSettings.enable;
  }

  isShowCurrencyPrice(product: any) {
    return product.settings && product.settings.shownPrice;
  }

  isShowProductPreview(product: any) {
    return product && product.documents && product.documents.length > 0 && product.documents[0] && product.documents[0].images && product.documents[0].images.length > 0;
  }

  isShowChiliPreview(product: any) {
    return product && product.documents && product.documents.length > 0 && product.documents[0] && product.documents[0].images && product.documents[0].images.length == 0 && product.documents[0].previewUrl;
  }

  getCreditGroup(group_id) {
    if (!group_id || this.creditGroups[group_id]) {
      return;
    }
    this.creditGroups[group_id] = this.creditGroupService.get(group_id)
      .then((response: any) => {
        this.creditGroups[response.group.id] = response.group;
      });
  }

  updateStyles() {
    document.documentElement.style.setProperty('--product-list-item-height', 'auto');
    const heights = [];
    setTimeout(() => {
      this.productsOnCurrentPage.map((value, index) => {
        let element = document.querySelector('#productsListItem'+index+' > .mat-list-item-content');

          heights.push(element.clientHeight);

      });
      this.height = (heights.sort())[heights.length - 1];
      document.documentElement.style.setProperty('--product-list-item-height', this.height + 'px');
    }, 100);
  }

  setProducts(products: any[]) {
    this.products = products;
    // _.map(this.products, (product, index) => {
    //   if(product.productType == 'group') {
    //     this.productsTags = this.productsTags.concat(product.products[0].tags).filter((value, index, array) => {
    //       return array.indexOf(value) === index;
    //     }).sort();
    //     this.productsMainTags = this.productsMainTags.concat(product.products[0].maintags).filter((value, index, array) => {
    //       return array.indexOf(value) === index;
    //     }).sort();
    //   } else {
    //     this.productsMainTags = this.productsMainTags.concat(product.maintags).filter((value, index, array) => {
    //       return array.indexOf(value) === index;
    //     }).sort();
    //     this.productsTags = this.productsTags.concat(product.tags).filter((value, index, array) => {
    //       return array.indexOf(value) === index;
    //     }).sort();
    //   }
    // });
    this.filteringProducts();
    if (this.page.products.isShown) {
      this.updateStyles();
    }
  }

  selectTags($event: MatSelectChange) {
    if($event.value.length > 0) {
      this.currentTag = $event.value;
    } else {
      this.currentTag = []
    }
    this.currentPage = 1;
    this.makeProductsList()
  }

  selectMainTags($event: MatSelectChange) {
    if($event.value) {
      this.currentMainTag = $event.value;
    } else {
      this.currentMainTag = []
    }
    this.currentPage = 1;
    this.makeProductsList();
    // this.filteringProducts();
  }

  filteringProducts() {
    let filteredProducts = this.products;
    // if (this.currentMainTag) {
    //   filteredProducts = filteredProducts.filter((product, index, array) => {
    //     if(product.productType == 'group') {
    //       return product.products[0].maintags.indexOf(this.currentMainTag) !== -1;
    //     }
    //     return product.maintags.indexOf(this.currentMainTag) !== -1;
    //   });
    // }
    // if (this.currentTag) {
    //   filteredProducts = filteredProducts.filter((product, index, array) => {
    //     let tags = [];
    //     if(product.productType == 'group') {
    //       tags =  this.currentTag.filter((tag, tagIndex, selectedTags) => {
    //         return product.products[0].tags.indexOf(tag) !== -1;
    //       });
    //     } else {
    //       tags =  this.currentTag.filter((tag, tagIndex, selectedTags) => {
    //         return product.tags.indexOf(tag) !== -1;
    //       });
    //     }
    //     return tags.length > 0;
    //   });
    // }

    filteredProducts = filteredProducts.map((product: any) => {
      if (product.notes) {
        if(product.productType == 'group') {
          product.products[0].notes = product.products[0].notes.replace(/\n/g, '<br>');
        } else {
          product.notes = product.notes.replace(/\n/g, '<br>');
        }

      }
      this.isSingleProduct(product)
        .then((isSingleProduct: boolean) => {
          product.isSingleProduct = isSingleProduct;
        });
      return product;
    });

    this.filteredProducts = filteredProducts;
    this.productsOnCurrentPage = this.filteredProducts;
  }

  async isSingleProduct(product: any) {
    if (!this.order || this.order.orderItems) {
      return false;
    }
    let orderItems = this.order.orderItems;
    for (let orderItem of orderItems) {
      if (orderItem.product == product.id) {
        let group = this.creditGroups[orderItem.creditGroupId];
        if (group instanceof Promise) {
          await group;
          group = this.creditGroups[orderItem.creditGroupId];
        }

        if(!group) {
          return false;
        }

        let productInGroup = group.products.some((item: any) => {
          return item.productId == product.id;
        })

        return productInGroup ? group.isSingleProduct : false;
      }
    }
  }

  setCurrentPage(page: number) {
    // this.productsOnCurrentPage = this.filteredProducts.slice((page - 1) * this.productPerPage, page * this.productPerPage);
    this.currentPage = page;
    this.makeProductsList({ size: this.productPerPage, offset: (page - 1) * this.productPerPage});
    this.container.nativeElement.scrollIntoView();
    if (this.page.products.isShown) {
      this.updateStyles();
    }
  }

  selectProductPerPageCounter($event) {
    this.productPerPage = $event.value;
    this.currentPage = 1
    this.makeProductsList({ size: this.productPerPage, offset: 0 });
  }

  handleSearch($event) {
    const query = {
      size: this.lastQuery.size,
      offset: this.lastQuery.offset,
      filters: JSON.stringify({
        name: this.search.value
      })
    }
    this.makeProductsList(query);
    this.currentPage = 1;
  }
  add(product) {
    this.processed = true;
    if (product.productType != 'group' && (product.in_stock <= product.minimum_stock && !product.unlimited_stock)) {
      return;
    }

    if (product.productType == 'group' && (product.products[0].in_stock <= product.products[0].minimum_stock && !product.products[0].unlimited_stock)) {
      return;
    }

    let data = {};
    if (product.productType != 'group') {
      data = { productId: product.id, quantity: 1, productgroupId: null }
    } else if (product.productType == 'group') {
      data = { productId: product.products[0].id, quantity: 1, productgroupId: product.id }
    }

    this.orderService.addOrderItem(data)
    .then((response) => {
      document.documentElement.style.setProperty('--cart-dialog-top', (window.scrollY + 20) + 'px');
      const dialogRef = this.dialog.open(CartComponent, {
        width: '800px',
        data: {test: 'test'},
        panelClass: 'cart-dialog',
        backdropClass: 'backdrop-transparent'
      });
      this.processed = false;
    });
  }

  onFieldFocusIn($event: any) {
    console.log($event);
    $event.path[4]
      .querySelector('.mat-form-field-ripple')
      .style
      .setProperty('background-color', this.themeService.getButtonColor(1));

    $event.path[4]
      .querySelector('.mat-form-field-label')
      .style
      .setProperty('color', this.themeService.getButtonColor(1));

    $event.target.style.setProperty('caret-color', this.themeService.getButtonColor(1));
  }

  onFieldFocusOut($event: any) {
    $event.path[4]
      .querySelector('.mat-form-field-ripple')
      .style
      .setProperty('background-color', 'rgba(0, 0, 0, 0.87)');

    $event.path[4]
      .querySelector('.mat-form-field-label')
      .style
      .setProperty('color', 'rgba(0, 0, 0, 0.6)');

    $event.target.style.setProperty('caret-color', 'rgba(0, 0, 0, 0.6)');
  }

  showProductImage(product: any) {
    const imagesForShow = [];
    _.each(product.documents, (document: any) => {
      if (document.images) {
        _.each(document.images, (image: any) => {
          imagesForShow.push(image.file.url);
        });
      } else {
        imagesForShow.push(document.previewUrl);
      }
    });

    const uniqueSet = new Set(imagesForShow);

    const unqiueValues = Array.from(uniqueSet);


    const dialogRef = this.dialog.open(ImagePopoverDialogComponent, {
      data: unqiueValues,
      width: (window.innerWidth * 0.3) + 'px',
      height: (window.innerWidth * 0.3) + 'px',
      panelClass: 'image-popover-dialog'
    });
  }
}
