import { Component, Input, OnInit } from '@angular/core';
import { ThemeService }             from '../../theme.service';
import { ClientPage }               from '../../client-page';

@Component({
  selector: 'global-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  @Input() page: ClientPage;

  constructor(public themeService: ThemeService) { }

  ngOnInit() {
  }

}
