import { TitleBlock }      from './blocks/title-block';
import { DeliveriesBlock } from './blocks/deliveries-block';
import { DeadlinesBlock }  from './blocks/deadlines-block';
import { LocationsBlock }  from './blocks/locations-block';
import { ImageBlock }      from './blocks/image-block';
import { SubTitleBlock }   from './blocks/sub-title-block';
import { TextBlock }       from './blocks/text-block';
import { ProductsBlock }   from "./blocks/products-block";
import { AlertBlock }      from './blocks/alert-block';

export class ClientPage {
  id?: number;
  code: string;
  title: TitleBlock;
  comment: TitleBlock;
  sub_title: SubTitleBlock;
  image: ImageBlock;
  backgroundColor: string;
  textColor: string;
  locations: LocationsBlock;
  deadlines: DeadlinesBlock;
  deliveries: DeliveriesBlock;
  text: TextBlock;
  products: ProductsBlock;
  orderItems: TitleBlock;
  alert: AlertBlock;
}
