import { Injectable }           from '@angular/core';
import { RestoreCommand }       from './commands/restore.command';
import { HttpClient }           from '@angular/common/http';
import { ConfigService }        from '../config.service';
import { ResetPasswordCommand } from './commands/reset-password.command';
import { CheckResetKeyCommand } from './commands/check-reset-key.command';

@Injectable({
  providedIn: 'root'
})
export class RestorePasswordService {

  constructor(private http: HttpClient,
              private configService: ConfigService) { }

  create(data: RestoreCommand): Promise<any> {
    return this.http.post(this.configService.getLanguagesUrl('users/forgot'), data).toPromise();
  }

  set(data: ResetPasswordCommand): Promise<any> {
    return this.http.post(this.configService.getLanguagesUrl('users/set-password'), data).toPromise();
  }

  check(data: CheckResetKeyCommand) {
    return this.http.post(this.configService.getLanguagesUrl('users/check-restore-key'), data).toPromise();
  }

  setNewPassword(data) {
    return this.http.post(this.configService.getLanguagesUrl('users/set-new-password'), data).toPromise();
  }
}
