import { Component, OnInit } from '@angular/core';
import {DetailsView, FileManager, NavigationPane, Toolbar} from "@syncfusion/ej2-filemanager";
import {MatDialogRef} from "@angular/material/dialog";
import {SettingService} from "../../setting.service";
import {UserService} from "../../user.service";

@Component({
  selector: 'global-filemanager',
  templateUrl: './filemanager.component.html',
  styleUrls: ['./filemanager.component.sass']
})
export class FilemanagerComponent implements OnInit {

  public filemanagerInstance: FileManager;
  public user: any;

  constructor(public dialogRef: MatDialogRef<FilemanagerComponent>, private settingService: SettingService, private usersService: UserService) { }

  ngOnInit() {
    this.usersService.getUser().then((user: any) => {
      this.user = user;
      this.settingService.get().subscribe((response: any) => {
        FileManager.Inject(Toolbar, NavigationPane, DetailsView);
        let hostUrl: string = '/api/fs/';

        const toolbarSettingsItems = ['Download', 'SortBy', 'Refresh', 'Selection', 'View', 'Details'];
        let layoutContextMenuSettings = ['SortBy', 'View', 'Refresh', '|', 'Paste', '|'];

        if(this.user.allowCreateFolder) {
          layoutContextMenuSettings.push('NewFolder');
        }

        if(this.user.allowUpload) {
          toolbarSettingsItems.push('Upload');
          layoutContextMenuSettings.push('Upload');
        }

        if(this.user.allowCreateFolder || this.user.allowUpload) {
          layoutContextMenuSettings.push('|');
        }

        layoutContextMenuSettings.push('Details', '|', 'SelectAll');

        this.filemanagerInstance = new FileManager({
          ajaxSettings: {
            url: hostUrl + 'file-operations/' + response.tenantName ,
            downloadUrl: hostUrl + 'download/' + response.tenantName,
            uploadUrl: hostUrl + 'upload/' + response.tenantName,
            getImageUrl: hostUrl + 'get-image/' + response.tenantName,
          },
          contextMenuSettings: {
            file: ['Open','|', 'Cut', 'Copy', '|', 'Delete', 'Rename', '|', 'Details'],
            folder: ['Open','|', 'Cut', 'Copy', 'Paste', '|', 'Delete', 'Rename', '|', 'Details'],
            layout: layoutContextMenuSettings,
            visible: true,
          },
          allowDragAndDrop: false,
          toolbarSettings: { items: toolbarSettingsItems, visible: true},
          height: '432px',
          path: '/' + this.user.name + '/'
        });

        this.filemanagerInstance.appendTo('#filemanager');
      });
    });

  }

  closeDialog() {
    this.dialogRef.close();
  }

}
