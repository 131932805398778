<div class="container" *ngIf="languagesService.getAvailableLocales().length > 1">
    <mat-select [(ngModel)]="config.currentLocale" panelClass="locale-selector-panel" (selectionChange)="changeCurrentLocale($event)">
        <mat-select-trigger>
            <img class="locale-icon" [src]="'/assets/icons/flags/' + config.currentLocale.code.toUpperCase() + '.png' ">
            <span>{{config.currentLocale.name}}</span>
        </mat-select-trigger>
        <mat-option *ngFor="let locale of languagesService.getAvailableLocales()" [value]="locale">
            <img class="locale-icon" [src]="'/assets/icons/flags/' + locale.code.toUpperCase() + '.png' ">
            <span>{{locale.name}}</span>
        </mat-option>
    </mat-select>
</div>
